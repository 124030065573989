import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteAccountContext } from '../context';
import { ActionButton } from './styled';
import { OpenedWindow } from '../context/initialState';
import { AlertWindow } from './modalWindows/alert';
import { DeleteWindow } from './modalWindows/delete';
import { ErrorWindow } from './modalWindows/error';
import { usePostMessage } from '../../../hooks/usePostMessage';

export const DeleteAccount = () => {
  const { t } = useTranslation();
  const { openedWindow, setOpenedWindow, isMobileSite } = useContext(DeleteAccountContext);

  const openAlertWindow = () => {
    setOpenedWindow(OpenedWindow.alertWindow);
  };

  usePostMessage({
    postMessageName: 'showAlertWindow',
    handler: openAlertWindow
  });

  return (
    <>
      {!isMobileSite && <ActionButton onClick={openAlertWindow}>{t('action')}</ActionButton>}
      {(openedWindow === OpenedWindow.alertWindow
        || (isMobileSite && openedWindow === OpenedWindow.deleteWindow)) && <AlertWindow />}
      {openedWindow === OpenedWindow.deleteWindow && <DeleteWindow />}
      {openedWindow === OpenedWindow.errorWindow && <ErrorWindow />}
    </>
  );
};

import { ItemTabName, Actions } from './types';

export const setDataStatAttr = (value: string) => ({ 'data-stat': value });

export const setDataStatAttrForProfileTabs = (
  tabName: string | ItemTabName
) => setDataStatAttr(`${tabName}-tab-profile`);

export const setDataStatAttrForProfileTabsActions = (
  action: Actions, tabName: ItemTabName
) => setDataStatAttrForProfileTabs(`${action}-ad-in-${tabName}`);

export const setDataStatAttrForProfileAdLink = (
  tabName: ItemTabName
) => setDataStatAttrForProfileTabs(`ad-link-in-${tabName}`);

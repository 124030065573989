import 'swiper/swiper.min.css';

import React, { useEffect, useRef, useState } from 'react';
import { Swiper } from 'swiper/core';
import { Swiper as SwiperContainer, SwiperSlide } from 'swiper/react';
import { Gallery } from './Gallery';
import { RATIO } from './Carousel.constants';
import * as S from './Carousel.styles';

interface CarouselProps {
  title: string;
  slides: { url: string; label: string }[];
}

export const Carousel = ({ title, slides = [] }: CarouselProps) => {
  const height = RATIO * window.innerWidth;

  const swiperRef = useRef<Swiper | null>(null);
  const [slideIndex, setSlideIndex] = useState<number>(1);
  const [isGalleryOpen, setGalleryOpen] = useState<boolean>(false);

  const counter = `${slideIndex}/${slides.length}`;

  useEffect(() => {
    document.body.style.overflow = isGalleryOpen ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [isGalleryOpen]);

  const onSlideChange = (swiper: Swiper) => {
    setSlideIndex(swiper.realIndex + 1);
  };

  const onToggleGallery = () => {
    setGalleryOpen(isOpen => !isOpen);
  };

  return (
    <S.CarouselMobileContainer>
      {isGalleryOpen && (
        <Gallery
          title={title}
          slides={slides}
          initialSlide={slideIndex}
          onClose={onToggleGallery}
          onChange={index => swiperRef.current?.slideTo(index)}
        />
      )}
      <SwiperContainer
        loop
        slidesPerView={1}
        onSlideChange={onSlideChange}
        onInit={swiper => (swiperRef.current = swiper)}
      >
        {slides.map(({ url }) => {
          return (
            <SwiperSlide key={url} style={{ height }} onClick={onToggleGallery}>
              <S.SlideMobile>
                <S.ImageMobile src={url} loading="lazy" />
              </S.SlideMobile>
            </SwiperSlide>
          );
        })}
      </SwiperContainer>
      <S.SlideCounterMobile>{counter}</S.SlideCounterMobile>
    </S.CarouselMobileContainer>
  );
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWindowComponent } from '../index';
import { DeleteAccountContext } from '../../../context';
import { OpenedWindow } from '../../../context/initialState';
import {
  BlueBtn, Footer, Message, PopupHeader
} from '../styled';

export const ErrorWindow = () => {
  const { setOpenedWindow, isMobileSite } = useContext(DeleteAccountContext);
  const { t } = useTranslation();

  const onClose = () => {
    setOpenedWindow(OpenedWindow.none);
  };

  return (
    <ModalWindowComponent
      onClose={onClose}
      title={<PopupHeader>{t('error.title')}</PopupHeader>}
      popupWidth={isMobileSite ? 'calc(100% - 30px)' : '455px'}
      hasCloseBtn={false}
      headerStyles={{ justifyContent: 'center' }}
    >
      <>
        <Message>{t('error.message')}</Message>
        <Footer><BlueBtn onClick={onClose}>{t('error.ok')}</BlueBtn></Footer>
      </>
    </ModalWindowComponent>
  );
};

import styled, { css } from 'styled-components';

import CloseSvg from '@assets/images/application/icons-for-sprite/icon-close--white.svg';
import ArrowLeftSvg from '@assets/images/application/icons-for-sprite/icon-arrow-left--white.svg';
import ArrowRightSvg from '@assets/images/application/icons-for-sprite/icon-arrow-right--white.svg';

import { SlideCounter } from '../../Carousel.styles';

const VisibleModal = css`
  opacity: 1;
  visibility: visible;
`;

const CenteredThumbs = css`
  display: flex;
  justify-content: center;
`;

export const ModalWrapper = styled.div<{ $isVisible: boolean }>`
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100vh;
  opacity: 0;
  display: flex;
  position: fixed;
  z-index: 10000001;
  visibility: hidden;
  flex-direction: column;
  transform: translateX(-50%) translateY(-50%);
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;

  background-color: ${({ theme }) => theme.color.black};
  ${({ $isVisible }) => ($isVisible ? VisibleModal : '')}
`;

export const Arrow = styled.button<{ $isLeft?: boolean }>`
  position: absolute;
  top: 50%;
  width: 60px;
  height: 90px;
  z-index: 3;
  cursor: pointer;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.4);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.2s ease-in-out;
  left: ${({ $isLeft }) => ($isLeft ? '0' : 'auto')};
  right: ${({ $isLeft }) => ($isLeft ? 'auto' : '0')};
  border-radius: ${({ $isLeft }) => ($isLeft ? '0 7px 7px 0' : '7px 0 0 7px')};
  background-image: url(${({ $isLeft }) => ($isLeft ? ArrowLeftSvg : ArrowRightSvg)});

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const CarouselContainer = styled.div`
  max-height: calc(100vh - 190px);
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 7px;
  justify-content: center;

  // Fix for too wide slides on initial load
  .swiper-container {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-height: 0;
    min-width: 0;
  }
`;

export const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Image = styled.img<{ $isActive: boolean }>`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  user-drag: none;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.8)};
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  padding: 0 30px;
  min-height: 60px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  background: rgba(255, 255, 255, 0.1);
`;

export const HeaderTitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${({ theme }) => theme.palette.grey[0]};
`;

export const ThumbnailsContainer = styled.div<{ $isCentered?: boolean }>`
  height: 50px;
  width: 100%;
  margin: 20px 0;
  padding-left: ${({ $isCentered }) => ($isCentered ? 0 : 10)}px;
  ${({ $isCentered }) => ($isCentered ? CenteredThumbs : '')}
`;

export const ThumbnailsScroller = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  transition: scroll 0.3s ease-in-out;
  transition-duration: 300ms;
  transform: translate3d(0px, 0px, 0px);
  transition-property: transform;
  will-change: transform;
  transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
`;

export const CloseButton = styled.button`
  width: 35px;
  height: 35px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  background: url(${CloseSvg}) center center / 26px 26px no-repeat transparent;
  border-radius: 7px;
  transition: background-color 0.2s linear 0s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

export const SlideCount = styled(SlideCounter)`
  opacity: 1;
  visibility: visible;
`;

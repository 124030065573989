import styled, { css } from 'styled-components';

const OpenedArrowIcon = css`
  transform: translateY(-50%) rotate(180deg);
`;

export const Icon = styled.svg<{ $isOpen: boolean }>`
  right: 10px;
  top: 50%;
  width: 15px;
  height: 9px;
  position: absolute;
  line-height: 0;
  transform: translateY(-50%);
  transition: transform 0.2s ease-in-out;

  & path {
    fill: ${({ theme }) => theme.palette.grey[200]};
  }

  ${({ $isOpen }) => ($isOpen ? OpenedArrowIcon : '')}
`;

import React, { useState, useCallback } from 'react';
import {
  oneOfType, object, arrayOf, node
} from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Provider } from './index';
import { initialState } from './initialState';
import { theme } from '../styled';

export const ContextWrapper = ({ children, dataset }) => {
  const [selectedCategory, setSelectedCategory] = useState(initialState.selectedCategory);
  const [openedQuestionId, setOpenedQuestionId] = useState(initialState.openedQuestionId);
  const [countOfSteps, setCountOfSteps] = useState(initialState.countOfSteps);

  const updateRouting = useCallback(funk => {
    funk();
    setCountOfSteps(countOfSteps - 1);
  }, [countOfSteps]);

  const contextValue = {
    setOpenedQuestionId: id => updateRouting(() => setOpenedQuestionId(id)),
    setSelectedCategory: category => updateRouting(() => setSelectedCategory(category)),
    selectedCategory,
    openedQuestionId,
    countOfSteps,
    isMobile: dataset.isMobileSite === 'true'
  };

  return (
    <Provider value={contextValue}>
      <ThemeProvider theme={theme(contextValue.isMobile)}>
        {children}
      </ThemeProvider>
    </Provider>
  );
};

ContextWrapper.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  dataset: object.isRequired
};

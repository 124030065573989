import { Swiper } from 'swiper/core';
import React, { useEffect, useRef, useState } from 'react';
import { Swiper as SwiperContainer, SwiperSlide } from 'swiper/react';
import { Thumbnails } from './Thumbnails';
import * as S from './Gallery.styles';

interface GalleryProps {
  isVisible: boolean;
  title?: string;
  onClose?: () => void;
  initialSlide: number;
  slides: { url: string; label: string }[];
  thumbs: { url: string; label: string }[];
}

const Component = ({
  onClose,
  isVisible,
  title = '',
  slides = [],
  thumbs = [],
  initialSlide
}: GalleryProps) => {
  const swiperRef = useRef<Swiper | null>(null);
  const [slideIndex, setSlideIndex] = useState<number>(1);

  const counter = `${slideIndex}/${slides.length}`;

  const onSlideChange = (swiper: Swiper) => {
    setSlideIndex(swiper.realIndex + 1);
  };

  const goNext = () => {
    swiperRef?.current?.slideNext();
  };

  const goPrev = () => {
    swiperRef?.current?.slidePrev();
  };

  useEffect(() => {
    const handleArrows = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') goPrev();
      if (event.key === 'ArrowRight') goNext();
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        onClose?.();
      }
    };

    document.addEventListener('keydown', handleArrows);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleArrows);
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  useEffect(() => {
    // Disable scrolling when the carousel is in fullscreen mode
    document.body.style.overflow = isVisible ? 'hidden' : '';
  }, [isVisible]);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(initialSlide);
    }
  }, [initialSlide]);

  return (
    <S.ModalWrapper $isVisible={isVisible}>
      <S.Header>
        <S.HeaderTitle>{title}</S.HeaderTitle>
        <S.CloseButton onClick={onClose} />
      </S.Header>

      <S.CarouselWrapper>
        <S.CarouselContainer>
          <SwiperContainer
            loop
            centeredSlides
            enabled={slides.length > 1}
            onSlideChange={onSlideChange}
            onInit={swiper => (swiperRef.current = swiper)}
          >
            {slides.map(({ url }) => (
              <SwiperSlide key={url}>
                <S.Slide>
                  <S.Image $isActive src={url} loading="lazy" />
                </S.Slide>
              </SwiperSlide>
            ))}
          </SwiperContainer>
          {slides.length > 1 ? <S.Arrow $isLeft onClick={goPrev} /> : null}
          <S.SlideCount>{counter}</S.SlideCount>
          {slides.length > 1 ? <S.Arrow onClick={goNext} /> : null}
        </S.CarouselContainer>
      </S.CarouselWrapper>

      <Thumbnails
        active={slideIndex}
        items={thumbs.length ? thumbs : slides}
        onThumbnailClick={id => swiperRef?.current?.slideTo(id)}
      />
    </S.ModalWrapper>
  );
};

export const Gallery = React.memo(Component);

import styled from 'styled-components';
import { FlexCenterAligned, darkgrey, blueMidnight } from '../../../../../styled';

export const EmptyTable = styled(FlexCenterAligned)`
  height: 207px;
  width: 100%;
  flex-direction: column;
  font-size: 16px;
  text-align: center;
  color: ${blueMidnight};
  
  span:last-child {
    color: ${darkgrey};
    line-height: 1.25;
    width: 285px;
    margin-top: 9px;
  }
`;

import useSWR from 'swr';
import { htmlFetcher } from './api';

export const useOffers = (search: string) => {
  const params = new URLSearchParams(search);
  const makeValue = params.get('make');
  const modelValue = params.get('model');

  params.append('make[]', makeValue ?? '');
  params.append('model[]', modelValue ?? '');
  params.delete('make');
  params.delete('model');

  const { data, isLoading, error, mutate } = useSWR(
    `/autos/embed?${params.toString()}`,
    htmlFetcher,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      shouldRetryOnError: false
    }
  );

  return { data: data?.data ?? '', isLoading, error, mutate };
};

import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { Invoices } from './components/Invoices';
import { InvoiceInfo } from './components/InvoiceInfo';
import { PaymentMethods } from './components/PaymentMethods';
import { ContextWrapper } from './context/ContextWrapper';
import az from './locales/az.json';
import ru from './locales/ru.json';
import { Packages } from './components/Packages';
import { Balance } from './components/Balance';
import { PackagePayment } from './components/Packages/PackagePayment';
import { PackagesUnpaid } from './components/Packages/PackagesUnpaid';
import { BusinessTabs } from './components/BusinessTabs';

const InvoicesApp = ({ dataset }) => (
  <ContextWrapper {...{ dataset }}>
    <Router>
      {dataset.isMobileSite === 'true' ? null : <PackagesUnpaid />}
      <BusinessTabs />
      <Switch>
        <Route path="/profile/packages" exact><Packages /></Route>
        <Route path="/profile/balance" exact><Balance /></Route>
        <Route path="/profile/packages-payment/:id" exact><PackagePayment /></Route>
        <Route path="/profile/invoices" exact><Invoices /></Route>
        <Route path="/profile/invoices/:id" exact><InvoiceInfo /></Route>
        <Route path="/profile/payment" exact><PaymentMethods /></Route>
      </Switch>
    </Router>
  </ContextWrapper>
);

export default { App: InvoicesApp, az, ru };

import React, { useEffect, useState } from 'react';
import { Feature } from 'packs/autocatalog/types';
import { MODIFICATION_ID } from 'packs/autocatalog/constants';
import { FeatureTab } from './GenerationsList.types';
import * as S from './GenerationsList.styles';

interface FeaturesSpecItemProps {
  item: Feature;
  featureTab: FeatureTab | null;
}

export const FeatureSpecItem: React.FC<FeaturesSpecItemProps> = ({ item, featureTab }) => {
  const [isVisible, setVisible] = useState<boolean>(false);
  const hasHiddenFields = !!featureTab?.hidden_fields.length;

  useEffect(() => {
    setVisible(false);
  }, [featureTab]);

  const toggleVisibility = () => {
    if (hasHiddenFields) setVisible(prev => !prev);
  };

  return (
    <S.FeatureItem>
      <S.SpecsRow onClick={toggleVisibility} $isClickable={hasHiddenFields} $isVisible={isVisible}>
        {Object.entries(item).map(([key, value]) => {
          if (featureTab?.fields.includes(key) || key === MODIFICATION_ID) {
            return (
              <S.SpecsCol key={key}>
                <S.SpecValue>{value?.value ?? '-'}</S.SpecValue>
              </S.SpecsCol>
            );
          }
        })}

        {hasHiddenFields ? (
          <S.ExpandIconContainer>
            <S.ExpandIcon $isVisible={isVisible} />
          </S.ExpandIconContainer>
        ) : null}
      </S.SpecsRow>

      {hasHiddenFields ? (
        <S.HiddenSpecsContainer $isVisible={isVisible}>
          {Object.entries(item).map(([key, value]) => {
            if (featureTab?.hidden_fields.includes(key)) {
              return (
                <S.HiddenSpecsCol key={key}>
                  <S.HiddenSpecHeader>{value?.label}</S.HiddenSpecHeader>
                  <S.SpecValue>{value?.value ?? '-'}</S.SpecValue>
                </S.HiddenSpecsCol>
              );
            }
          })}
        </S.HiddenSpecsContainer>
      ) : null}
    </S.FeatureItem>
  );
};

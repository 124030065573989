import React, { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as classNames from 'classnames';
import { filterInvoices } from '../helpers';
import { paidStatuses } from '../constants';
import { InvoicesAppContext } from '../context';

export const Tabs = () => {
  const { t } = useTranslation();
  const {
    invoicesRawData: { allInvoices },
    setCurrentInvoicesTab,
    currentInvoicesTab: { currentTabId }
  } = useContext(InvoicesAppContext);

  const onSelectCurrentTab = useCallback(tab => {
    setCurrentInvoicesTab({
      currentTabId: tab.id,
      currentFilter: tab.filterKeyword,
      filteredInvoices: filterInvoices(tab.filterKeyword, allInvoices)
    });
  }, [allInvoices]);

  return (
    <header className="invoices_header">
      {
        paidStatuses.map((filterKeyword, index) => {
          const id = index + 1;
          const title = t(`filter.${filterKeyword === 'all' ? 'allInvoices' : filterKeyword}`);
          const tabClassNames = classNames(
            'invoices_tab',
            { 'invoices_tab--active': currentTabId === id }
          );
          return (
            <div
              className={tabClassNames}
              onClick={() => onSelectCurrentTab({ title, id, filterKeyword })}
              key={id}
            >
              {title}
            </div>
          );
        })
      }
    </header>
  );
};

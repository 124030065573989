import swr from 'swr';
import axios from 'axios';
import { getToken } from 'helpers';

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Csrf-Token': getToken()
  }
});

const htmlAxiosInstance = axios.create({
  headers: {
    Accept: '*/*',
    'X-Csrf-Token': getToken()
  }
});

export const fetcher = (url: string) => axiosInstance.get(url).then(data => data);
export const htmlFetcher = (url: string) => htmlAxiosInstance.get(url).then(data => data);

import { ItemI } from '../../../../components/Item/types';

enum Status {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  expired = 'expired'
}

export const getAdStatus = (item: ItemI): Status | null => {
  const { pending, expired, rejected } = Status;
  switch (true) {
    case item.pending_paid: return pending;
    case !!item.reject_reason: return rejected;
    case item.expired: return expired;
    case !!item.moderation_status: return Status[item.moderation_status];
    default: return null;
  }
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: ${({ theme }) => theme.border};
  }

  &:not(:first-child) {
    margin-top: 15px;
  }
`;

export const Details = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const SubTitle = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  & > div {
    min-width: 235px;
  }

  & > div:nth-child(1) {
    grid-row: span 3;
    height: auto;
  }

  & > div:not(:nth-child(1)) {
    height: 165px;
  }
`;

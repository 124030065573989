export const paidStatuses = ['all', 'paid', 'unpaid', 'canceled', 'overdue', 'revoked'];
export const existedPromotions = ['instagram', 'web'];

export const RoutePath = {
  profile: '/profile',
  invoices: '/profile/invoices',
  payment: '/profile/payment',
  packages: '/profile/packages',
  balance: '/profile/balance',
  packages_payment: id => `/profile/packages-payment/${id}`
};

export const PaymentOption = {
  wallet: 'wallet',
  portmanat: 'portmanat',
  cash: 'cash'
};

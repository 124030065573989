import React from 'react';
import { Operations } from './index';

export const AdsPayments = () => (
  <Operations
    dataKey="payments"
    emptyError={{
      title: 'empty.operations.title',
      message: 'empty.operations.adsPayments'
    }}
  />
);

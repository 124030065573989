import React, { useCallback, useEffect, useState } from 'react';
import { DropdownOption } from 'components/DropdownSelect';
import { DropdownList } from './components';
import {
  Wrapper,
  Container,
  Placeholder,
  DropdownIcon,
  Input,
  InputSection,
  ResetIcon
} from './styled';

interface DropdownSelectProps {
  label?: string;
  placeholder?: string;
  isMultiSelect?: boolean;
  isFullscreen?: boolean;
  isShort?: boolean;
  isSearchable?: boolean;
  isOutlined?: boolean;
  options: DropdownOption[];
  isDropdownIconVisible?: boolean;
  isLabelActive?: boolean;
  isClearable?: boolean;
  dropdownTitle?: string;
  searchTitle?: string;
  value: DropdownOption | DropdownOption[] | null;
  renderPlaceholder?: () => React.JSX.Element | null;
  onChange: (value: DropdownOption | DropdownOption[] | null) => void;
}

export const DropdownSelect = ({
  onChange,
  renderPlaceholder,
  value,
  label = '',
  options = [],
  placeholder = '',
  dropdownTitle = '',
  searchTitle = '',
  isShort = false,
  isOutlined = false,
  isClearable = true,
  isMultiSelect = false,
  isFullscreen = false,
  isSearchable = false,
  isLabelActive = false,
  isDropdownIconVisible = true
}: DropdownSelectProps) => {
  const [listVisible, setListVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<DropdownOption[]>([]);

  const hasLabel = label.length > 0;
  const hasPlaceholder = placeholder?.length > 0;
  const hasSelected = selected.length > 0;
  const isPlaceholderOnly = !hasLabel && hasPlaceholder;
  const isMinimizedPlaceholder = hasSelected || isLabelActive;
  const selectedValue = Array.isArray(value)
    ? selected.map(item => item.label).join(', ')
    : value?.label;

  useEffect(() => {
    setSelected(value ? (Array.isArray(value) ? [...value] : [value]) : []);
  }, [value]);

  const onSelect = useCallback(
    (item: DropdownOption) => {
      if (!isMultiSelect) {
        onChange(item);
      } else {
        const updated = selected.includes(item)
          ? selected.filter(({ value }) => value !== item.value)
          : [...selected, item];

        onChange(updated);
      }

      isMultiSelect || setListVisible(false);
    },
    [selected, isMultiSelect]
  );

  const toggleDropdown = () => {
    setListVisible(visibility => !visibility);
  };

  const onDropdownIconClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    toggleDropdown();
  };

  const onReset = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onChange(null);
  };

  const renderIcons = () => {
    if (hasSelected && isClearable) {
      return <ResetIcon onClick={onReset} isCentered={!isLabelActive && hasLabel} />;
    }

    if (isDropdownIconVisible) {
      return <DropdownIcon onClick={onDropdownIconClick} />;
    }

    return null;
  };

  return (
    <Wrapper $isOutlined={isOutlined}>
      <Container onClick={toggleDropdown}>
        <InputSection>
          {renderPlaceholder?.() ? (
            renderPlaceholder()
          ) : (
            <Placeholder isMinimized={isMinimizedPlaceholder}>{label}</Placeholder>
          )}
          <Input
            readOnly
            type="text"
            value={selectedValue ?? ''}
            placeholder={isLabelActive || isPlaceholderOnly ? placeholder : ''}
          />
          {renderIcons()}
        </InputSection>
      </Container>

      {listVisible && (
        <DropdownList
          isShort={isShort}
          isSearchable={isSearchable}
          isFullscreen={isFullscreen}
          isMultiSelect={isMultiSelect}
          onSelect={onSelect}
          onClose={toggleDropdown}
          options={options}
          headerTitle={dropdownTitle || label || placeholder}
          searchTitle={searchTitle}
          selectedOptions={selected}
        />
      )}
    </Wrapper>
  );
};

import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as classNames from 'classnames';
import { InvoicesAppContext } from '../context';
import { RoutePath } from '../constants';

export const BusinessTabs = () => {
  const {
    isMobileSite,
    invoiceRef,
    packageRef,
    balanceRef,
    visibleTabRef,
    currentBusinessTab,
    setCurrentBusinessTab
  } = useContext(InvoicesAppContext);
  const location = useLocation();

  const isPackagesPaymentPath = location.pathname.indexOf('packages-payment') !== -1;
  const isPackagesPath = location.pathname === RoutePath.packages;

  const { t } = useTranslation();

  const navigateToInvoicesPage = () => {
    window.location.pathname = isPackagesPaymentPath ? RoutePath.packages : RoutePath.profile;
  };

  const handleRefClick = ref => {
    const yOffset = -150;
    const y = ref?.current?.getBoundingClientRect().top
      + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const tabsClass = (tab, route) => classNames(
    'link',
    { active: (tab === route) }
  );

  return (
    <>
      { isMobileSite ? (
        isPackagesPath || isPackagesPaymentPath ? (
          <div className="business">
            <header className="invoices_header">
              <div className="invoices_header-back" onClick={navigateToInvoicesPage}>
                Back
              </div>
              <div className="invoices_header-title">
                { isPackagesPaymentPath
                  ? t('mobilePaymentPackTitle') : t('mobileHeaderTitle')}
              </div>
            </header>
            {!isPackagesPaymentPath && (
              <div className="business-tabs">
                <div
                  className={tabsClass(visibleTabRef, packageRef)}
                  onClick={() => handleRefClick(packageRef)}
                >
                  {t('tabs.packages')}
                </div>
                <div
                  className={tabsClass(visibleTabRef, balanceRef)}
                  onClick={() => handleRefClick(balanceRef)}
                >
                  {t('tabs.balance')}
                </div>
                <div
                  className={tabsClass(visibleTabRef, invoiceRef)}
                  onClick={() => handleRefClick(invoiceRef)}
                >
                  {t('tabs.invoices')}
                </div>
              </div>
            )}

          </div>
        ) : null
      ) : (!isPackagesPaymentPath
        && (
        <div className="business-tabs">
          <Link
            to={RoutePath.packages}
            className={tabsClass(currentBusinessTab, RoutePath.packages)}
            onClick={() => setCurrentBusinessTab(RoutePath.packages)}
          >
            {t('tabs.packages')}
          </Link>
          <Link
            to={RoutePath.balance}
            className={tabsClass(currentBusinessTab, RoutePath.balance)}
            onClick={() => setCurrentBusinessTab(RoutePath.balance)}
          >
            {t('tabs.balance')}
          </Link>
          <Link
            to={RoutePath.invoices}
            className={tabsClass(currentBusinessTab, RoutePath.invoices)}
            onClick={() => setCurrentBusinessTab(RoutePath.invoices)}
          >
            {t('tabs.invoices')}
          </Link>
        </div>
        )
      )}
    </>
  );
};

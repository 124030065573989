import React, { useRef, useState } from 'react';
import { Swiper } from 'swiper/core';
import { Swiper as SwiperContainer, SwiperSlide } from 'swiper/react';
import * as S from './ItemCard.styles';

interface CarouselCardProps {
  slides: { url: string; label: string }[];
}

export const CarouselCard: React.FC<CarouselCardProps> = ({ slides = [] }) => {
  const swiperRef = useRef<Swiper | null>(null);
  const [slideIndex, setSlideIndex] = useState<number>(1);

  const onSlideChange = (swiper: Swiper) => {
    setSlideIndex(swiper.realIndex + 1);
  };

  const goNext = () => {
    swiperRef?.current?.slideNext();
  };

  const goPrev = () => {
    swiperRef?.current?.slidePrev();
  };

  return (
    <S.Container>
      <S.LeftArrow onClick={goPrev} />
      <S.RightArrow onClick={goNext} />

      <S.Indicators>
        {slides.map((_, index) => {
          const distance = Math.abs(index - slideIndex + 1);
          const fadeLevel = (0.8 - distance * 0.1).toFixed(1);

          return (
            <S.IndicatorDot key={index} fadeLevel={fadeLevel} isActive={index === slideIndex - 1} />
          );
        })}
      </S.Indicators>

      <SwiperContainer
        loop
        noSwiping
        allowTouchMove={false}
        onSlideChange={onSlideChange}
        onInit={swiper => (swiperRef.current = swiper)}
      >
        {slides.map(({ url, label }) => {
          return (
            <SwiperSlide key={url}>
              <S.Slide>
                <S.Image src={url} />
                <S.Label>{label}</S.Label>
              </S.Slide>
            </SwiperSlide>
          );
        })}
      </SwiperContainer>
    </S.Container>
  );
};

import { TFunction } from 'react-i18next';
import { DropdownOption } from 'components';
import {
  Make,
  CatalogItemsResponse,
  FilterOptions,
  ModelsResponse,
  PropertiesResponse,
  QueryParams,
  Section,
  GenerationsResponse,
  PlainModelsResponse
} from '../types';

export const formatModelOptions = (data: ModelsResponse | null) => {
  if (!data) return [];

  return data.reduce((acc: DropdownOption[], item) => {
    const group = item?.group;

    if (group?.id) {
      acc.push({ value: group?.id, label: group?.name, isCategory: true });
    }

    item.models.forEach(model => {
      acc.push({ value: model.id, label: model.name, isCategory: false });
    });

    return acc;
  }, []);
};

export const groupFormatModelOptions = (data: ModelsResponse | null) => {
  if (!data) return [];

  return data.reduce((acc: DropdownOption[], item) => {
    const group = item?.group;

    if (group?.id) {
      acc.push({ value: group?.id, label: group?.name, isCategory: true });
    }

    item.models.forEach(model => {
      acc.push({
        value: model.id,
        label: model.name,
        isCategory: false,
        isGroup: !!item?.group.id
      });
    });

    return acc;
  }, []);
};

export const groupMakeOptions = (
  makes: Make[],
  popularMakes: Make[],
  t: TFunction
): DropdownOption[] => {
  const popular = popularMakes.map(item => ({
    label: item.name,
    image: item.logo_url,
    value: item.id
  }));
  const all = makes
    .filter(item => !popularMakes.find(p => p.id === item.id))
    .map(item => ({ label: item.name, image: item.logo_url, value: item.id }));

  return [
    { isSection: true, label: t('popularMakes'), value: '' },
    ...popular,
    { isSection: true, label: t('allMakes'), value: '' },
    ...all
  ];
};

export const selectInitialModel = (params: QueryParams, options: DropdownOption[]) => {
  if (!options.length) return null;

  return (
    options.find(item => {
      const id = params.model || params.model_group;
      const makeId = String(item.value);
      const isCategory = !params.model && !!params.model_group;

      return makeId === id && item.isCategory === isCategory;
    }) ?? null
  );
};

export const formatFilterOptions = (data: PropertiesResponse): FilterOptions => {
  return {
    categories: data.categories?.map(item => ({ value: item.id, label: item.name })) ?? [],
    transmissions: data.transmissions?.map(item => ({ value: item.id, label: item.name })) ?? [],
    gears: data.gears?.map(item => ({ value: item.id, label: item.name })) ?? [],
    fuelTypes: data.fuel_types?.map(item => ({ value: item.id, label: item.name })) ?? [],
    regYears: data.reg_years?.map(year => ({ value: year, label: String(year) })) ?? [],
    engineVolumes: data.engine_volumes?.map(vol => ({ value: vol, label: String(vol) })) ?? []
  };
};

export const formatCatalogItems = (
  data: CatalogItemsResponse | null
): Array<{ make: DropdownOption; model: DropdownOption; thumbnails: string[] }> => {
  if (!data?.items.length) return [];

  return data.items.map(item => ({
    make: { label: item.make.name, value: item.make.id },
    model: {
      value: item.model?.id || item.model_group?.id,
      label: item.model?.name || item.model_group?.name,
      isCategory: !!item.model_group?.id
    },
    thumbnails: item?.thumbnails ?? []
  }));
};

export function formatSections(array: Section[]): Section[][] {
  return array.reduce(
    (acc: Section[][], curr, idx) => (
      idx % 2 === 0 ? acc.push([curr]) : acc[acc.length - 1].push(curr), acc
    ),
    []
  );
}

export function formatMakeOptions(data: Make[]) {
  return (
    data.map(item => ({
      value: item.id,
      label: item.name
    })) ?? []
  );
}

export function formatGenerationOptions(data: GenerationsResponse): DropdownOption[] {
  return (
    data.map(item => ({
      value: item.id,
      label: item.name
    })) ?? []
  );
}

export function formatPlainModels(data: PlainModelsResponse): ModelsResponse {
  return data.map(group => {
    const groupInfo = {
      id: group.model_group_id ? group.model_group_id.toString() : '',
      name: group.group_name
    };

    const models = group.models.map(model => ({
      id: model[0],
      name: model[1],
      logo_url: ''
    }));

    return { group: groupInfo, models: models };
  });
}

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { InvoicesInfoMobRow } from './InvoicesInfoMobRow';
import { getPaidStatusDispatcher, PaidStatusesMap } from '../../helpers';
import { InvoicesAppContext } from '../../context';
import { RoutePath } from '../../constants';
import { doFormatDate, numberWithCurrency, numberWithFractionDigits } from '../../../../helpers';

export const InvoiceInfoMobile = ({ invoice, paymentStatus }) => {
  const { t } = useTranslation();
  const { selectedInvoice } = useContext(InvoicesAppContext);

  const paidStatusDispatcher = getPaidStatusDispatcher(t);

  const {
    services,
    billing_info: { contract_number, company_tin, company_name },
    status,
    vat_amount,
    amount,
    starts_at,
    expires_at
  } = invoice || selectedInvoice;

  const subheaderStatus = status === PaidStatusesMap.paid
    ? paymentStatus
    : paidStatusDispatcher[status];

  const statusClassName = `invoices-info_subheader invoices-info_subheader--${status}`;

  const invoicesInfoDetails = `${doFormatDate({ date: starts_at })} — ${doFormatDate({ date: expires_at })}`;

  const hasBillingInfo = contract_number || company_tin || company_name;

  return (
    <div className="invoices-info">
      <header className="invoices-info_header">
        <Link
          className="invoices-info_header-close"
          to={{ pathname: RoutePath.invoices }}
          role="button"
          tabIndex="0"
        >
          Back
        </Link>
        <div className="invoices-info_header-title">
          {t('tariffType', { tariff: services.tariff_plan_title })}
        </div>
      </header>
      <div className={statusClassName}>{subheaderStatus}</div>
      <div className="invoices-info_body">
        <div className="invoices-info_content">
          {services.paid_ads_count && (
            <InvoicesInfoMobRow
              label={t('paidAds')}
              value={services.paid_ads_count}
            />
          )}
          {services.wallet_amount && (
            <InvoicesInfoMobRow label={t('paidServices')} value={services.wallet_amount} />
          )}
          {services.discount && (
            <InvoicesInfoMobRow label={t('discount')} value={`${services.discount}%`} />
          )}
          <InvoicesInfoMobRow label={t('dateRange')} value={invoicesInfoDetails} />
          <div className="invoices-info_divider" />
          {services.additional_conditions && (
            <>
              <InvoicesInfoMobRow
                label={t('additionalConditions')}
                value={services.additional_conditions}
              />
              <div className="invoices-info_divider" />
            </>
          )}
          {company_name && (<InvoicesInfoMobRow label={t('customer')} value={company_name} />)}
          {company_tin && (<InvoicesInfoMobRow label={t('voen')} value={company_tin} />)}
          {contract_number && (
            <InvoicesInfoMobRow label={t('contract')} value={contract_number} />
          )}
          {hasBillingInfo && <div className="invoices-info_divider" />}
          {vat_amount && (
            <>
              <InvoicesInfoMobRow label={t('vat18')} value={numberWithFractionDigits(vat_amount)} />
              <div className="invoices-info_divider" />
            </>
          )}
          {amount && (
            <InvoicesInfoMobRow label={t('totalAmount')} value={`${numberWithCurrency(amount)}`} />
          )}
        </div>
        {status === PaidStatusesMap.unpaid && (
          <Link className="invoices-info_footer" to={{ pathname: RoutePath.payment }}>
            <button
              className="invoices-info_pay-btn"
              value={t('pay')}
              type="button"
            >
              {t('pay')}
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

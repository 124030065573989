import styled from 'styled-components';
import { globalTheme } from '../../../styled';

export const theme = isMobileSite => ({
  ...globalTheme(isMobileSite),
  color: {
    ...globalTheme(isMobileSite).color,
    grey: '#a3a3a4',
    pink: '#ff586d'
  },
  borderRadius: isMobileSite ? '8px' : '7px'
});

export const MediumText = styled.span`
  font-weight: 500;
  font-size: 18px;
  ${({ theme: { fontFamily } }) => ({ fontFamily })}
`;

export const ActionButton = styled.div`
  cursor: pointer;
  font-size: 13px;
  text-decoration: underline;
  right: 25px;
  top: 25px;
  ${({ theme: { fontFamily, color: { darkgrey } } }) => ({ fontFamily, color: darkgrey })}
`;

export const RoutePath = {
  autocatalog: '/autocatalog',
  model: '/autocatalog/model',
  search: '/autocatalog/search'
};

export const MODIFICATION_ID = 'modification';

export const INITIAL_FILTERS = {
  yearFrom: null,
  yearTo: null,
  category: null,
  accelTo: '',
  accelFrom: '',
  capFrom: null,
  capTo: null,
  fuelType: null,
  powerFrom: '',
  powerTo: '',
  gear: null,
  transmission: null,
  seatsCount: null
};

export const apiPaths = {
  makes: '/api/v2/catalog/makes',
  newItems: '/api/v2/catalog/items',
  properties: '/api/v2/catalog/properties',
  items: (query: string) => `/api/v2/catalog/items${query}`,
  features: (query: string) => `/api/v2/catalog/model_features${query}`,
  generations: (query: string) => `/api/v2/catalog/generations${query}`,
  models: (id: string | number) => `/api/v2/catalog/makes/${id}/models`
};

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getHelpUrl, getQuestionsByCategory } from '../../../routes';
import { FAQContext } from '../../../context';
import { useAxios } from '../../../../../hooks/useAxios';
import { CategoryQuestionsContainer, Breadcrumb, Title } from './styled';
import { Question } from './Question';
import { Loader } from '../../../../../components/Loader';

export const CategoryQuestions = ({ loadingCategories }) => {
  const { t } = useTranslation();
  const {
    selectedCategory,
    isMobile
  } = useContext(FAQContext);

  const { loading, callRequest, response } = useAxios({
    method: 'get',
    url: getQuestionsByCategory(selectedCategory?.slug)
  });

  useEffect(() => {
    if (selectedCategory?.slug) callRequest();
  }, [selectedCategory]);

  return (
    <CategoryQuestionsContainer>
      {loading || loadingCategories ? <Loader /> : (
        <>
          {!isMobile && (
            <Breadcrumb>
              <a href="/">{t('main')}</a>
              <span>{selectedCategory?.name}</span>
            </Breadcrumb>
          )}
          <Title>{selectedCategory?.name}</Title>
          <Switch>
            <Route path={getHelpUrl(selectedCategory?.slug)}>
              {!!response?.length && (
                response.map(question => <Question key={question} question={question} />)
              )}
            </Route>
            {selectedCategory?.slug && <Redirect from="/help/" to={getHelpUrl(selectedCategory?.slug)} />}
          </Switch>
        </>
      )}
    </CategoryQuestionsContainer>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import * as S from './SearchFilters.styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLangUpdate, useQueryState } from 'packs/autocatalog/hooks';
import {
  countFiltersInURL,
  formatGenerationOptions,
  formatMakeOptions,
  groupFormatModelOptions,
  selectInitialModel
} from 'packs/autocatalog/utils';
import { useAutocatalogContext } from 'packs/autocatalog/context';
import { useCatalogModels, useFilterOptions, useGenerationOptions } from 'packs/autocatalog/api';
import { apiPaths, INITIAL_FILTERS } from 'packs/autocatalog/constants';
import { DropdownOption, DropdownSelect, FilterInput } from 'components';
import { FiltersState } from 'packs/autocatalog/types';
import { EMPTY_PARAMS, SEATS } from './SearchFilters.constants';

export const SearchFilters = () => {
  useLangUpdate();
  const history = useHistory();
  const { t } = useTranslation();
  const context = useAutocatalogContext();
  const [query, setQuery] = useQueryState();

  const filterOptions = useFilterOptions();
  const makeOptions = formatMakeOptions(context.makes);
  const initialGenerations = formatGenerationOptions(context.generations);
  const initMake = makeOptions.find(item => String(item.value) === query.make) ?? null;
  const filtersCount = countFiltersInURL(query);
  const hasFilters = filtersCount > 0;

  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [model, setModel] = useState<DropdownOption | null>(null);
  const [make, setMake] = useState<DropdownOption | null>(initMake);
  const [generation, setGeneration] = useState<DropdownOption | null>(null);
  const [filters, setFilters] = useState<FiltersState>(INITIAL_FILTERS);

  const makeId = make?.value ?? query.make ?? '';
  const modelId = model?.value ?? (query.model || query.model_group) ?? '';
  const generationUrl = `?make=${makeId}&${query.model_group ? 'model_group' : 'model'}=${modelId}`;
  const models = useCatalogModels(makeId ? apiPaths.models(makeId) : null);
  const initialModels = models.data.length ? models.data : groupFormatModelOptions(context.models);
  const initModel = selectInitialModel(query, initialModels);
  const generations = useGenerationOptions(modelId ? apiPaths.generations(generationUrl) : null);
  const generationOptions = generations.data.length ? generations.data : initialGenerations;
  const initGeneration = generationOptions.find(item => item.value === +query.generation) ?? null;

  useEffect(() => {
    if (!query.make) {
      setMake(null);
      setModel(null);
    } else setMake(initMake);
  }, [query.make]);

  useEffect(() => {
    if (query.model || query.model_group) setModel(initModel);
    if (!query.model && !query.model_group) setModel(null);
  }, [query.model, query.model_group, initModel?.value]);

  useEffect(() => {
    if (!query.generation) setGeneration(null);
    else setGeneration(initGeneration);
  }, [query.generation, initGeneration?.value]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!query.generation) setGeneration(null);
    if (!query.model && !query.model_group) setModel(null);
  }, [history.location.search]);

  const getInitialFilter = (key: string, options: DropdownOption[]) => {
    return (
      options.filter(i => {
        if (Array.isArray(query[key])) {
          return query[key]?.includes?.(String(i.value)) ?? false;
        }

        return query[key] === String(i.value);
      }) ?? []
    );
  };

  useEffect(() => {
    if (!query) return;

    setFilters(prev => ({
      ...prev,
      gear: getInitialFilter('[gear][]', filterOptions.data.gears),
      seatsCount: getInitialFilter('[seats_count][]', SEATS),
      fuelType: getInitialFilter('[fuel_type][]', filterOptions.data.fuelTypes),
      category: getInitialFilter('[category][]', filterOptions.data.categories),
      transmission: getInitialFilter('[transmission][]', filterOptions.data.transmissions),
      accelFrom: query?.acceleration_from ?? '',
      accelTo: query?.acceleration_to ?? '',
      powerFrom: query?.power_from ?? '',
      powerTo: query?.power_to ?? '',
      yearTo: filterOptions.data.regYears.find(i => i.value === Number(query.year_to)) ?? null,
      capTo:
        filterOptions.data.engineVolumes.find(i => i.value === Number(query.engine_volume_to)) ??
        null,
      yearFrom: filterOptions.data.regYears.find(i => i.value === Number(query.year_from)) ?? null,
      capFrom:
        filterOptions.data.engineVolumes.find(i => i.value === Number(query.engine_volume_from)) ??
        null
    }));
  }, [filterOptions.data, history.location.search]);

  const onApplyFilters = () => {
    const queryParams = {
      make: make?.value ?? '',
      model: model?.isCategory ? '' : model?.value ?? '',
      model_group: model?.isCategory ? model?.value ?? '' : '',
      generation: generation?.value ?? '',
      power_from: filters?.powerFrom ?? '',
      power_to: filters?.powerTo ?? '',
      acceleration_from: filters?.accelFrom ?? '',
      acceleration_to: filters?.accelTo ?? '',
      year_from: filters.yearFrom?.value ?? '',
      year_to: filters.yearTo?.value ?? '',
      engine_volume_from: filters.capFrom?.value ? filters.capFrom?.value ?? '' : '',
      engine_volume_to: filters.capTo?.value ? filters.capTo?.value ?? '' : '',
      '[transmission][]': filters.transmission?.map(i => String(i.value)) ?? '',
      '[category][]': filters.category?.map(i => String(i.value)) ?? '',
      '[fuel_type][]': filters.fuelType?.map(i => String(i.value)) ?? '',
      '[seats_count][]': filters.seatsCount?.map(i => String(i.value)) ?? '',
      '[gear][]': filters.gear?.map(i => String(i.value)) ?? ''
    };

    setExpanded(false);
    setQuery(queryParams);
  };

  const onResetFilters = () => {
    setExpanded(false);
    setQuery(EMPTY_PARAMS);
    setMake(null);
    setModel(null);
    setGeneration(null);
    setFilters(INITIAL_FILTERS);
  };

  const onMakeChange = (item: DropdownOption) => {
    setMake(item);
    setModel(null);
  };

  const onModelChange = (item: DropdownOption) => {
    setModel(item);
    setGeneration(null);
  };

  const onGenerationChange = (item: DropdownOption) => {
    setGeneration(item);
  };

  return (
    <S.Container>
      <S.Wrapper>
        <div className="main-search__row main-search__row-4 tz-d-grid">
          <DropdownSelect
            isSearchable
            label={t('mainSearch.make')}
            value={make}
            options={makeOptions}
            placeholder={t('mainSearch.inputMake')}
            onChange={onMakeChange}
          />

          <DropdownSelect
            isSearchable
            isCategorized
            label={t('mainSearch.model')}
            value={model}
            isDisabled={!makeId}
            options={models.data}
            placeholder={t('mainSearch.inputModel')}
            onChange={onModelChange}
          />

          <DropdownSelect
            isSearchable
            label={t('mainSearch.generation')}
            value={generation}
            isDisabled={!model}
            options={generations.data}
            placeholder={t('mainSearch.inputGeneration')}
            onChange={onGenerationChange}
          />

          <DropdownSelect
            isSearchable
            isMultiSelect
            label={t('mainSearch.category')}
            value={filters.category}
            options={filterOptions.data.categories}
            onChange={(items: DropdownOption[]) =>
              setFilters(prev => ({ ...prev, category: items }))
            }
          />
        </div>

        <div className="main-search__row main-search__row-4 tz-d-grid">
          <S.Group>
            <DropdownSelect
              isGrouped
              isHighlighted={!!filters.yearTo}
              label={t('mainSearch.yearFrom')}
              value={filters.yearFrom}
              options={filterOptions.data.regYears}
              onChange={(item: DropdownOption) => setFilters(prev => ({ ...prev, yearFrom: item }))}
            />

            <DropdownSelect
              isGrouped
              isHighlighted={!!filters.yearFrom}
              label={t('mainSearch.to')}
              value={filters.yearTo}
              options={filterOptions.data.regYears}
              onChange={(item: DropdownOption) => setFilters(prev => ({ ...prev, yearTo: item }))}
            />
          </S.Group>

          <DropdownSelect
            isMultiSelect
            value={filters.seatsCount}
            options={SEATS}
            label={t('mainSearch.seatsCount')}
            onChange={(items: DropdownOption[]) =>
              setFilters(prev => ({ ...prev, seatsCount: items }))
            }
          />

          <DropdownSelect
            isMultiSelect
            value={filters.fuelType}
            options={filterOptions.data.fuelTypes}
            label={t('mainSearch.fuelType')}
            onChange={(items: DropdownOption[]) =>
              setFilters(prev => ({ ...prev, fuelType: items }))
            }
          />

          <DropdownSelect
            isMultiSelect
            value={filters.gear}
            options={filterOptions.data.gears}
            label={t('mainSearch.gear')}
            onChange={(items: DropdownOption[]) => setFilters(prev => ({ ...prev, gear: items }))}
          />
        </div>

        <S.MinimizedFilters $isExpanded={isExpanded}>
          <div className="main-search__row main-search__row-3 tz-d-grid">
            <S.Group>
              <DropdownSelect
                isGrouped
                isHighlighted={!!filters.capTo}
                value={filters.capFrom}
                options={filterOptions.data.engineVolumes}
                label={t('mainSearch.engineVolumeFrom')}
                onChange={(item: DropdownOption) =>
                  setFilters(prev => ({ ...prev, capFrom: item }))
                }
              />
              <DropdownSelect
                isGrouped
                isHighlighted={!!filters.capFrom}
                value={filters.capTo}
                options={filterOptions.data.engineVolumes}
                label={t('mainSearch.to')}
                onChange={(item: DropdownOption) => setFilters(prev => ({ ...prev, capTo: item }))}
              />
            </S.Group>

            <S.Group>
              <FilterInput
                isGrouped
                isHighlighted={!!filters.powerTo}
                inputValue={filters.powerFrom}
                label={t('mainSearch.powerFrom')}
                onChange={value => setFilters({ ...filters, powerFrom: value })}
              />
              <FilterInput
                isGrouped
                isHighlighted={!!filters.powerFrom}
                inputValue={filters.powerTo}
                label={t('mainSearch.to')}
                onChange={value => setFilters({ ...filters, powerTo: value })}
              />
            </S.Group>

            <S.Group>
              <FilterInput
                isGrouped
                isHighlighted={!!filters.accelTo}
                inputValue={filters.accelFrom}
                label={t('mainSearch.acceleration')}
                onChange={value => setFilters(prev => ({ ...prev, accelFrom: value }))}
              />
              <FilterInput
                isGrouped
                isHighlighted={!!filters.accelFrom}
                inputValue={filters.accelTo}
                label={t('mainSearch.to')}
                onChange={value => setFilters(prev => ({ ...prev, accelTo: value }))}
              />
            </S.Group>
          </div>

          <div className="main-search__row main-search__row-4 tz-d-grid">
            <DropdownSelect
              isMultiSelect
              value={filters.transmission}
              options={filterOptions.data.transmissions}
              label={t('mainSearch.transmission')}
              onChange={(items: DropdownOption[]) =>
                setFilters(prev => ({ ...prev, transmission: items }))
              }
            />
          </div>
        </S.MinimizedFilters>

        <S.FilterControls>
          <S.ResetFilters onClick={onResetFilters}>{t('mainSearch.reset')}</S.ResetFilters>
          <S.ExpandFilters $isExpanded={isExpanded} onClick={() => setExpanded(prev => !prev)}>
            {isExpanded ? t('mainSearch.slideUp') : t('mainSearch.moreFilters')}
            {hasFilters && (
              <span className="main-search__count-filters js-count-filters">{filtersCount}</span>
            )}
          </S.ExpandFilters>

          <S.Button onClick={onApplyFilters}>{t('mainSearch.submit')}</S.Button>
        </S.FilterControls>
      </S.Wrapper>
    </S.Container>
  );
};

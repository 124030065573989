import React, { useContext, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAxios } from '../../../../hooks/useAxios';
import { InvoicesAppContext } from '../../context';
import { PackagesUnpaidBlock } from './PackagesUnpaidBlock';
import { getInvoicesByScopesPath } from '../../routes';
import { useOnScreen } from '../../../../hooks/useOnScreen';

export const PackagesUnpaid = () => {
  const {
    setHasErrors, setVisibleTabRef, setPackageRef, visibleTabRef
  } = useContext(InvoicesAppContext);
  const location = useLocation();
  const packagesPaymentPath = location.pathname.indexOf('packages-payment') !== -1;
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const { response } = useAxios({
    method: 'get',
    url: getInvoicesByScopesPath('unpaid'),
    isInitialCall: true,
    onError: () => setHasErrors(true)
  });

  useEffect(() => {
    setPackageRef(ref);
    if (isVisible || !visibleTabRef) {
      setVisibleTabRef(ref);
    }
  }, [isVisible]);

  return (
    <div className="packages-unpaid__container" ref={ref}>
      {response && !packagesPaymentPath ? (
        response?.map(unpaid => (
          <PackagesUnpaidBlock
            key={unpaid.id}
            invoice={unpaid}
          />
        ))
      ) : null}
    </div>
  );
};

import styled, { css } from 'styled-components';
import ArrowDownSvg from '@assets/images/application/icon-arrow-down--blue.svg';

const SelectedRow = css`
  p {
    color: ${({ theme }) => theme.palette.red[100]};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
`;

export const SpecsButtons = styled.div`
  display: flex;
  gap: 15px;
  row-gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;

export const SpecsTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin: 15px 0;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const ModelItem = styled.div`
  flex: 1;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  .card {
    width: 235px;
    height: 165px;
  }
`;

export const GenerationItem = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
  padding-bottom: 20px;
  border-bottom: ${({ theme }) => theme.border};
`;

export const SpecsContainer = styled.div`
  flex: 1;
`;

export const HiddenSpecsContainer = styled.div<{ $isVisible: boolean }>`
  display: flex;
  border-radius: 7px;
  padding: 10px 15px;
  margin-top: 5px;
  gap: 10px;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
`;

export const SpecsGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SpecsRow = styled.div<{ $isClickable?: boolean; $isVisible: boolean }>`
  display: flex;
  gap: 15px;
  user-select: none;
  position: relative;
  ${({ $isVisible }) => ($isVisible ? SelectedRow : null)};
  ${({ $isClickable }) => ($isClickable ? 'cursor: pointer;' : '')};
`;

export const ExpandIconContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: block;
  height: 9px;
`;

export const ExpandIcon = styled.span<{ $isVisible: boolean }>`
  display: block;
  width: 17px;
  height: 9px;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-image: url(${ArrowDownSvg});
  -webkit-mask-image: url(${ArrowDownSvg});
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: ${({ theme, $isVisible }) =>
    $isVisible ? theme.palette.blue[200] : theme.palette.grey[200]};
  transform: ${({ $isVisible }) => ($isVisible ? 'rotate(180deg)' : '')};
`;

export const SpecsCol = styled.div`
  flex: 1;

  &:first-child {
    flex: 2.5;
  }
`;

export const HiddenSpecsCol = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;
`;

export const SpecValue = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const SpecHeader = styled.p`
  font-size: 15px;
  font-weight: 400;
  user-select: none;
  color: ${({ theme }) => theme.palette.grey[200]};
  padding-bottom: 10px;
`;

export const HiddenSpecHeader = styled.p`
  font-size: 15px;
  font-weight: 400;
  user-select: none;
  white-space: nowrap;
  word-break: keep-all;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

export const FeatureItem = styled.div`
  padding: 10px 0;
  border-bottom: ${({ theme }) => theme.border};
`;

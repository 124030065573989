import React, {
  useContext, useState, useCallback
} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as classNames from 'classnames';
import { InvoicesAppContext } from '../../context';
import { RoutePath } from '../../constants';
import {
  getPaidStatusDispatcher,
  getStatusFormatDate,
  PaidStatusesMap
} from '../../helpers';
import { InvoiceInfo } from '../InvoiceInfo';
import { doFormatDate } from '../../../../helpers';

export const TableRow = ({ invoice }) => {
  const {
    status, id, amount, status_datetime, invoice_date, services, print_url
  } = invoice;

  const { t } = useTranslation();
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const {
    isMobileSite,
    setSelectedInvoice,
    setIsModalVisible
  } = useContext(InvoicesAppContext);

  const showPaymentModal = () => {
    setSelectedInvoice(invoice);
    setIsModalVisible(true);
  };

  const paidStatus = `invoices-table_status invoices-table_status--${status}`;

  const paidStatusDispatcher = getPaidStatusDispatcher(t);

  const menuBtnClassName = classNames(
    { 'show-details': !isInfoVisible },
    { 'hide-details': isInfoVisible }
  );

  const formatedAmount = Math.floor(Number(amount));
  const formatedInvoiceDate = doFormatDate({ date: invoice_date });

  const selectInvoice = useCallback(() => {
    setSelectedInvoice(invoice);
  }, [invoice]);

  return (
    <>
      {isMobileSite ? (
        <div onClick={selectInvoice}>
          <div>
            <div className="invoices-table_tariff">
              {t('tariffType', { tariff: services.tariff_plan_title })}
            </div>
            <div className="invoices-table_number">{id}</div>
            {print_url && (
              <a href={print_url} target="_blank" rel="noreferrer" className="invoices-table_download">
                {t('download')}
              </a>
            )}
          </div>
          <Link
            className="invoices-table_row"
            data-invoice-id={id}
            to={{ pathname: `${RoutePath.invoices}/${id}` }}
          >
            <div>
              <div className="invoices-table_price">{`${formatedAmount} AZN`}</div>
              <div className={paidStatus}>{paidStatusDispatcher[status]}</div>
            </div>
          </Link>
        </div>
      ) : (
        <div className="invoices-table_row invoices-grid" data-invoice-id={id}>
          <div className="invoices-table_body-col">{formatedInvoiceDate}</div>
          <div className="invoices-table_body-col">{id}</div>
          <div className="invoices-table_body-col">{t('tariffType', { tariff: services.tariff_plan_title })}</div>
          <div className="invoices-table_body-col">{`${formatedAmount} AZN`}</div>
          <div className="invoices-table_body-col">
            <div className={paidStatus}>{paidStatusDispatcher[status]}</div>
            <div>{getStatusFormatDate(status, status_datetime)}</div>
          </div>
          <div className="invoices-table_body-col">
            {print_url && <a href={print_url} target="_blank" rel="noreferrer" className="invoices-table_download" />}
          </div>
          <div className="invoices-table_body-col flex">
            {status === PaidStatusesMap.unpaid && (
              <button
                className="invoices-table_pay-btn"
                value={t('pay')}
                type="button"
                onClick={showPaymentModal}
              >
                {t('pay')}
              </button>
            )}
            <div
              className={menuBtnClassName}
              title={t('preview')}
              onClick={() => setIsInfoVisible(!isInfoVisible)}
            />
          </div>
        </div>
      )}
      {isInfoVisible && <InvoiceInfo {...{ invoice }} />}
    </>
  );
};

import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { InvoicesAppContext } from '../../context';
import { numberWithFractionDigits, doFormatDate } from '../../../../helpers';
import { TimeFormat } from '../../../../types';
import { RoutePath } from '../../constants';

export const PackageActive = ({ invoice, showPromotions, showDiscount }) => {
  const { t } = useTranslation();
  const { packageTariffs, isMobileSite } = useContext(InvoicesAppContext);
  const history = useHistory();
  const [tariff, setTariff] = useState(null);
  const {
    services: {
      tariff_plan_title,
      paid_ads_count,
      wallet_amount,
      additional_conditions,
      additional_services,
      promotions,
      discount
    },
    deal: { starts_at, expires_at }
  } = invoice;

  const startsAt = new Date(starts_at).getTime();
  const expiresAt = new Date(expires_at).getTime();
  const now = new Date().getTime();
  const progress = `${100 - (100 - ((now - startsAt) / (expiresAt - startsAt)) * 100)}%`;

  const filtered = packageTariffs?.find(
    tariffs => tariffs.name === invoice?.services?.tariff_plan_title
  );

  useEffect(() => {
    if (filtered) setTariff(filtered);
  }, [packageTariffs]);

  const additionalConditions = additional_conditions?.length > 0 && (
    <div className="package-active__additional-info">
      {t('packages.additional_info.title')}
      <span>
        {additional_conditions}
      </span>
    </div>
  );

  const additionalPromotions = (promotions?.length > 0
    || additional_services?.banner_ad_impressions_count > 0) && (
    <>
      {promotions?.length > 0 && (
        <div className="package-active__additional-info">
          {t('packages.additional_info.title')}
          {promotions?.map(promotion => (
            <span key={promotion}>
              {t(promotion)}
            </span>
          ))}
        </div>
      )}
      { additional_services?.banner_ad_impressions_count > 0 && (
        <div className="package-active__additional-info">
          {t('packages.additional_info.impressionCount')}
          <span>
            {additional_services?.banner_ad_impressions_count}
          </span>
        </div>
      )}
    </>
  );

  const showAdditionalConditions = additionalConditions || additionalPromotions;

  const packageClassNames = classNames(
    'package-active',
    tariff_plan_title?.toLowerCase() === 'platinum+' ? 'platinum-plus' : tariff_plan_title?.toLowerCase()
  );

  return (
    <div className={packageClassNames}>
      <div className="package-active__name">
        {tariff_plan_title}
      </div>
      <div className="package-active__info">
        <div>
          <div className="package-active__info_title">{t('packages.progress')}</div>
          <span className="package-active__info_value">
            {`${numberWithFractionDigits(wallet_amount, { minimumFractionDigits: 0 })} AZN`}
          </span>
          {invoice?.services?.discount
            && <span className="discount">{t('packages.discount', { name: invoice?.services?.discount })}</span>}
        </div>
        <div>
          <div className="package-active__info_title">
            {t('packages.ads_count')}
          </div>
          <span className="package-active__info_value">{paid_ads_count}</span>
        </div>
        <div>
          <div className="package-active__info_title">{t('packages.time')}</div>
          <span className="package-active__info_value">
            {doFormatDate({
              date: expires_at,
              format: TimeFormat.date
            })}
          </span>
          <div className="progress">
            <div className="progress-bar" style={{ width: progress }} />
          </div>
        </div>
        { isMobileSite && showAdditionalConditions}
        { tariff && (
          <button
            className="package-active__info_pay"
            type="button"
            onClick={() => history.push({
              pathname: RoutePath.packages_payment(tariff?.name),
              state: {
                name: tariff?.name,
                price: tariff?.price,
                pricePeriod: tariff?.price_per_period,
                promotions: tariff?.promotions,
                showPromotions,
                wallet_amount: tariff?.wallet_amount,
                paid_ads_count: tariff?.paid_ads_count,
                expired: expires_at,
                discount,
                showDiscount,
                additionalServices: tariff?.additional_services?.banner_ad_impressions_count,
                rawPricePerPeriod: tariff?.raw_price_per_period,
                discountsPercents: tariff?.promotion?.discounts,
                displayDiscounts: tariff?.promotion?.display_discounts,
                promoPricePerPeriod: tariff.promo_price_per_period,
                promoPriceDiscounts: tariff.promo_price?.discounts,
                promoPriceDisplayDiscounts: tariff.promo_price?.display_discounts
              }
            })}
          >
            {t('packages.name', { name: tariff_plan_title })}
          </button>
        )}
      </div>
      { !isMobileSite && showAdditionalConditions}
    </div>
  );
};

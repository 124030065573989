import { QueryParams } from '../types';

export const FILTERS = [
  'power_from',
  'power_to',
  '[fuel_type][]',
  'engine_volume_from',
  'engine_volume_to',
  'acceleration_from',
  'acceleration_to',
  '[seats_count][]',
  'year_to',
  'year_from',
  '[category][]',
  '[gear][]',
  '[transmission][]'
];

export function countFiltersInURL(params: QueryParams): number {
  return Object.keys(params).filter(key => FILTERS.includes(key)).length;
}

import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.color.white};
`;

export const Title = styled.p`
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 5px;
`;

export const Value = styled.p`
  font-size: 15px;
  font-weight: 700;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SpecIcon = styled.img`
  max-width: 100%;
  width: 70px;
  height: auto;
  object-fit: contain;
`;

import useSWR from 'swr';
import { fetcher } from './api';
import { formatCatalogItems } from '../utils';
import { CatalogItemsResponse } from '../types';

type TypedResponse = { data: CatalogItemsResponse };

export const useCatalogItems = (url: string | null) => {
  const { data, isLoading, error, mutate } = useSWR<TypedResponse>(url, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    keepPreviousData: true
  });

  return {
    mutate,
    isLoading,
    isError: error,
    next_page: data?.data?.next_page,
    data: formatCatalogItems(data?.data ?? null)
  };
};

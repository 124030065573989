import React, {
  useContext, useState, useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { getInvoicesPaymentPath } from '../routes';
import { InvoicesAppContext } from '../context';
import { RoutePath, PaymentOption } from '../constants';
import { numberWithCurrency } from '../../../helpers';
import {
  openNewPaymentWindow, paymentData
} from '../helpers';
import { PaymentButton } from './PaymentButton';

export const PaymentMethods = () => {
  const { t } = useTranslation();
  const {
    bankCardProvider,
    selectedInvoice,
    isMobileSite,
    hasErrors,
    balanceValueData,
    setHasErrors
  } = useContext(InvoicesAppContext);

  const [selectedOption, setSelectedOption] = useState(bankCardProvider);

  const [blockBtnOnRequest, setBlockBtnOnRequest] = useState(false);

  const [dataUrl, setDataUrl] = useState(null);

  const { portmanat, wallet, cash } = PaymentOption;

  const { headers, body } = paymentData(selectedOption);

  if (!selectedInvoice?.id) {
    return <Redirect to={{ pathname: RoutePath.invoices }} />;
  }

  const initializePayment = async () => {
    const path = getInvoicesPaymentPath(selectedInvoice.id);

    try {
      const res = await fetch(path, {
        method: 'POST',
        headers,
        body
      });

      const data = await res.json();

      if (data.url) {
        setDataUrl(data);
      } else {
        openNewPaymentWindow(data, true);
      }

      return data;
    } catch (e) {
      setHasErrors(e);
      onClose();
    }
  };

  if (hasErrors) {
    return <Redirect to={{ pathname: RoutePath.invoices }} />;
  }

  const isBalanceEnough = Number(balanceValueData) >= Number(selectedInvoice.amount);

  const blockPaymentBtn = selectedOption === wallet ? !isBalanceEnough : null;

  const handlePaymentClick = useCallback((res, paymentUrl, selectedPayment, openedWin) => {
    if (res?.status === 'unpaid') {
      if (selectedPayment === 'wallet') {
        setBlockBtnOnRequest(true);
        initializePayment(selectedPayment);
      } else if (selectedPayment === 'terminal') {
        openKioskModal();
      } else {
        openNewPaymentWindow(paymentUrl, openedWin);
      }
    }
  },
  [selectedInvoice?.id, selectedOption, dataUrl]);

  const description = () => (selectedInvoice?.services?.discount ? 'descriptionDiscount' : 'description');

  const digitalPayType = window.Detector.ios ? 'apple' : 'google';

  return isMobileSite ? (
    <div className="payment-methods">
      <header className="payment-methods_header">
        <Link className="payment-methods_header-close" to={{ pathname: RoutePath.invoices }}>
          Close
        </Link>
        <div className="payment-methods_header-title">
          {t('payment.modal.title', { name: selectedInvoice?.services?.tariff_plan_title })}
        </div>
      </header>
      <div className="package-payment_modal_description">
        <p>
          {t(`payment.modal.${description()}`, {
            count: selectedInvoice?.services?.paid_ads_count,
            sum: selectedInvoice?.services?.wallet_amount,
            discount: selectedInvoice?.services?.discount
          })}
        </p>
      </div>
      <div className="package-payment_modal_source">
        <p>
          {t('payment.source')}
        </p>
      </div>
      <section className="payment-methods_body">
        <ul>
          <li className="payment-methods_option">
            <input
              id={bankCardProvider}
              name="payment-option"
              type="radio"
              value={bankCardProvider}
              onClick={() => setSelectedOption(bankCardProvider)}
              defaultChecked
            />
            <label htmlFor={bankCardProvider}>
              {t('modal.card')}
              {isMobileSite && bankCardProvider === 'yigim' && (
                <span className={`digital-payment-provider digital-payment-provider--${digitalPayType}-pay`} />
              )}
            </label>
          </li>
          <li className="payment-methods_option">
            <input
              id={portmanat}
              name="payment-option"
              type="radio"
              value={portmanat}
              onClick={() => setSelectedOption(portmanat)}
            />
            <label htmlFor={portmanat}>{t('modal.portmanat')}</label>
          </li>
          <li className="payment-methods_option">
            <input
              id={cash}
              name="payment-option"
              type="radio"
              value={cash}
              onClick={() => setSelectedOption(wallet)}
            />
            <label htmlFor={cash}>{t('modal.wallet', { sum: numberWithCurrency(balanceValueData) })}</label>
          </li>
        </ul>
      </section>
      <footer className="payment-methods_footer">
        <p className="payment-methods_confirm-rules">
          <span>{t('modal.confirmRules1')}</span>
          <a href="/pages/terms-and-conditions">{t('modal.confirmRules2')}</a>
          <a href="/pages/rules">{t('modal.confirmRules3')}</a>
          <span>{t('modal.confirmRules4')}</span>
          <a href="/pages/proposal">{t('modal.confirmRules5')}</a>
          <span>{t('modal.confirmRules6')}</span>
        </p>
        <PaymentButton
          disabled={blockBtnOnRequest || blockPaymentBtn}
          checkUnpaidStatus={handlePaymentClick}
          buttonClassName="payment-methods-button"
          invoiceId={selectedInvoice.id}
          initializePayment={initializePayment}
          selectedPayment={selectedOption}
        />
      </footer>
    </div>
  ) : null;
};

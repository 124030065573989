import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FAQContent = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 100px;
  font-family: ${({ theme: { fontFamily } }) => fontFamily};
  font-weight: 400;
  font-size: 15px;

  ${({ theme: { isMobileSite } }) => isMobileSite && ({
    flexDirection: 'column',
    margin: 0,
    fontSize: '14px'
  })};
`;

export const Categories = styled.menu`
  width: 305px;
  height: min-content;
  margin: 0;
  padding: 40px 25px 15px;
  border-radius: 7px;
  background-color: ${({ theme: { color: { grey } } }) => grey};
  list-style: none;
  box-sizing: border-box;

  ${({ theme: { isMobileSite, color: { white, lightGrey } } }) => isMobileSite && ({
    width: '100%',
    height: '66px',
    backgroundColor: white,
    padding: '15px',
    display: 'flex',
    borderBottom: `solid 1px ${lightGrey}`,
    borderRadius: 0,
    overflow: 'auto hidden'
  })};
  
  li {
    margin-bottom: 25px;
   }
`;

export const Category = styled(Link)`
  line-height: 20px;
  color: ${({ theme: { color: { darkgrey, mainTurboColor } }, $isActive }) => ($isActive ? mainTurboColor : darkgrey)};
  text-decoration: none;

  ${({ theme: { isMobileSite, color }, $isActive }) => isMobileSite && ({
    padding: '10px 15px',
    lineHeight: '16px',
    color: ($isActive ? color.white : color.blueMidnight),
    backgroundColor: $isActive ? color.mainTurboColor : color.grey,
    borderRadius: '20px',
    whiteSpace: 'nowrap',
    margin: '0 10px 0 0',
    display: 'flex'
  })};

  &:hover {
    ${({ theme: { color: { blue }, isMobileSite } }) => !isMobileSite && ({ color: blue })};
  }
`;

import { DropdownOption } from 'components';

export enum BodyTypes {
  coupe = 'coupe',
  suv_open = 'suv_open',
  roadster = 'roadster',
  minivan = 'minivan',
  cabriolet = 'cabriolet',
  phaeton = 'phaeton',
  sedan = 'sedan',
  compact_van = 'compact_van',
  liftback = 'liftback',
  hatchback_5_doors = 'hatchback_5_doors',
  hatchback_4_doors = 'hatchback_4_doors',
  hatchback_3_doors = 'hatchback_3_doors',
  pickup = 'pickup',
  pickup_single_cab = 'pickup_single_cab',
  pickup_double_cab = 'pickup_double_cab',
  suv_3_doors = 'suv_3_doors',
  suv_5_doors = 'suv_5_doors',
  targa = 'targa',
  microvan = 'microvan',
  estate_3_doors = 'estate_3_doors',
  estate_5_doors = 'estate_5_doors',
  speedster = 'speedster',
  limousine = 'limousine',
  van = 'van',
  fastback = 'fastback'
}

interface NamedEntity {
  id: number;
  name: string;
}

export interface Generation {
  name: string;
  id: number;
  years: string;
  categories: Category[];
}

export interface Category extends NamedEntity {
  icon: string;
  modification: Modification;
}

export interface Modification {
  id: number;
  option_name: string;
  gears: number;
  engine_capacity: string;
  power: string;
  make: Make;
  model: NamedEntity;
  model_group: NamedEntity;
  generation: Generation;
  category: NamedEntity;
  fuel_type: NamedEntity;
  transmission: NamedEntity;
  gear: NamedEntity;
  geometry: Geometry;
  sections: Section[];
  photos: string[];
  thumbnails?: string[];
  category_icon?: BodyTypes;
}

interface Geometry {
  width: number;
  height: number;
  length: number;
  clearance: number;
}

export interface Section {
  name: string;
  type?: string;
  fields: Field[];
}

export interface Field {
  label: string;
  value: string | number | string[] | number[];
  field?: string;
}

export interface Make {
  id: number | string;
  name: string;
  logo_url: string;
}

export interface CatalogItemsResponse {
  items: Array<{
    make: NamedEntity;
    model: NamedEntity;
    model_group: NamedEntity;
    thumbnails: string[];
  }>;
  next_page?: number;
}

export interface CatalogItem {
  id: number;
  name: string;
}
export type ModelsResponse = Array<{
  group: { id: string; name: string };
  models: Make[];
}>;

export type PlainModelsResponse = Array<{
  make_id: number;
  model_group_id: number;
  group_name: string;
  models: string[];
}>;

export interface QueryParams {
  [query: string]: any;
}

export type CatalogNewItems = Array<{
  make: {
    id: number;
    name: string;
  };
  model: {
    id: number;
    name: string;
  };
  model_group?: {
    id: number;
    name: string;
  };
  thumbnails: string[];
}>;

export interface MakesResponse {
  makes: Make[];
  popular_makes: Make[];
}

export type GenerationsResponse = Array<Generation>;

export interface MakeSearchState {
  make: DropdownOption | null;
  model: DropdownOption | null;
  generation: DropdownOption | null;
}

export interface PropertiesResponse {
  reg_years: number[];
  gears: NamedEntity[];
  categories: NamedEntity[];
  engine_volumes: number[];
  fuel_types: NamedEntity[];
  transmissions: NamedEntity[];
}

export interface FilterOptions {
  regYears: DropdownOption[];
  gears: DropdownOption[];
  categories: DropdownOption[];
  engineVolumes: DropdownOption[];
  fuelTypes: DropdownOption[];
  transmissions: DropdownOption[];
}

export interface FiltersState {
  yearFrom: DropdownOption | null;
  yearTo: DropdownOption | null;
  category: DropdownOption[] | null;
  accelTo: string;
  accelFrom: string;
  capFrom: DropdownOption | null;
  capTo: DropdownOption | null;
  fuelType: DropdownOption[] | null;
  powerFrom: string;
  powerTo: string;
  gear: DropdownOption[] | null;
  transmission: DropdownOption[] | null;
  seatsCount: DropdownOption[] | null;
}

export interface ModificationsResponse {
  name: string;
  items: Array<{
    id: number;
    engine_capacity: string;
    power: string;
    transmission: string;
    gear: string;
  }>;
}

export interface FeatureTab {
  fields: string[];
  hidden_fields: string[];
  id: string;
  label: string;
}

export interface Feature {
  [key: string]: {
    id: number;
    label: string;
    value: string | number | null;
  };
}

export interface FeaturesResponse {
  feature_tabs: Array<FeatureTab>;
  categories: Array<{
    generation_id: number;
    name: string;
    items: Array<{
      name: string;
      thumbnail: string;
      features: Array<Feature>;
    }>;
  }>;
}

import {
  arrayOf, bool, func, number, shape, oneOf, string, objectOf, object
} from 'prop-types';
import { paidStatuses } from '../constants';
import { PaidStatusesMap } from '../helpers';

export const initialCurrentInvoicesTab = {
  currentTabId: 1,
  currentFilter: PaidStatusesMap.all,
  filteredInvoices: []
};

export const initialInvoicesRawData = {
  allInvoices: [],
  totalCount: null,
  currentPage: 1
};

export const initialSelectedInvoice = {
  id: null,
  services: null,
  billing_info: null,
  status: null,
  vat_amount: null,
  amount: null,
  payment_source: null,
  status_datetime: null,
  starts_at: null,
  expires_at: null,
  invoice_date: null
};

export const globalState = {
  invoicesRawData: initialInvoicesRawData,
  setInvoicesRawData: null,
  isMobileSite: false,
  currentInvoicesTab: initialCurrentInvoicesTab,
  setCurrentInvoicesTab: null,
  bankCardProvider: null,
  selectedInvoice: initialSelectedInvoice,
  setSelectedInvoice: null,
  isModalVisible: false,
  setIsModalVisible: func,
  hasErrors: false,
  setHasErrors: null,
  balanceValue: null,
  visibleTabRef: null
};

globalState.propTypes = {
  currentInvoicesTab: objectOf(shape({
    currentTabId: number.isRequired,
    currentFilter: oneOf(paidStatuses),
    filteredInvoices: arrayOf(paidStatuses)
  })),
  invoicesRawData: objectOf(shape({
    allInvoices: arrayOf(number),
    totalCount: number,
    currentPage: number.isRequired
  })),
  setCurrentInvoicesTab: func,
  isModalVisible: bool,
  hasErrors: bool,
  setHasErrors: func,
  isMobileSite: bool,
  balanceValue: string,
  setIsModalVisible: func,
  bankCardProvider: string,
  setState: func,
  selectedInvoice: objectOf(shape({
    amount: string,
    billing_info: object,
    description: string,
    expires_at: string,
    id: number,
    invoice_date: string,
    payment_source: string,
    services: objectOf(shape({
      discount: string,
      wallet_amount: string,
      paid_ads_count: string,
      tariff_plan_title: string,
      additional_conditions: string
    })),
    starts_at: string,
    status: string,
    status_datetime: string
  })),
  setSelectedInvoice: func
};

import { useState } from 'react';

export const useNumericInput = (initialValue = '', maxDigits = 7) => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = (inputValue: string) => {
    const numericValue = inputValue.replace(/\D/g, '');
    const numericInput = parseInt(numericValue, 10);

    if (
      inputValue === '' ||
      (!isNaN(numericInput) && numericInput < Math.pow(10, maxDigits))
    ) {
      const formattedValue = numericValue.replace(
        /(\d)(?=(\d{3})+(?!\d))/g,
        '$1 '
      );

      setValue(formattedValue);
    }
  };

  return { value, handleChange };
};

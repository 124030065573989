import i18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

i18n.use(intervalPlural);

export const customizeI18n = ({ az, ru }) => {
  const newInstance = i18n.createInstance({
    lng: document.querySelector('html').getAttribute('lang'),
    fallbacks: true,
    fallbackLng: 'az',
    nonExplicitWhitelist: true,
    load: 'languageOnly',
    debug: false,
    resources: {
      az: {
        translation: az
      },
      ru: {
        translation: ru
      }
    },
    languages: ['az', 'ru'],
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true
    },
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span']
  }, (err, t) => {
    if (err) return console.log('Something went wrong loading', err);
    t('key');
  });

  return newInstance;
};

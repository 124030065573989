import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body, .body-inner, .content {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    height: auto;
    min-height: 100%;
  }

  #autocatalog-app {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
  }
`;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { setSuffix } from '../../helpers';
import { RoutePath } from '../../constants';
import { checkPromotions } from '../../../../hooks/checkPromotions';

export const Package = ({
  name,
  price,
  promotions,
  count,
  wallet_amount,
  expired,
  pricePeriod,
  showPromotions,
  showDiscount,
  discount,
  additionalServices,
  rawPricePerPeriod,
  discountsPercents,
  displayDiscounts,
  promoPricePerPeriod,
  promoPriceDiscounts,
  promoPriceDisplayDiscounts
}) => {
  const { i18n, t } = useTranslation();
  const renderPromotions = checkPromotions(promotions, showPromotions);
  const [expireAt, setExpireAt] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (expired?.length) {
      const maxInvoicesExpireTime = expired?.filter(value => Number.isFinite(value))
        .reduce((a, b) => Math.max(a, b), []);
      setExpireAt(new Date(maxInvoicesExpireTime)?.toISOString());
    }
  }, [expired]);

  const packageClassNames = classNames(
    'package',
    name?.toLowerCase() === 'platinum+' ? 'platinum-plus' : name?.toLowerCase()
  );

  const showPromotionLabel = showDiscounts => showDiscounts
        && Object.values(showDiscounts).some(value => value === true);

  const packageDiscounts = param => classNames(
    'package-info',
    { 'not-include': !param }
  );

  return (
    <div
      className={packageClassNames}
      onClick={() => history.push({
        pathname: RoutePath.packages_payment(name),
        state: {
          name,
          price,
          pricePeriod,
          promotions,
          showPromotions,
          wallet_amount,
          paid_ads_count: count,
          expired: expireAt,
          discount,
          showDiscount,
          additionalServices,
          rawPricePerPeriod,
          discountsPercents,
          displayDiscounts,
          promoPricePerPeriod,
          promoPriceDiscounts,
          promoPriceDisplayDiscounts
        }
      })}
    >
      {(showPromotionLabel(promoPriceDisplayDiscounts)
        || showPromotionLabel(displayDiscounts))
        && (
          <div className="package-discount">
            <span>
              %
            </span>
          </div>
        )}
      <div className="package-name">
        {name.replace(/i/g, 'ı')}
      </div>
      <div className="package-price">
        {`${price} AZN`}
      </div>
      <div className="package-info">
        {`${count} `}
        {t(setSuffix(i18n, count, 'packages.count'))}
      </div>
      <div className="package-info">
        {t('packages.services_price', { services_price: wallet_amount })}
      </div>
      {showDiscount && (
        <div className={packageDiscounts(discount)}>
          {discount ? t('packages.discountServices', { discount })
            : t('packages.withoutDiscountServices')}
        </div>
      )}
      {additionalServices ? (
        <div className="package-info">
          {t('packages.impressionCount', { count: additionalServices })}
        </div>
      ) : renderPromotions}
      <div className="package-buy">
        {t('packages.buy', { name })}
      </div>
    </div>
  );
};

import styled from 'styled-components';
import {
  blueMidnight, defaultBorder, FlexColumn, darkgrey
} from '../../../../../styled';

export const AdInfo = styled.a`
  display: flex;
  padding: 20px 30px;
  background-color: #f8f9fd;
  border-bottom: ${defaultBorder};
  color: ${blueMidnight};
  font-size: 14px;
  text-decoration: none;
`;

export const AdPhoto = styled.div`
  width: 70px;
  height: 70px;
  background-position: center;
  background-size: cover;
  background-image: url(${p => p.photo});
  border-radius: 7px;
`;

export const AdTitle = styled(FlexColumn)`
  margin-left: 10px;
  line-height: 1.36;
  width: 265px;
  
  &:first-child {
    margin-bottom: 1px;
  }
`;

export const Description = styled(FlexColumn)`
  padding: 0 30px 40px;
`;

export const Row = styled.div`
  border-bottom: ${defaultBorder};
  display: flex;
  padding: 5px 0;
  align-items: center;
  font-size: 15px;
  min-height: 46px;
  color: ${blueMidnight};
  box-sizing: border-box;
  
  &.sum {
    border-bottom: none;
    & > span {
      color: ${blueMidnight};
      font-weight: 500;
    }
  }
`;

export const RowTitle = styled.span`
  flex: none;
  color: ${darkgrey};
  width: 148px;
  margin-right: 30px;
`;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow } from './Row';
import { InvoicesAppContext } from '../../context';

export const Table = () => {
  const { t } = useTranslation();
  const { currentInvoicesTab: { filteredInvoices }, isMobileSite } = useContext(InvoicesAppContext);

  const invoicesMapped = filteredInvoices.map(inv => (
    <TableRow key={inv.id} invoice={inv} />
  ));

  return (
    <div className="invoices_table invoices-table">
      {!isMobileSite && (
        <div className="invoices-table_header invoices-grid">
          <div className="invoices-table_header-col">{t('created')}</div>
          <div className="invoices-table_header-col">{t('number')}</div>
          <div className="invoices-table_header-col">{t('serviceType')}</div>
          <div className="invoices-table_header-col">{t('sum')}</div>
          <div className="invoices-table_header-col">{t('statusNdate')}</div>
        </div>
      )}
      <div className="invoices-table_body">{invoicesMapped}</div>
    </div>
  );
};

type Currency = 'azn' | 'usd' | 'eur';
type Status = 'pending' | 'approved' | 'rejected';

export const enum ItemTabName {
  expired = 'expired',
  pending = 'pending',
  rejected = 'rejected',
  all = 'all',
  published = 'published'
}

export enum Actions {
  pay = 'pay',
  prolong = 'prolong',
  edit_rejected = 'edit-rejected',
  bump = 'bump',
  vip = 'vip',
  featured = 'featured'
}


export enum CurrencyLabel {
  azn = 'AZN',
  usd ='$',
  eur = '€'
}
export enum Flag {
  bumped = 'bumped',
  vipped = 'vipped',
  featured = 'featured'
}

export interface ItemI {
  id: number;
  description: string;
  availability_status: string;
  power: number;
  engine_volume: number;
  mileage: number;
  loan: boolean;
  bumped_at: string | null;
  updated_at: string | null;
  barter: boolean;
  currency: Currency;
  price_azn: number | null;
  price: number;
  prior_owners_count: number;
  seats_count: number | null;
  uncrashed: boolean;
  unpainted: boolean;
  crashed: boolean;
  painted: boolean;
  vin: string;
  for_spare_parts: boolean;
  category: { id: number, name: string };
  color: { id: number, name: string }
  expired: boolean;
  extras: { id: number, name: string }[];
  flags: Flag[];
  fuel_type: { id: number, name: string };
  gear: { id: number, name: string };
  is_owner: boolean;
  is_shop: boolean;
  make: { id: number, name: string };
  market: { id: number, name: string };
  model: { id: number, name: string };
  moderation_status: Status;
  name: string;
  new: boolean;
  pending_paid: boolean;
  photo: string;
  photos: string[];
  price_decreasing: number | null;
  published: boolean;
  region: { id: number, name: string };
  reject_reason?: string;
  shop_slug: string;
  transmission: { id: number, name: string };
  uri: string;
  year: number;
  cursor?: string;
  spincar_panorama_url: string | null;
  is_edit_rejectable: boolean;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryState } from 'packs/autocatalog/hooks';
import { useAutocatalogContext } from 'packs/autocatalog/context';
import { apiPaths, RoutePath } from 'packs/autocatalog/constants';
import { useCatalogModels, useGenerationOptions } from 'packs/autocatalog/api';
import {
  formatGenerationOptions,
  formatMakeOptions,
  groupFormatModelOptions,
  selectInitialModel
} from 'packs/autocatalog/utils';
import { EMPTY_PARAMS } from '../SearchFilters';
import * as S from './Breadcrumbs.styles';

export const Breadcrumbs = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const context = useAutocatalogContext();
  const [query, setQuery] = useQueryState();
  const params: { generation: string } = useParams();

  const makeId = query.make ?? '';
  const modelId = (query.model || query.model_group) ?? '';
  const initialGenerations = formatGenerationOptions(context.generations);
  const generationUrl = `?make=${makeId}&${query.model_group ? 'model_group' : 'model'}=${modelId}`;
  const makeOptions = formatMakeOptions(context.makes);
  const models = useCatalogModels(makeId ? apiPaths.models(makeId) : null);
  const initialModels = models.data.length ? models.data : groupFormatModelOptions(context.models);
  const generations = useGenerationOptions(modelId ? apiPaths.generations(generationUrl) : null);
  const initModel = selectInitialModel(query, initialModels);
  const initMake = makeOptions.find(item => String(item.value) === query.make) ?? null;
  const generationOptions = generations.data.length ? generations.data : initialGenerations;
  const initGeneration = generationOptions.find(item => item.value === +params.generation) ?? null;

  const onReset = () => {
    setQuery(EMPTY_PARAMS);
    history.push(RoutePath.autocatalog + history.location.search);
  };

  const onMakeClick = () => {
    setQuery({ ...query, model: '', model_group: '', generation: '' });
    history.push(RoutePath.autocatalog + history.location.search);
  };

  const onModelClick = () => {
    setQuery({ ...query, generation: '' });
    history.push(RoutePath.autocatalog + history.location.search);
  };

  return (
    <S.Container>
      <S.Wrapper>
        <span onClick={onReset}>{t('catalog')}</span>
        {initMake && <span onClick={onMakeClick}>{initMake.label}</span>}
        {initModel && <span onClick={onModelClick}>{initModel.label}</span>}
        {initGeneration && <span>{initGeneration.label}</span>}
      </S.Wrapper>
    </S.Container>
  );
};

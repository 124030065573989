import React, { useCallback, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import classnames from 'classnames';
import { getHelpUrl, getCategories } from '../../routes';
import { useAxios } from '../../../../hooks/useAxios';
import { FAQContext } from '../../context';
import { CategoryQuestions } from './categoryQuestions';
import { FAQContent, Categories, Category } from './styled';

export const Content = () => {
  const {
    setSelectedCategory,
    setOpenedQuestionId,
    selectedCategory,
    isMobile
  } = useContext(FAQContext);

  const onSuccessFetchCategories = useCallback(res => {
    if (res?.length) {
      const openedCategory = res.find(({ slug }) => document.location.pathname.includes(slug));
      setSelectedCategory(openedCategory || res[0]);
      setOpenedQuestionId(+document.location.hash.replace(/[^+\d]/g, ''));
    }
  }, [setSelectedCategory, setOpenedQuestionId]);

  const { loading, response } = useAxios({
    method: 'get',
    url: getCategories,
    isInitialCall: true,
    onSuccess: onSuccessFetchCategories
  });

  const containerClassnames = classnames({ 'main-container': !isMobile });

  const toggleCategory = caregory => {
    setOpenedQuestionId(null);
    setSelectedCategory(caregory);
  };

  return (
    <FAQContent className={containerClassnames}>
      <BrowserRouter>
        {!!response?.length && (
          <Categories>
            {response.map(({ slug, name }) => (
              <li role="presentation" key={slug} onClick={() => toggleCategory({ slug, name })}>
                <Category to={getHelpUrl(slug)} $isActive={slug === selectedCategory?.slug}>
                  {name}
                </Category>
              </li>
            ))}
          </Categories>
        )}
        <CategoryQuestions loadingCategories={loading} />
      </BrowserRouter>
    </FAQContent>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  margin: 32px auto 40px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  min-height: 800px;
`;

export const CarouselContainer = styled.div`
  min-width: 640px;
  margin-bottom: 20px;
  border-radius: 7px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
`;

export const ConfigPanel = styled.div`
  position: sticky;
  border-radius: 4px;
  top: 0;
  padding: 20px 15px;
  width: 100%;
  align-self: start;
  background-color: ${({ theme }) => theme.palette.grey[50]};

  button {
    width: 100%;
    font-size: 14px;
    padding: 10px 7px;
  }
`;

export const EngineCategory = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const EngineCategoryTitle = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

export const ButtonBody = styled.div`
  display: flex;
  gap: 3px;

  span {
    text-align: left;
  }
`;

export const ConfigPanelTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.color.blueMidnight};
`;

export const ConfigPanelText = styled.p`
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color.blueMidnight};
`;

export const SpecsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 20px;
  align-items: start;
  padding-bottom: 15px;
`;

export const SpecsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  align-items: start;
  border-top: 1px solid ${({ theme }) => theme.color.lightGrey};

  &:not(:last-child) {
    padding: 20px 0;
  }

  &:last-child {
    padding-top: 20px;
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ErrorContainer, Title, Button, Message
} from './styled';

export const Error: React.FC<{
  refetchData: () => void; isMobile: boolean
}> = ({ refetchData, isMobile }) => {
  const { t } = useTranslation();

  return (
    <ErrorContainer>
      <Title>{t('error.title')}</Title>
      <Message>{t('error.message')}</Message>
      <Button onClick={refetchData} isMobile={isMobile}>{t('error.btn')}</Button>
    </ErrorContainer>
  );
};

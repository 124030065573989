import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { getObjCopy, getToken } from '../../helpers';
import { Header, UseAxios } from './types';

export const useAxios = <T, B = null, H = Header, E = unknown>({
  method,
  url,
  body = null,
  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Csrf-Token': getToken()
  },
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {},
  isInitialCall = false
}: UseAxios<T, B, H, E>) => {
  const [response, setResponse] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const callRequest = useCallback(
    (props = {}) => {
      const { openedWin, withUrl } = props;
      setLoading(true);
      setHasError(false);

      axios({
        method,
        url: withUrl || url,
        headers: getObjCopy(headers),
        data: getObjCopy(body)
      })
        .then(({ data }: { data: T }) => {
          setResponse(data);
          onSuccess(data, isInitialCall, openedWin);
        })
        .catch(error => {
          setHasError(true);
          onError(error, openedWin);
        })
        .finally(() => {
          setLoading(false);
          onFinally();
        });
    },
    [method, url, headers, body]
  );

  useEffect(() => {
    if (isInitialCall) callRequest();
  }, []);

  return {
    response,
    loading,
    callRequest,
    hasError
  };
};

import styled, { keyframes } from 'styled-components';
import { FlexCenterAligned } from '../../styled';

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderContainer = styled(FlexCenterAligned)`
  flex: auto;
  width: 100%;
  height: 100%;
`;

export const LoaderStyled = styled.div<{ size: string; fill: string }>`
  width: ${p => p.size};
  height: ${p => p.size};
  border-radius: 50%;
  border: 3px solid ${p => p.fill};
  border-top: 3px solid transparent;
  transform: translateZ(0);
  animation: ${rotation} 1s infinite linear;
`;

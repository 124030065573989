export const EMPTY_PARAMS = {
  make: '',
  model: '',
  model_group: '',
  generation: '',
  gear: '',
  power_from: '',
  power_to: '',
  acceleration_from: '',
  acceleration_to: '',
  year_from: '',
  year_to: '',
  engine_volume_from: '',
  engine_volume_to: '',
  '[transmission][]': '',
  '[category][]': '',
  '[fuel_type][]': '',
  '[seats_count][]': '',
  '[gear][]': ''
};

export const SEATS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8+', value: 8 }
];

import React, { useRef, useState } from 'react';
import { Swiper } from 'swiper/core';
import { useTranslation } from 'react-i18next';
import { Swiper as SwiperContainer, SwiperSlide } from 'swiper/react';
import { Gallery } from './components';
import * as S from './Carousel.styles';

interface CarouselProps {
  title?: string;
  slides: { url: string; label: string }[];
  thumbs: { url: string; label: string }[];
}

export const Carousel = ({ title = '', slides = [], thumbs = [] }: CarouselProps) => {
  const { t } = useTranslation();
  const swiperRef = useRef<Swiper | null>(null);

  const [slideIndex, setSlideIndex] = useState<number>(1);
  const [isFullScreen, setFullScreen] = useState<boolean>(false);

  const thumbnails = thumbs.length ? thumbs : slides;
  const slicedSlides = thumbnails.slice(0, 9);
  const counter = `${slideIndex}/${thumbnails.length}`;

  const onSlideChange = (swiper: Swiper) => {
    setSlideIndex(swiper.realIndex + 1);
  };

  const goNext = () => {
    swiperRef?.current?.slideNext();
  };

  const goPrev = () => {
    swiperRef?.current?.slidePrev();
  };

  const toggleFullScreen = () => {
    setFullScreen(prev => !prev);
  };

  const renderThumbs = () => {
    return slicedSlides.map(({ url }, index) => {
      const currIndex = index + 1;
      const restItems = slides.length - slicedSlides.length;
      const isLastItem = currIndex === 9 && restItems > 0;

      return (
        <S.Thumb
          key={url}
          onClick={toggleFullScreen}
          onMouseOver={() => swiperRef.current?.slideTo(currIndex)}
        >
          <S.Image $isActive={slideIndex === currIndex} src={url} />
          {isLastItem ? (
            <S.ThumbLabel>
              +{restItems} {t('mainSearch.photos')}
            </S.ThumbLabel>
          ) : null}
        </S.Thumb>
      );
    });
  };

  return (
    <S.CarouselContainer>
      <Gallery
        title={title}
        slides={slides}
        thumbs={thumbs.length ? thumbs : slides}
        initialSlide={slideIndex}
        isVisible={isFullScreen}
        onClose={toggleFullScreen}
      />

      <S.SlidesContainer>
        <SwiperContainer
          loop
          speed={1}
          onSlideChange={onSlideChange}
          onInit={swiper => (swiperRef.current = swiper)}
        >
          {slides.map(({ url }) => (
            <SwiperSlide key={url}>
              <S.Slide>
                <S.Image onClick={toggleFullScreen} $isActive src={url} loading="lazy" />
              </S.Slide>
            </SwiperSlide>
          ))}
        </SwiperContainer>
        <S.ZoomIcon onClick={toggleFullScreen} />
        <S.Arrow $isLeft onClick={goPrev} />
        <S.SlideCounter>{counter}</S.SlideCounter>
        <S.Arrow onClick={goNext} />
      </S.SlidesContainer>

      <S.Thumbs>{renderThumbs()}</S.Thumbs>
    </S.CarouselContainer>
  );
};

import styled, { css } from 'styled-components';

const HiddenInputStyles = css`
  display: none;
  opacity: 0;
  visibility: hidden;
`;

const MinimizedInputLabel = css`
  position: absolute;
  top: 7px;
  left: 10px;
  padding: 0;
  font-size: 13px;
`;

const Disabled = css`
  opacity: 0.5;
  pointer-events: none;
`;

const GroupedWrapper = css<{ $isOpen: boolean }>`
  &:first-child {
    margin-right: -1px;
    border-right-width: 1px;
    border-radius: 7px 0 0 7px;
  }

  &:last-child {
    border-radius: 0 7px 7px 0;
    margin-left: ${({ $isOpen }) => ($isOpen ? '0' : '1px')};
    border-left-width: ${({ $isOpen }) => ($isOpen ? '1px' : '0')};
  }

  &:nth-child(n + 2):not(:last-child) {
    border-radius: 0;
    margin-right: -1px;
    border-right-width: 1px;
    margin-left: ${({ $isOpen }) => ($isOpen ? '0' : '1px')};
    border-left-width: ${({ $isOpen }) => ($isOpen ? '1px' : '0')};
  }
`;

export const Wrapper = styled.div<{
  $isOpen: boolean;
  $isGrouped: boolean;
  $isDisabled: boolean;
}>`
  width: 100%;
  display: flex;
  min-width: 82px;
  position: relative;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid
    ${({ theme, $isOpen }) => ($isOpen ? theme.color.darkgrey : theme.palette.grey['100'])};

  ${({ $isDisabled }) => ($isDisabled ? Disabled : '')}
  ${({ $isGrouped }) => ($isGrouped ? GroupedWrapper : '')}
`;

export const Container = styled.div`
  width: 100%;
  height: 46px;
  z-index: 1;
  display: flex;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  align-items: center;
  padding: 0 32px 0 10px;
`;

export const InputLabel = styled.p<{ $isMinimized: boolean }>`
  font-size: 15px;
  color: ${({ theme }) => theme.palette.grey['200']};
  ${({ $isMinimized }) => ($isMinimized ? MinimizedInputLabel : '')}
`;

export const InputPlaceholder = styled.p<{ $isDark: boolean }>`
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme, $isDark }) =>
    $isDark ? theme.color.blueMidnight : theme.palette.grey['200']};
`;

export const InputValue = styled.p`
  font-size: 15px;
  padding: 24px 0 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.blueMidnight};
`;

export const Input = styled.input<{ $isHidden: boolean }>`
  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 2;
  font-size: 15px;
  line-height: 16px;
  width: calc(100% - 40px);
  transform: translateY(-50%);
  background-color: transparent !important;
  color: ${({ theme }) => theme.color.blueMidnight};
  ${({ $isHidden }) => ($isHidden ? HiddenInputStyles : '')}
`;

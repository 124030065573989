import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PaidStatusesMap } from '../../helpers';
import { InvoicesAppContext } from '../../context';
import { doFormatDate } from '../../../../helpers';

export const InvoiceInfoDesktop = ({ invoice, paymentStatus }) => {
  const { t } = useTranslation();

  const { selectedInvoice } = useContext(InvoicesAppContext);

  const {
    services,
    billing_info,
    status,
    vat_amount,
    amount,
    starts_at,
    expires_at
  } = invoice || selectedInvoice;

  const invoicesInfoDetails = `${doFormatDate({ date: starts_at })} — ${doFormatDate({ date: expires_at })}`;

  return (
    <div className="invoices-info">
      {services.tariff_plan_title && (
        <div className="invoices-info_heading">
          {t('tariffType', { tariff: services.tariff_plan_title })}
        </div>
      )}
      <div className="invoices-info_flex w-65">
        {services.paid_ads_count && (
          <div className="invoices-info_flex justify-start mb-20">
            <div className="invoices-info_label left">{t('paidAds')}</div>
            <div className="invoices-info_details">
              {services.paid_ads_count}
            </div>
          </div>
        )}
        {services.discount && (
          <div className="invoices-info_flex justify-start mb-20">
            <div className="invoices-info_label right">{t('discount')}</div>
            <div className="invoices-info_details">
              {services.discount}
              %
            </div>
          </div>
        )}
        {services.wallet_amount && (
          <div className="invoices-info_flex justify-start mb-20">
            <div className="invoices-info_label left">
              {t('paidServices')}
            </div>
            <div className="invoices-info_details">
              {services.wallet_amount}
            </div>
          </div>
        )}
        {starts_at && (
          <div className="invoices-info_flex justify-start mb-20">
            <div className="invoices-info_label right">
              {t('dateRange')}
            </div>
            <div className="invoices-info_details">
              {invoicesInfoDetails}
            </div>
          </div>
        )}
      </div>
      <div className="invoices-info_divider" />
      {services.additional_conditions && (
        <>
          <div className="invoices-info_flex">
            <div className="invoices-info_flex justify-start mb-20">
              <div className="invoices-info_label left">
                {t('additionalConditions')}
              </div>
              <div>{services.additional_conditions}</div>
            </div>
          </div>
          <div className="invoices-info_divider" />
        </>
      )}
      <div className="invoices-info_flex">
        <div>
          <div className="invoices-info_flex justify-start mb-20">
            <div className="invoices-info_label left">{t('customer')}</div>
            <div className="invoices-info_details">
              {billing_info.company_name}
            </div>
          </div>
          <div className="invoices-info_flex justify-start mb-20">
            <div className="invoices-info_label left">{t('voen')}</div>
            <div className="invoices-info_details">
              {billing_info.company_tin}
            </div>
          </div>
          <div className="invoices-info_flex justify-start mb-20">
            <div className="invoices-info_label left">{t('contract')}</div>
            <div className="invoices-info_details w-300 wrap">
              {billing_info.contract_number}
            </div>
          </div>
          {status === PaidStatusesMap.paid && (
            <div className="invoices-info_status">{paymentStatus}</div>
          )}
        </div>
        <div>
          <div className="invoices-info_flex justify-end fz-15 pb-20 border-b mb-20">
            <div className="invoices-info_label w-100">{`${t('vat18')}:`}</div>
            <div>{vat_amount}</div>
          </div>
          <div className="invoices-info_sum">{`${amount} AZN`}</div>
        </div>
      </div>
    </div>
  );
};

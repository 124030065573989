import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryState } from 'packs/autocatalog/hooks';
import { ItemCard } from 'packs/autocatalog/components/desktop';
import { CatalogItem } from './CatalogDesktop.types';
import * as S from './CatalogDesktop.styles';

interface ItemCardsProps {
  items: CatalogItem[];
  page: number | undefined;
  onLoadMore: () => void;
}

export const ItemCards: React.FC<ItemCardsProps> = ({ page, items, onLoadMore }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryState();

  const onCardClick = ({ make, model }: CatalogItem) => {
    setQuery({
      make: make.value,
      model: model.isCategory ? '' : model.value,
      model_group: model.isCategory ? model.value : ''
    });
  };

  const formatKey = ({ model, make }: CatalogItem) => {
    const modelId = model.isCategory ? `g-${model.value}` : model.value;

    return `${modelId}-${make.value}`;
  };

  return (
    <>
      <S.SectionTitle>{t('allModels')}</S.SectionTitle>
      <S.ItemsGrid $hasLoadButton={!!page}>
        {items.map(item => {
          return (
            <ItemCard
              key={formatKey(item)}
              image={item.thumbnails[0]}
              onClick={() => onCardClick(item)}
              label={`${item.make.label} ${item.model.label}`}
            />
          );
        })}
      </S.ItemsGrid>
      {!!page && <S.LoadButton onClick={onLoadMore}>{t('loadMore')}</S.LoadButton>}
    </>
  );
};

import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ToggleButton } from 'components';
import { useLangUpdate, useQueryState } from 'packs/autocatalog/hooks';
import { useCatalogGenerations, useCatalogItem, useModifications } from 'packs/autocatalog/api';
import { formatSections } from 'packs/autocatalog/utils';
import { RoutePath, apiPaths } from 'packs/autocatalog/constants';
import {
  Loader,
  Carousel,
  SpecTable,
  Breadcrumbs,
  CarDimensions
} from 'packs/autocatalog/components/desktop';
import * as S from './ModelDesktop.styles';

export const ModelDesktop = () => {
  useLangUpdate();
  const history = useHistory();
  const [query] = useQueryState();
  const params: { id: string; generation: string } = useParams();
  const id = params?.id;
  const generationId = params?.generation;
  const makeId = query.make ?? '';
  const modelId = (query.model || query.model_group) ?? '';
  const generationUrl = `?make=${makeId}&${query.model_group ? 'model_group' : 'model'}=${modelId}`;

  const { data, isLoading } = useCatalogItem(apiPaths.items(`/${id}?p=desktop`));
  const modifications = useModifications(apiPaths.items(`/${id}/modifications`));
  const generations = useCatalogGenerations(apiPaths.generations(generationUrl));
  const modificationData = generations.data
    .find(item => item.id === +generationId)
    ?.categories.find(item => item.modification.id === +id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  const slides = data?.photos?.map(url => ({ url, label: '' })) ?? [];
  const thumbs = data?.thumbnails?.map(url => ({ url, label: '' })) ?? [];
  const geometry = data?.geometry;
  const sections = data?.sections.filter(section => !section.type && section.name) ?? [];
  const defaultSection = data?.sections.filter(section => !section.type && !section.name)[0];
  const sectionPairs = formatSections(sections);

  const onModificationSelect = (id: number) => {
    history.replace(`${RoutePath.model}/${generationId}/${id}` + history.location.search);
  };

  return (
    <>
      <Breadcrumbs />
      <S.Container className="tz-container">
        <div>
          <S.CarouselContainer>
            {data?.photos ? (
              <Carousel
                title={`${data?.make.name} ${data?.model_group?.name || data?.model?.name}`}
                slides={slides}
                thumbs={thumbs}
              />
            ) : null}
          </S.CarouselContainer>

          {isLoading && !data ? (
            <S.LoaderContainer>
              <Loader />
            </S.LoaderContainer>
          ) : (
            <>
              <S.SpecsWrapper>
                <SpecTable title="" fields={defaultSection?.fields ?? []} />
                <CarDimensions
                  width={geometry?.width ?? 0}
                  height={geometry?.height ?? 0}
                  length={geometry?.length ?? 0}
                  icon={modificationData?.icon}
                />
              </S.SpecsWrapper>

              {sectionPairs.map((pair, idx) => {
                return (
                  <S.SpecsGrid key={idx}>
                    {pair?.map(section => {
                      return (
                        <SpecTable
                          key={section.name}
                          title={section.name}
                          fields={section.fields}
                        />
                      );
                    })}
                  </S.SpecsGrid>
                );
              })}
            </>
          )}
        </div>

        {isLoading && !data ? null : (
          <S.ConfigPanel>
            <S.ConfigPanelTitle>
              {data?.make.name} {data?.model_group?.name || data?.model?.name}
            </S.ConfigPanelTitle>
            <S.ConfigPanelText>{data?.option_name}</S.ConfigPanelText>

            <S.EngineCategory>
              {modifications.data
                ?.flatMap(i => i)
                .map(modification => {
                  return (
                    <React.Fragment key={modification.name}>
                      <S.EngineCategoryTitle>{modification.name}</S.EngineCategoryTitle>
                      {modification.items.map(item => {
                        return (
                          <ToggleButton
                            key={item.id}
                            isActive={item.id === +id}
                            onChange={() => onModificationSelect(item.id)}
                          >
                            <S.ButtonBody>
                              {item.engine_capacity && <span>{item.engine_capacity} /</span>}
                              {item.power && <span>{item.power} /</span>}
                              {item.transmission && <span>{item.transmission} /</span>}
                              {item.gear && <span>{item.gear}</span>}
                            </S.ButtonBody>
                          </ToggleButton>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
            </S.EngineCategory>
          </S.ConfigPanel>
        )}
      </S.Container>
    </>
  );
};

import styled from 'styled-components';

export const CarouselMobileContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  // Fix for too wide slides on initial load
  .swiper-container {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-height: 0;
    min-width: 0;
  }
`;

export const SliderMobile = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

export const SlideMobile = styled.div`
  width: 100%;
  height: 100%;
`;

export const ImageMobile = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  user-drag: none;
  transition: opacity 0.2s linear;
`;

export const SlideCounterMobile = styled.span`
  position: absolute;
  bottom: 15px;
  padding: 5px 10px;
  right: 15px;
  font-size: 12px;
  line-height: 14px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 7px;
  color: ${({ theme }) => theme.color.white};
`;

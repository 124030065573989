import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[75]};
`;

export const Wrapper = styled.div`
  max-width: 970px;
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 15px 0 15px;

  & > span {
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;

    &:not(:first-child)::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 3px;
      height: 3px;
      margin-right: 10px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.palette.grey[100]};
    }

    &:last-child {
      cursor: default;
      user-select: none;
      text-decoration: none;
    }
  }
`;

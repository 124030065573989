import React, { useRef, useState } from 'react';
import { Swiper } from 'swiper/core';
import { Swiper as SwiperContainer, SwiperSlide } from 'swiper/react';
import { useGallerySwipe } from '../hooks';
import { RATIO } from '../Carousel.constants';
import * as S from './Gallery.styles';

interface GalleryProps {
  title: string;
  initialSlide: number;
  slides: { url: string; label: string }[];
  onClose: () => void;
  onChange: (index: number) => void;
}

export const Gallery: React.FC<GalleryProps> = ({
  title,
  initialSlide,
  slides,
  onClose,
  onChange
}) => {
  const swiperRef = useRef<Swiper | null>(null);

  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [isInteracts, setIsInteracts] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(initialSlide ?? 1);

  const height = RATIO * window.innerWidth;
  const counter = `${slideIndex}/${slides.length}`;

  const { onTouchStart, onTouchMove, onTouchEnd, swipeDistance } = useGallerySwipe(
    isInteracts,
    () => initiateClose(),
    () => initiateClose()
  );

  const onSlideChange = (swiper: Swiper) => {
    const index = swiper.realIndex + 1;

    onChange(index);
    setSlideIndex(index);
  };

  const initiateClose = () => {
    setIsClosing(true);
    setTimeout(onClose, 200);
  };

  return (
    <S.Container $isClosing={isClosing}>
      <S.Header>
        <S.CloseButton onClick={initiateClose} />
        <S.HeaderTitle>{counter}</S.HeaderTitle>
      </S.Header>

      <S.Body>
        <S.CarouselContainer
          onTouchEnd={onTouchEnd}
          onTouchMove={onTouchMove}
          onTouchStart={onTouchStart}
          $offsetY={isInteracts ? 0 : swipeDistance}
        >
          <SwiperContainer
            loop
            slidesPerView={1}
            enabled={slides.length > 1}
            initialSlide={slideIndex - 1}
            onSlideChange={onSlideChange}
            onTouchEnd={() => setIsInteracts(false)}
            onSliderMove={() => setIsInteracts(true)}
            onInit={swiper => (swiperRef.current = swiper)}
          >
            {slides.map(({ url }) => {
              return (
                <SwiperSlide key={url} style={{ height }}>
                  <S.SlideMobile>
                    <S.ImageMobile src={url} loading="lazy" />
                  </S.SlideMobile>
                </SwiperSlide>
              );
            })}
          </SwiperContainer>
        </S.CarouselContainer>
      </S.Body>
      <S.Footer>
        <S.Title>{title}</S.Title>
      </S.Footer>
    </S.Container>
  );
};

import React, {
  useEffect, useContext, useState, useRef
} from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import * as classNames from 'classnames';
import { InvoicesAppContext } from '../../context';
import { mobStyles, deskStyles } from './balanceModalStyles';
import { PaymentOption } from '../../constants';
import { useAxios } from '../../../../hooks/useAxios';
import {
  openNewPaymentWindow,
  paymentData
} from '../../helpers';
import { numberWithFractionDigits } from '../../../../helpers';
import { balanceReplenishmentPath } from '../../routes';

export const BalanceModal = () => {
  const { t } = useTranslation();
  const valueInput = useRef(null);
  const {
    bankCardProvider,
    balanceModalVisible,
    setIsBalanceModalVisible,
    setHasErrors,
    isMobileSite,
    setWindowOpened,
    winOpened
  } = useContext(InvoicesAppContext);

  const [selectedOption, setSelectedOption] = useState(bankCardProvider);
  const [paymentSum, setPaymentSum] = useState(100);
  const [blockPaymentButton, setBlockPaymantButton] = useState(false);

  const { portmanat } = PaymentOption;
  const { headers } = paymentData(selectedOption);

  Modal.setAppElement(document.querySelector('[data-behavior="react"]'));

  const onClose = () => {
    setIsBalanceModalVisible(false);
  };

  const onError = () => {
    setHasErrors(true);
    onClose();
  };

  const {
    callRequest
  } = useAxios({
    method: 'post',
    headers,
    body: {
      service_id: 'add_balance_custom',
      amount: paymentSum,
      payment_method: selectedOption
    },
    url: balanceReplenishmentPath,
    onSuccess: (data, isInitialCall, openedWin) => {
      openNewPaymentWindow(data, openedWin);
    },
    onError
  });

  const showCustomValueError = paymentSum < 1
    ? t('replenishment.custom_price_min')
    : t('replenishment.custom_price_sum', { price: selectedOption === portmanat ? 1000 : 5000 });

  useEffect(() => {
    setBlockPaymantButton(false);
    if (paymentSum < 1
      || (paymentSum > 1000 && selectedOption === portmanat)
      || (paymentSum > 5000 && selectedOption === bankCardProvider)) {
      setBlockPaymantButton(true);
    }
  }, [balanceModalVisible, selectedOption, paymentSum]);

  const handlePaymentClick = () => {
    if (winOpened) winOpened.close();
    const openedWin = window.open('', 'new_payment', 'width=580,height=750');
    setWindowOpened(openedWin);
    setBlockPaymantButton(false);
    if (paymentSum < 1
      || (paymentSum > 1000 && selectedOption === portmanat)
      || (paymentSum > 5000 && selectedOption === bankCardProvider)) {
      setBlockPaymantButton(true);
    }
    if (!blockPaymentButton) callRequest({ openedWin });
  };

  const modalStyles = isMobileSite ? mobStyles : deskStyles;

  const customInputClassNames = classNames(
    'balance_modal_pay-list-option custom-price',
    { error: blockPaymentButton && paymentSum !== '' }
  );

  return (
    <Modal
      isOpen={balanceModalVisible}
      onRequestClose={onClose}
      style={modalStyles}
    >
      <header className="balance_modal_replenishment_header">
        <p className="balance_modal_replenishment_title">{t('replenishment.title')}</p>
        <button
          type="button"
          onClick={onClose}
          className="balance_modal_close-modal"
        />
      </header>
      <section className="balance_modal_pay">
        <div className="balance_modal_pay_title-amount">
          {t('replenishment.amount')}
          :
        </div>
        <ul className="balance_modal_pay-list">
          <li className="balance_modal_pay-list-option">
            <input
              id="first"
              name="payment-sum"
              type="radio"
              onChange={() => setPaymentSum(100)}
              checked={paymentSum === 100}
            />
            <label htmlFor="first">
              {`${numberWithFractionDigits(100, { minimumFractionDigits: 0 })} AZN`}
            </label>
          </li>
          <li className="balance_modal_pay-list-option">
            <input
              id="second"
              name="payment-sum"
              type="radio"
              onChange={() => setPaymentSum(250)}
              checked={paymentSum === 250}
            />
            <label htmlFor="second">
              {`${numberWithFractionDigits(250, { minimumFractionDigits: 0 })} AZN`}
            </label>
          </li>

          { isMobileSite
            ? (
              <li className="balance_modal_pay-list-option">
                <input
                  id="third"
                  name="payment-sum"
                  type="radio"
                  onChange={() => setPaymentSum(500)}
                  checked={paymentSum === 500}
                />
                <label htmlFor="third">
                  {`${numberWithFractionDigits(500, { minimumFractionDigits: 0 })} AZN`}
                </label>
              </li>
            )
            : (
              <li className={customInputClassNames}>
                <input
                  id="value"
                  name="payment-sum"
                  type="input"
                  maxLength={4}
                  pattern="[0-9]"
                  ref={valueInput}
                  placeholder=" "
                  value={document.activeElement === valueInput.current ? paymentSum : ''}
                  onClick={event => setPaymentSum(event.target.value)}
                  onChange={event => setPaymentSum(event.target.value.replace(/\D/g, ''))}
                />
                <span onClick={() => {
                  valueInput.current.focus();
                  setPaymentSum('');
                }}
                >
                  {t('replenishment.custom_price_placeholder')}
                </span>
                <span className="error">
                  {showCustomValueError}
                </span>
              </li>
            )}
        </ul>

        <div className="balance_modal_pay_title-method">
          {t('replenishment.method')}
          :
        </div>
        <ul className="balance_modal_pay-method">
          <li className="balance_modal_pay-method-option">
            <input
              id={bankCardProvider}
              name="payment-option"
              type="radio"
              value={bankCardProvider}
              checked={selectedOption === bankCardProvider}
              onChange={() => setSelectedOption(bankCardProvider)}
            />
            <label htmlFor={bankCardProvider}>{t('modal.card')}</label>
          </li>
          <li className="balance_modal_pay-method-option">
            <input
              id={portmanat}
              name="payment-option"
              type="radio"
              value={portmanat}
              checked={selectedOption === portmanat}
              onChange={() => setSelectedOption(portmanat)}
            />
            <label htmlFor={portmanat}>{t('modal.portmanat')}</label>
          </li>
        </ul>
      </section>
      <footer className="balance_modal-footer">
        <p className="balance_modal-confirm-rules">
          <span>{t('modal.confirmRules1')}</span>
          <a href="/pages/terms-and-conditions">{t('modal.confirmRules2')}</a>
          <span>{t('modal.confirmRules4')}</span>
          <a href="/pages/proposal">{t('modal.confirmRules5')}</a>
          <span>{t('modal.confirmRules6')}</span>
        </p>
        <button
          value={t('pay')}
          type="button"
          onClick={handlePaymentClick}
          disabled={blockPaymentButton}
        >
          {t('pay')}
        </button>
      </footer>
    </Modal>
  );
};

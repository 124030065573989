import useSWR from 'swr';
import { fetcher } from './api';
import { ModificationsResponse } from '../types';

type TypedResponse = { data: ModificationsResponse[] };

export const useModifications = (url: string | null) => {
  const { data, isLoading } = useSWR<TypedResponse>(url, fetcher, {
    keepPreviousData: true,
    revalidateOnFocus: false,
    revalidateIfStale: false
  });

  return {
    isLoading,
    data: data?.data
  };
};

import { useState, useEffect } from 'react';

type SwipeCallback = () => void;

interface UseSwipeResult {
  swipeDistance: number;
  onTouchEnd: (event: React.TouchEvent) => void;
  onTouchMove: (event: React.TouchEvent) => void;
  onTouchStart: (event: React.TouchEvent) => void;
}

export const useGallerySwipe = (
  isDisabled: boolean,
  onSwipeUp: SwipeCallback,
  onSwipeDown: SwipeCallback
): UseSwipeResult => {
  const [distance, setDistance] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchCurrent, setTouchCurrent] = useState(0);

  useEffect(() => {
    if (isDisabled) {
      setDistance(0);
      setTouchStart(0);
      setTouchCurrent(0);
    }
  }, [isDisabled]);

  useEffect(() => {
    if (!touchStart || !touchCurrent) return;

    const distance = touchStart - touchCurrent;

    setDistance(distance);
  }, [touchCurrent, touchStart, onSwipeUp, onSwipeDown]);

  const onTouchStart = (event: React.TouchEvent) => {
    event.stopPropagation();
    setTouchStart(event.targetTouches[0].clientY);
    setTouchCurrent(event.targetTouches[0].clientY);
  };

  const onTouchMove = (event: React.TouchEvent) => {
    event.stopPropagation();
    setTouchCurrent(event.targetTouches[0].clientY);
  };

  const onTouchEnd = (event: React.TouchEvent) => {
    event.stopPropagation();
    setTouchStart(0);
    setTouchCurrent(0);

    const threshold = Math.abs(distance) > 100;

    if (threshold) {
      distance > 0 ? onSwipeUp() : onSwipeDown();
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    swipeDistance: touchCurrent !== 0 && touchStart !== 0 ? touchCurrent - touchStart : 0
  };
};

import styled from 'styled-components';
import { blue, FlexCenterAligned, white } from '../../../../../styled';

export const Button = styled(FlexCenterAligned)`
  border-radius: 7px;
  border: solid 1px ${blue};
  height: 31px;
  margin-right: 7px;
  font-size: 14px;
  font-weight: 500;
  color: ${blue};
  box-sizing: border-box;
  cursor: pointer;
  
  &:hover {
    background-color: ${blue};
    color: ${white};
  }
`;

import { useTranslation } from 'react-i18next';
import React, { useCallback, useContext } from 'react';
import { FAQContext } from '../../../context';
import { getHelpUrl } from '../../../routes';
import {
  Answer, QuestionContainer, QuestionStyled, ShareButton
} from './styled';

export const Question = ({ question }) => {
  const { t } = useTranslation();

  const {
    setOpenedQuestionId,
    selectedCategory,
    openedQuestionId,
    isMobile
  } = useContext(FAQContext);

  const isOpenedQuestion = question.id === openedQuestionId;

  const getQuestionLink = useCallback(() => {
    const pathname = getHelpUrl(selectedCategory?.slug);
    return isOpenedQuestion ? pathname : { pathname, hash: `${question.id}` };
  }, [selectedCategory, openedQuestionId, question]);

  const handleToggleQuestion = useCallback(() => {
    setOpenedQuestionId(isOpenedQuestion ? null : question.id);
  }, [openedQuestionId, setOpenedQuestionId, question]);

  const shareAnswer = () => {
    window.navigator.share({ url: window.location.href });
  };

  return (
    <QuestionContainer key={question.id}>
      <QuestionStyled
        to={getQuestionLink}
        onClick={handleToggleQuestion}
        $isOpened={isOpenedQuestion}
      >
        {question.question}
      </QuestionStyled>
      {isOpenedQuestion && (
        <Answer>
          <div dangerouslySetInnerHTML={{ __html: question.answer }} />
          {window.navigator.share && isMobile && (
            <ShareButton tabIndex="0" role="button" onClick={shareAnswer}>
              {t('share')}
            </ShareButton>
          )}
        </Answer>
      )}
    </QuestionContainer>
  );
};

import styled, { css } from 'styled-components';
import ResetSvg from '@assets/images/mobile/icon-reset--grey.svg';

const MinimizedInputLabel = css`
  top: 15px;
  cursor: pointer;
  font-size: 13px;
`;

const Grouped = css<{ $isOpen: boolean }>`
  &:first-child {
    margin-right: -1px;
    border-right-width: 1px;
    border-radius: 7px 0 0 7px;
  }

  &:last-child {
    border-radius: 0 7px 7px 0;
    margin-left: ${({ $isOpen }) => ($isOpen ? '0' : '1px')};
    border-left-width: ${({ $isOpen }) => ($isOpen ? '1px' : '0')};
  }

  &:nth-child(n + 2):not(:last-child) {
    border-radius: 0;
    margin-right: -1px;
    border-right-width: 1px;
    margin-left: ${({ $isOpen }) => ($isOpen ? '0' : '1px')};
    border-left-width: ${({ $isOpen }) => ($isOpen ? '1px' : '0')};
  }
`;

export const Container = styled.div<{ $isOpen: boolean; $isGrouped: boolean }>`
  width: 100%;
  display: flex;
  position: relative;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid
    ${({ theme, $isOpen }) => ($isOpen ? theme.color.darkgrey : theme.palette.grey['100'])};
  ${({ $isGrouped }) => ($isGrouped ? Grouped : null)}
`;

export const InputLabel = styled.p<{ $isMinimized: boolean }>`
  font-size: 15px;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  position: absolute;
  transition-property: top, font-size;
  transition-duration: 0.2s;
  color: ${({ theme }) => theme.palette.grey['200']};
  ${({ $isMinimized }) => ($isMinimized ? MinimizedInputLabel : '')}
`;

export const StyledInput = styled.input`
  width: 100%;
  font-size: 15px;
  appearance: none;
  z-index: 1;
  border-radius: 0 7px 7px 0;
  padding: 24px 32px 10px 10px !important;
  background: transparent !important;
`;

export const ResetButton = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(-3px, -3px);
  z-index: 1;
  width: 26px;
  height: 26px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  background-image: url(${ResetSvg});
`;

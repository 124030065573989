import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Field } from 'packs/autocatalog/types';
import * as S from './SpecTable.styles';

interface SpecTableProps {
  title: string;
  fields: Field[];
}

export const SpecTable = ({ title, fields }: SpecTableProps) => {
  return (
    <S.Container>
      {title && <S.Title>{title}</S.Title>}

      {fields.map(item => {
        return (
          <S.Grid key={item.label}>
            <S.Label>{item.label}</S.Label>
            <S.Value>{isEmpty(item.value) ? '-' : item.value}</S.Value>
          </S.Grid>
        );
      })}
    </S.Container>
  );
};

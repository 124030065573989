import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWindowComponent } from '../index';
import { DeleteAccountContext } from '../../../context';
import { OpenedWindow } from '../../../context/initialState';
import { DeleteBtn } from './styled';
import { useAxios } from '../../../../../hooks/useAxios';
import {
  BlueBtn, Footer, Message, PopupHeader
} from '../styled';

export const DeleteWindow = () => {
  const { setOpenedWindow, isMobileSite } = useContext(DeleteAccountContext);
  const { t } = useTranslation();

  const { callRequest } = useAxios({
    method: 'post',
    url: '/api/v2/profile/delete.json',
    onError: () => { setOpenedWindow(OpenedWindow.errorWindow); },
    onSuccess: () => { window.location.href = '/'; }
  });

  const onClose = () => {
    setOpenedWindow(OpenedWindow.none);
  };

  return (
    <ModalWindowComponent
      onClose={onClose}
      title={<PopupHeader>{t('delete.title')}</PopupHeader>}
      popupWidth={isMobileSite ? 'calc(100% - 30px)' : '455px'}
      hasCloseBtn={false}
      headerStyles={{ justifyContent: 'center' }}
    >
      <>
        <Message>{t('delete.message')}</Message>
        <Footer>
          <BlueBtn onClick={onClose}>{t('delete.cancel')}</BlueBtn>
          <DeleteBtn onClick={callRequest}>{t('delete.delete_btn')}</DeleteBtn>
        </Footer>
      </>
    </ModalWindowComponent>
  );
};

import styled, { css } from 'styled-components';
import ArrowLeftSvg from '@assets/images/application/icons-for-sprite/icon-arrow-left--white.svg';
import ArrowRightSvg from '@assets/images/application/icons-for-sprite/icon-arrow-right--white.svg';

const Arrow = css`
  position: absolute;
  top: 50%;
  height: 40px;
  width: 40px;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  transform: translateY(-50%);
  transition: opacity 0.2s linear;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Label = styled.p`
  position: absolute;
  left: 15px;
  bottom: 17px;
  z-index: 1;
  font-weight: 500;
  word-wrap: break-word;
  padding-right: 15px;
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => (theme.isMobileSite ? 14 : 16)}px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: absolute;
  width: calc(100% - 10px);
  left: 0;
  bottom: 0;
  z-index: 1;
  background-color: #00000099;
`;

export const CardTitle = styled.p`
  font-weight: 500;
  word-wrap: break-word;
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => (theme.isMobileSite ? 14 : 15)}px;
`;

export const Image = styled.img`
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-drag: none;
  transition: opacity 0.2s linear;
`;

export const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const LeftArrow = styled.button`
  ${Arrow};
  left: 15px;
  background-image: url(${ArrowLeftSvg});
`;

export const RightArrow = styled.button`
  ${Arrow};
  right: 15px;
  background-image: url(${ArrowRightSvg});
`;

export const Indicators = styled.span`
  position: absolute;
  bottom: 8px;
  left: 50%;
  z-index: 3;
  opacity: 0;
  display: flex;
  gap: 3px;
  border-radius: 15px;
  transform: translateX(-50%);
  font-size: 12px;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s linear;
  color: ${({ theme }) => theme.color.white};
`;

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.color.black};

  &:hover {
    ${Indicators} ,
    ${RightArrow},
    ${LeftArrow} {
      opacity: 1;
    }

    ${Image} {
      opacity: 0.8;
    }
  }

  .swiper-container {
    height: 100%;
  }

  .swiper-slide {
    height: auto;
  }
`;

export const IndicatorDot = styled.div<{
  isActive: boolean;
  fadeLevel: string;
}>`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ theme, isActive, fadeLevel }) =>
    isActive ? theme.color.white : `rgba(240, 240, 240, ${fadeLevel})`};
`;

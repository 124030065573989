import { globalTheme } from '../../styled';

export const theme = isMobileSite => ({
  ...globalTheme(isMobileSite),
  color: {
    ...globalTheme(isMobileSite).color,
    grey: '#f6f7fa',
    lightGrey: '#eaebf2'
  }
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Empty.styles';

export const Empty = () => {
  const { t } = useTranslation();

  return (
    <S.EmptySection>
      <S.EmptyPlaceholder />
      <S.EmptyTitle>{t('notFound.title')}</S.EmptyTitle>
      <S.EmptyText>{t('notFound.text')}</S.EmptyText>
    </S.EmptySection>
  );
};

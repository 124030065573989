import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAxios } from 'hooks';
import { ToggleButton } from 'components';
import { apiPaths, MODIFICATION_ID, RoutePath } from 'packs/autocatalog/constants';
import { useQueryState } from 'packs/autocatalog/hooks';
import { FeaturesResponse } from 'packs/autocatalog/types';
import {
  Empty,
  ItemCard,
  Loader,
  ModelGeneration,
  TabSwitcher
} from 'packs/autocatalog/components/desktop';
import { Tabs, FeatureTab, GenerationListProps } from './GenerationsList.types';
import * as S from './GenerationsList.styles';
import { FeatureSpecItem } from './FeatureSpecItem';

export const GenerationList: React.FC<GenerationListProps> = ({ isLoading, generations }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [query] = useQueryState();

  const [feature, setFeature] = useState<FeatureTab | null>(null);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.generation);

  const featuresReq = useAxios<FeaturesResponse>({
    method: 'GET',
    url: apiPaths.features(history.location.search),
    onSuccess: data => {
      setActiveTab(Tabs.generation);
      setFeature(data?.feature_tabs?.[0] ?? null);
    }
  });

  const tabs = featuresReq?.response?.feature_tabs ?? [];
  const categories = featuresReq?.response?.categories ?? [];
  const hasData = generations.length && categories.length;

  useEffect(() => {
    if (query.model || query.model_group) {
      featuresReq.callRequest();
    }
  }, [query.model, query.model_group, history.location.search]);

  const onModificationClick = (generationId: number, modificationId: number) => {
    const query = new URLSearchParams(history.location.search);

    query.delete('generation');
    history.push(`${RoutePath.model}/${generationId}/${modificationId}/?` + query.toString());
  };

  const renderGenerationTab = () => {
    return generations.map(generation => {
      return (
        <ModelGeneration
          key={generation.id}
          id={generation.id}
          generation={generation.name}
          date={generation.years}
          items={generation.categories}
        />
      );
    });
  };

  const renderFeaturesTab = () => {
    return (
      <>
        <S.SpecsButtons>
          {tabs.map(tab => {
            return (
              <ToggleButton
                key={tab.id}
                isActive={feature?.id === tab.id}
                onChange={() => setFeature(tab)}
              >
                {tab.label}
              </ToggleButton>
            );
          })}
        </S.SpecsButtons>

        {categories.map((generation, i) => (
          <S.ModelItem key={generation.name + i}>
            <S.SpecsTitle>
              {feature?.label} {generation.name}
            </S.SpecsTitle>

            {generation.items.map((category, idx) => {
              return (
                <S.GenerationItem key={`${category.name}-${idx}`}>
                  <ItemCard
                    onClick={() =>
                      onModificationClick(
                        generation.generation_id,
                        category.features[0].modification.id
                      )
                    }
                    className="card"
                    image={category.thumbnail}
                    label={category.name}
                  />

                  <S.SpecsContainer>
                    <S.SpecsGridContainer>
                      <S.SpecsRow>
                        {Object.entries(category.features[0]).map(([key, value]) => {
                          if (feature?.fields.includes(key) || key === MODIFICATION_ID) {
                            return (
                              <S.SpecsCol>
                                <S.SpecHeader>{value?.label}</S.SpecHeader>
                              </S.SpecsCol>
                            );
                          }
                        })}
                      </S.SpecsRow>

                      {category.features.map((item, idx) => (
                        <FeatureSpecItem key={idx} item={item} featureTab={feature} />
                      ))}
                    </S.SpecsGridContainer>
                  </S.SpecsContainer>
                </S.GenerationItem>
              );
            })}
          </S.ModelItem>
        ))}
      </>
    );
  };

  return (
    <S.Container>
      {isLoading || featuresReq.loading ? (
        <Loader />
      ) : hasData ? (
        <>
          <TabSwitcher
            isFullWidth
            defaultTab={activeTab || Tabs.generation}
            tabs={{
              [Tabs.generation]: t('mainSearch.generation'),
              [Tabs.specs]: t('specs')
            }}
            onTabChange={(tab: Tabs) => setActiveTab(tab)}
          />
          <S.TabBody>
            {activeTab === Tabs.generation ? renderGenerationTab() : renderFeaturesTab()}
          </S.TabBody>
        </>
      ) : (
        <Empty />
      )}
    </S.Container>
  );
};

import React from 'react';
import * as S from './ItemCard.styles';

interface ItemCardProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  image: string;
}

export const ItemCard: React.FC<ItemCardProps> = React.memo(({ label, image, ...props }) => {
  return (
    <S.Container {...props}>
      <S.Image src={image} loading="lazy" />
      <S.LabelContainer>
        <S.CardTitle>{label}</S.CardTitle>
      </S.LabelContainer>
    </S.Container>
  );
});

import styled, { css } from 'styled-components';
import ResetSvg from '@assets/images/application/icon-reset--red.svg';
import CheckedSvg from '@assets/images/application/icon-checked--white.svg';

const OpenedContainer = css`
  display: block !important;
  opacity: 1 !important;
  transform: scaleY(1) !important;
`;

const SelectedOption = css`
  color: ${({ theme }) => theme.color.red};
`;

const NestedOption = css`
  display: list-item;
  margin-left: 1.5em;
`;

const SelectedCheckbox = css`
  color: ${({ theme }) => theme.color.red};
  border-color: ${({ theme }) => theme.color.red};
  background-color: ${({ theme }) => theme.color.red};
  background-repeat: no-repeat;
  background-image: url(${CheckedSvg});
  background-position: center;
`;

export const Container = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 53px;
  left: -1px;
  width: 100%;
  overflow: auto;
  max-height: 335px;
  border-radius: 7px;
  transform: scaleY(0);
  z-index: 99;
  opacity: 0;
  transform-origin: center top;
  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);

  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.palette.grey['100']};

  ${({ $isOpen }) => ($isOpen ? OpenedContainer : '')}
`;

export const ListItem = styled.div`
  position: relative;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  color: ${({ theme }) => theme.color.blueMidnight};

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey['50']};
  }
`;

export const ListItemLabel = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 23px 9px 10px;
  ${({ $isSelected }) => ($isSelected ? SelectedOption : '')}
`;

export const ListItemText = styled.span<{ $isNested: boolean }>`
  ${({ $isNested }) => ($isNested ? NestedOption : '')}
`;

export const ResetButton = styled.span`
  display: flex;
  align-items: center;
  gap: 7px;

  &::before {
    content: '';
    display: inline-block;
    width: 11px;
    height: 11px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${ResetSvg});
  }
`;

export const Checkbox = styled.span<{ $isSelected: boolean }>`
  display: inline-flex;
  min-width: 20px;
  height: 20px;
  margin-left: 2px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.palette.grey['100']};
  ${({ $isSelected }) => ($isSelected ? SelectedCheckbox : '')}
`;

import React, {
  Dispatch, SetStateAction, useEffect, useRef
} from 'react';
import classNames from 'classnames';
import { TFunction } from 'react-i18next';
import { convertToLitres, doFormatDate, numberWithFractionDigits } from '../../helpers';
import { getAdPageLink } from '../../helpers/getAdPageLink';
import {
  Actions,
  CurrencyLabel, Flag, ItemI, ItemTabName
} from './types';
import { CardServices } from '../CardServices';
import { ItemTop } from './styled';
import { TimeFormat } from '../../types';
import { useOnScreen } from '../../hooks/useOnScreen';
import { getAdStatus } from '../../packs/profile/items/helpers';
import {
  setDataStatAttrForProfileAdLink,
  setDataStatAttrForProfileTabsActions
} from './data-stat';

interface ItemProps {
  item: ItemI,
  isMobile: boolean,
  t: TFunction,
  setPage: Dispatch<SetStateAction<number>>,
  lastItemId: number | null,
  tabName: ItemTabName
}

export const Item: React.FC<ItemProps> = ({
  item,
  isMobile,
  t,
  setPage,
  lastItemId,
  tabName
}) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const showServices = !isMobile && item.published;
  const featured = item.flags?.includes(Flag.featured) || false;
  const vipped = item.flags?.includes(Flag.vipped) || false;
  const showActions = item.pending_paid
    || (item.expired && item.is_owner)
    || (showServices && !isMobile)
    || item.is_edit_rejectable;
  const route = getAdPageLink(item.id, item.name);
  const props = item.id === lastItemId ? { ref } : {};
  const status = tabName === ItemTabName.all ? getAdStatus(item) : '';

  const itemTopClasses = classNames('products-i__top', { spincar: item.spincar_panorama_url });
  const itemContainerClasses = classNames(
    'products-i',
    { 'with-services': showActions },
    { salon: item.is_shop },
    { featured },
    { vipped }
  );

  const prolongButtonClasses = classNames(
    'products-i-purchase-btn',
    { 'products-i-purchase-btn--disabled': !item.is_owner }
  );

  useEffect(() => {
    if (isVisible) setPage(page => page + 1);
  }, [isVisible]);

  return (
    <div className={itemContainerClasses}>
      <a
        className="products-i__link"
        target={isMobile ? '_self' : '_blank'}
        href={route}
        {...setDataStatAttrForProfileAdLink(tabName)}
        rel="noreferrer"
      />
      <ItemTop className={itemTopClasses} $url={item.spincar_panorama_url} {...props}>
        {!item.spincar_panorama_url && <img alt={item.name} loading="lazy" src={item.photo} />}
        {status && (
          <div className={`products-i-purchase__status ${status}`}>
            {t(`status.${status}`)}
          </div>
        )}
        <div className="products-i__label-container tz-d-flex tz-gap-5 tz-wrap-wrap">
          {item.is_shop && item.availability_status !== 'order' && (
            <div className="products-i__label products-i__label--salon">{t('labels.shop')}</div>
          )}
          {item.availability_status === 'order' && (
            <div className="products-i__label products-i__label--order">{t('labels.order')}</div>
          )}
          {item.vin && (
            <div className="products-i__label products-i__label--vin">{t('labels.vin')}</div>
          )}
          {item.for_spare_parts && (
            <div className="products-i__label products-i__label--spare-parts">{t('labels.for_spare_parts')}</div>
          )}
        </div>
        {!!item.flags.length && (
        <div className="products-i__paid">
          {featured && <span className="featured-icon" />}
          {vipped && <span className="vipped-icon" />}
        </div>
        )}
        <div className="products-i__info">
          {item.barter && (
            <div className="products-i__icon products-i__icon--barter">
              {!isMobile && (
                <div className="products-i__tooltip products-i__tooltip--barter">
                  {t('barter_possible')}
                </div>
              )}
            </div>
          )}
          {item.loan && (
            <div className="products-i__icon products-i__icon--loan">
              {!isMobile && (
                <div className="products-i__tooltip products-i__tooltip--loan ">
                  {t('loan')}
                </div>
              )}
            </div>
          )}
          {item.spincar_panorama_url && <div className="products-i__icon spincar360" />}
        </div>
      </ItemTop>
      <div className="products-i__bottom">
        <div className="products-i__price products-i__bottom-text">
          {`${numberWithFractionDigits(item.price, {})} ${CurrencyLabel[item.currency]} `}
        </div>
        <div className="products-i__name products-i__bottom-text">{item.name}</div>
        <div className="products-i__attributes products-i__bottom-text">
          {`${item.year}, ${convertToLitres(item.engine_volume)} L, ${numberWithFractionDigits(item.mileage, {})} km`}
        </div>
        <div className="products-i__datetime">
          {`${item.region.name}, ${doFormatDate({ date: item.updated_at, format: TimeFormat.fullTime2 })}`}
        </div>
        {showServices && <CardServices {...{ t, tabName, itemId: item.id }} />}
        {item.pending_paid && (
          <a
            className="products-i-purchase-btn"
            href={`${route}#awaiting_payment`}
            {...setDataStatAttrForProfileTabsActions(Actions.pay, tabName)}
          >
            {t('actions.pay')}
          </a>
        )}
        {item.expired && (
          <a
            className={prolongButtonClasses}
            href={`${route}/edit_request`}
            {...setDataStatAttrForProfileTabsActions(Actions.prolong, tabName)}
          >
            {t('actions.prolong')}
          </a>
        )}
        {item.is_edit_rejectable && (
          <a
            className={prolongButtonClasses}
            href={`${route}/edit_rejects/edit`}
            {...setDataStatAttrForProfileTabsActions(Actions.edit_rejected, tabName)}
          >
            {t('actions.make_edits')}
          </a>
        )}
      </div>
    </div>
  );
};

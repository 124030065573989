import styled from 'styled-components';
import {
  blue, blueMidnight, darkgrey, FlexCenterAligned, white
} from '../../../../../styled';

export const ErrorContainer = styled(FlexCenterAligned)`
  font-size: 15px;
  font-weight: 400;
  flex-direction: column;
  text-align: center;
  padding: 0 15px 15px;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${blueMidnight};
`;

export const Message = styled.span`
  color: ${darkgrey};
  margin-top: 15px;
`;

export const Button = styled.span<{ isMobile: boolean }>`
  border-radius: 7px;
  cursor: pointer;

  ${({ isMobile }) => (isMobile ? ({
    margin: '15px 0 15px',
    padding: '11px 30px',
    color: white,
    backgroundColor: blue
  }) : ({
    margin: '20px 0 15px',
    padding: '15px 30px',
    color: blue,
    backgroundColor: '#dae8ff'
  }))};
  
  &:hover {
    background-color: #c6dbff;
  }
`;

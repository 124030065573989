import React from 'react';
import {
  element, string, object, func, bool
} from 'prop-types';
import {
  ModalWindowContainer, ModalWindow, Header, Content, Close
} from './styled';

export const ModalWindowComponent = ({
  title,
  children,
  onClose,
  popupWidth,
  styles,
  hasCloseBtn,
  headerStyles,
  contentStyles
}) => (
  <ModalWindowContainer onClick={onClose}>
    <ModalWindow width={popupWidth} styles={styles} onClick={e => { e.stopPropagation(); }}>
      <Header styles={headerStyles}>
        {title}
      </Header>
      <Content styles={contentStyles}>{children}</Content>
      {hasCloseBtn && <Close onClick={onClose} />}
    </ModalWindow>
  </ModalWindowContainer>
);

ModalWindowComponent.propTypes = {
  children: element.isRequired,
  title: (element || string).isRequired,
  onClose: func.isRequired,
  popupWidth: string,
  styles: object,
  hasCloseBtn: bool,
  headerStyles: object,
  contentStyles: object
};

ModalWindowComponent.defaultProps = {
  popupWidth: '455px',
  styles: {},
  hasCloseBtn: true,
  headerStyles: {},
  contentStyles: {}
};

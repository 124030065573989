import React, { useState } from 'react';
import { element, object } from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Provider } from './index';
import { initialState } from './initialState';
import { theme } from '../components/styled';

export const ContextWrapper = ({
  children, dataset
}) => {
  const isMobileSite = dataset.isMobileSite === 'true';
  const [openedWindow, setOpenedWindow] = useState(initialState.openedWindow);

  const contextValue = {
    openedWindow,
    setOpenedWindow,
    isMobileSite
  };

  return (
    <Provider value={contextValue}>
      <ThemeProvider theme={theme(isMobileSite)}>{ children }</ThemeProvider>
    </Provider>
  );
};

ContextWrapper.propTypes = {
  children: element.isRequired,
  dataset: object.isRequired
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryState } from 'packs/autocatalog/hooks';
import { ItemCard } from 'packs/autocatalog/components/desktop';
import { CatalogItem } from './CatalogDesktop.types';
import * as S from './CatalogDesktop.styles';

interface NewItemCardsProps {
  items: CatalogItem[];
}

export const NewItemCards: React.FC<NewItemCardsProps> = ({ items }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryState();

  const onCardClick = ({ make, model }: CatalogItem) => {
    setQuery({
      make: make.value,
      model: model.isCategory ? '' : model.value,
      model_group: model.isCategory ? model.value : ''
    });
  };

  return (
    <>
      <S.SectionTitle>{t('newItems')}</S.SectionTitle>
      <S.NewItemsGrid>
        {items.map(item => {
          return (
            <ItemCard
              image={item.thumbnails[0]}
              onClick={() => onCardClick(item)}
              key={`${item.model.value}-${item.make.value}`}
              label={`${item.make.label} ${item.model.label}`}
            />
          );
        })}
      </S.NewItemsGrid>
    </>
  );
};

import React from 'react';
import { LoaderContainer, LoaderStyled } from './styled';
import { mainTurboColor } from '../../styled';

export const Loader: React.FC<{ size?: string; fill?: string }> = ({ size, fill }) => (
  <LoaderContainer>
    <LoaderStyled size={size} fill={fill} />
  </LoaderContainer>
);
Loader.defaultProps = {
  size: '25px',
  fill: mainTurboColor
};

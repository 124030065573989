import React from 'react';

interface MobileHeaderProps {
  title: string;
  isFixed?: boolean;
  onBackBtnClick?: () => void;
  renderRightSide?: () => React.ReactNode;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({
  title,
  onBackBtnClick,
  renderRightSide,
  isFixed = false
}) => {
  return (
    <div className={`header js-header ${isFixed ? 'is-fixed' : ''}`}>
      <div className="header__nav">
        <div className="header__nav-left">
          <span onClick={onBackBtnClick} className="header__nav-btn--back" />
        </div>
        <div className="header__nav-title">{title}</div>
        <div className="header__nav-right">{renderRightSide?.()}</div>
      </div>
    </div>
  );
};

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './DropdownList.styles';

const HALF_HEIGHT = 167.5;

export interface DropdownOption {
  label: string;
  value: string | number;
  color?: string;
  image?: string;
  isCategory?: boolean;
  isGroup?: boolean;
  isSection?: boolean;
}

interface DropdownListProps {
  isOpen: boolean;
  options: DropdownOption[];
  selectedOptions: DropdownOption[];
  isMultiSelect?: boolean;
  isCategorized?: boolean;
  onReset: () => void;
  onSelect: (item: DropdownOption) => void;
}

export const DropdownList: React.FC<DropdownListProps> = ({
  isOpen,
  onReset,
  onSelect,
  options = [],
  selectedOptions,
  isMultiSelect = false,
  isCategorized = false
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const selectedListItemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen && options.length && containerRef.current && selectedListItemRef.current) {
      const offsetTop = selectedListItemRef.current.offsetTop;
      containerRef.current.scrollTop = offsetTop - HALF_HEIGHT;
    }
  }, [isOpen]);

  return (
    <S.Container $isOpen={isOpen} ref={containerRef}>
      <S.ListItem onClick={onReset}>
        <S.ListItemLabel>
          <S.ResetButton>{t('mainSearch.reset')}</S.ResetButton>
        </S.ListItemLabel>
      </S.ListItem>

      {options.map(item => {
        const isGrouped = !!item?.isGroup;
        const isSelected = selectedOptions.some(
          option => option.isCategory === item.isCategory && +option.value === +item.value
        );

        return (
          <S.ListItem
            ref={isSelected ? selectedListItemRef : null}
            key={`${item.value}-${item.label}`}
            onClick={() => onSelect(item)}
          >
            <S.ListItemLabel $isSelected={isSelected}>
              <S.ListItemText $isNested={isCategorized && isGrouped}>{item.label}</S.ListItemText>
              {isMultiSelect && <S.Checkbox $isSelected={isSelected} />}
            </S.ListItemLabel>
          </S.ListItem>
        );
      })}
    </S.Container>
  );
};

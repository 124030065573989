import {
  bool, func, number, objectOf, shape, string
} from 'prop-types';

export const initialState = {
  isMobile: false,
  openedQuestionId: null,
  countOfSteps: 0,
  selectedCategory: null,
  setOpenedQuestionId: () => {},
  setSelectedCategory: () => {}
};

initialState.propTypes = {
  isMobile: bool.isRequired,
  openedQuestionId: number,
  countOfSteps: number,
  selectedCategory: objectOf(shape({ slug: string, name: string })) || null,
  setSelectedCategory: func,
  setOpenedQuestionId: func
};

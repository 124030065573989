import React from 'react';
import * as classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { existedPromotions } from '../packs/invoices/constants';

export const checkPromotions = (promotions, showPromotions) => {
  const { t } = useTranslation();

  const packagePromotions = promotion => classNames(
    'package-info',
    { 'not-include': !promotion }
  );

  return existedPromotions.map(item => showPromotions?.some(v => item.indexOf(v) !== -1) && (
  <div key={item} className={packagePromotions(promotions?.indexOf(item) !== -1)}>
    {t(`packages.${item}`)}
  </div>
  ));
};

import React, { useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { accountsBalancesPath } from '../routes';
import { numberWithCurrency } from '../../../helpers';
import { InvoicesAppContext } from '../context';
import { useOnScreen } from '../../../hooks/useOnScreen';
import { BalanceModal } from './PaymentModal/BalanceModal';
import { RoutePath } from '../constants';

export const Balance = () => {
  const {
    setBalanceData,
    setHasErrors,
    setIsBalanceModalVisible,
    setBalanceRef,
    setVisibleTabRef,
    isMobileSite
  } = useContext(InvoicesAppContext);
  const { t } = useTranslation();
  const location = useLocation();
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const onSuccess = response => {
    if (response?.cash) {
      setBalanceData(response.cash);
    }
  };

  if (isMobileSite && location.pathname !== RoutePath.packages) {
    return <Redirect to={{ pathname: RoutePath.packages }} />;
  }

  const { response } = useAxios({
    method: 'get',
    url: accountsBalancesPath,
    isInitialCall: true,
    onSuccess,
    onError: () => setHasErrors(true)
  });

  useEffect(() => {
    setBalanceRef(ref);
    if (isVisible) {
      setVisibleTabRef(ref);
    }
  }, [isVisible]);

  return (
    <>
      <div className="invoices_balance">
        <div className="invoices_balance-wallet_container">
          <div className="invoices_balance-i">
            <div>
              <div ref={ref} className="invoices_balance-title">
                {t('balance.title')}
              </div>
              <div className="invoices_balance-description">
                {t('balance.title_description')}
              </div>
            </div>
            <div>
              <div className="invoices_balance-value">
                {numberWithCurrency(response?.total)}
              </div>
              <button
                className="invoices_balance-pay"
                value={t('balance.pay')}
                type="button"
                onClick={() => setIsBalanceModalVisible(true)}
              >
                {t('balance.pay')}
              </button>
            </div>
          </div>
        </div>

        <div className="invoices_balance-types_container">
          <div className="invoices_balance-types-title">{t('balance.types.title')}</div>
          <div className="invoices_balance-types">
            <div className="invoices_balance-i">
              <div>
                <div className="invoices_balance-i-title">{t('balance.cash')}</div>
                <div className="invoices_balance-description">
                  {t('balance.cash_description')}
                </div>
              </div>
              <div className="invoices_balance-value">{numberWithCurrency(response?.cash)}</div>
            </div>
            <div className="invoices_balance-i">
              <div>
                <div className="invoices_balance-i-title">{t('balance.pack')}</div>
                <div className="invoices_balance-description">
                  {t('balance.pack_description')}
                </div>
              </div>
              <div className="invoices_balance-value">{numberWithCurrency(response?.pack, '')}</div>
            </div>
            <div className="invoices_balance-i">
              <div>
                <div className="invoices_balance-i-title">{t('balance.bonus')}</div>
                <div className="invoices_balance-description">
                  {t('balance.bonus_description')}
                </div>
              </div>
              <div className="invoices_balance-value">{numberWithCurrency(response?.bonus, '')}</div>
            </div>
          </div>
        </div>
      </div>
      <BalanceModal />
    </>
  );
};

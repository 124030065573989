import React, { useEffect } from 'react';
import { useNumericInput } from 'hooks';
import * as S from './FilterInput.styles';

interface FilterInputProps {
  label?: string;
  placeholder: string;
  inputValue: string;
  onChange: (value: string) => void;
}

export const FilterInput: React.FC<FilterInputProps> = ({
  label = '',
  placeholder,
  onChange,
  inputValue
}) => {
  const { value, handleChange } = useNumericInput('');
  const hasValue = value.length > 0;
  const hasLabel = label.length > 0;

  useEffect(() => {
    handleChange(inputValue);
  }, [inputValue]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    onChange(inputValue.replace(/\s/g, ''));
    handleChange(inputValue);
  };

  const onReset = () => {
    onChange('');
    handleChange('');
  };

  return (
    <S.Container>
      <S.InputSection>
        {hasLabel && <S.Label $isMinimized>{label}</S.Label>}
        <S.Input
          type="text"
          pattern="\d*"
          value={value}
          onChange={onInputChange}
          placeholder={placeholder}
        />
      </S.InputSection>
      {hasValue && <S.ResetButton onClick={onReset} />}
    </S.Container>
  );
};

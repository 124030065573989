import styled, { css } from 'styled-components';

const SelectedOption = css`
  color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.color.red};
  background-color: ${({ theme }) => theme.color.red};
`;

export const Container = styled.div`
  display: flex;
`;

export const Option = styled.button<{ $isSelected: boolean }>`
  flex: auto;
  line-height: 1;
  padding: 15px 0;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.grey['100']};
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

  &:first-of-type {
    border-right: 0;
    border-radius: 7px 0 0 7px;
  }

  &:last-of-type {
    border-left: 0;
    border-radius: 0 7px 7px 0;
  }

  color: ${({ theme }) => theme.palette.grey['200']};
  ${({ $isSelected }) => ($isSelected ? SelectedOption : '')};
`;

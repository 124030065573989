import React, { useContext, PropsWithChildren, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { initialState } from './initialState';
import { FilterOptions, GenerationsResponse, ModelsResponse } from '../types';
import { formatPlainModels } from '../utils';
import { theme } from '../styled';

interface ContextWrapperProps extends PropsWithChildren<any> {
  dataset: {
    behavior: string;
    isMobileSite: string;
  };
}

const Context = React.createContext(initialState);
const { Provider } = Context;

export const ContextWrapper = ({ children, dataset }: ContextWrapperProps) => {
  const catalogModels = formatPlainModels(window.catalogModels);
  const catalogGenerations = window?.catalogGenerations ?? [];
  const catalogMakes = window?.catalogMakes || initialState.makes;
  const catalogPopularMakes = window?.popularCatalogMakes || initialState.popularMakes;

  const [makes, setMakes] = useState(catalogMakes);
  const [models, setModels] = useState<ModelsResponse>(catalogModels);
  const [popularMakes, setPopularMakes] = useState(catalogPopularMakes);
  const [generations, setGenerations] = useState<GenerationsResponse>(catalogGenerations);
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>(initialState.filterOptions);

  const contextValue = {
    makes,
    models,
    generations,
    isLoading,
    setLoading,
    setMakes,
    setModels,
    setGenerations,
    popularMakes,
    setPopularMakes,
    filterOptions,
    setFilterOptions,
    isMobile: dataset.isMobileSite === 'true'
  };

  return (
    <Provider value={contextValue}>
      <ThemeProvider theme={theme(contextValue.isMobile)}>{children}</ThemeProvider>
    </Provider>
  );
};

export const useAutocatalogContext = () => {
  return useContext(Context);
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './CarDimensions.styles';

interface CarDimensionProps {
  icon?: string;
  length: number;
  height: number;
  width: number;
  title?: string;
}

export const CarDimensions = ({ icon, length, height, width, title = '' }: CarDimensionProps) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      {title && <S.Title>{title}</S.Title>}
      <S.Container>
        <S.LeftSide>
          <S.RotatedSideText>
            <p>
              {height} {t('mainSearch.millimeters')}
            </p>
          </S.RotatedSideText>
        </S.LeftSide>
        <S.Icon $icon={icon} />
        <S.RightSide>
          <p>
            {width} {t('mainSearch.millimeters')}
          </p>
        </S.RightSide>
        <S.BottomSide>
          <p>
            {length} {t('mainSearch.millimeters')}
          </p>
        </S.BottomSide>
      </S.Container>
    </S.Wrapper>
  );
};

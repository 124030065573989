import styled, { css } from 'styled-components';
import ArrowDownSvg from '@assets/images/application/icon-arrow-down--blue.svg';

export const ExpandedFilters = css`
  position: relative;
  visibility: visible;
  opacity: 1;
  max-height: 1000px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[75]};
`;

export const Wrapper = styled.div`
  width: 970px;
  margin: 0 auto;
  padding: 15px 0;
`;

export const FilterControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 40px;
  cursor: pointer;
  border-radius: 7px;
  font-size: 15px;
  margin-left: 15px;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.palette.blue[200]};
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.palette.blue[300]};
  }
`;

export const ResetFilters = styled.button`
  font-size: 15px;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  color: ${({ theme }) => theme.palette.grey['200']};
`;

export const ExpandFilters = styled.button<{ $isExpanded: boolean }>`
  position: relative;
  font-size: 15px;
  background-size: 16px 9px;
  padding-right: 25px;
  cursor: pointer;
  background: transparent;
  color: ${({ theme }) => theme.palette.blue[200]};
  transition: color 0.15s ease-in-out;
  margin-left: 35px;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 16px;
    height: 9px;
    background-image: url(${ArrowDownSvg});
    background-repeat: no-repeat;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(-50%) ${({ $isExpanded }) => ($isExpanded ? 'rotate(180deg)' : '')};
  }

  &:hover {
    color: ${({ theme }) => theme.palette.blue[300]};
  }
`;

export const MinimizedFilters = styled.div<{ $isExpanded: boolean }>`
  visibility: hidden;
  max-height: 0;
  opacity: 0;
  transition:
    transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  ${({ $isExpanded }) => ($isExpanded ? ExpandedFilters : '')}
`;

export const Group = styled.div`
  display: flex;
  width: 100%;
`;

import styled, { css } from 'styled-components';

const Text = css`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const Grid = styled.div`
  display: grid;
  max-width: 315px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Label = styled.p`
  ${Text}
  word-break: break-word;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

export const Value = styled.p`
  ${Text}
  color: ${({ theme }) => theme.palette.blue[200]};
`;

import useSWR from 'swr';
import { fetcher } from './api';
import { formatGenerationOptions } from '../utils';

export const useGenerationOptions = (url: string | null) => {
  const { data, isLoading, error, mutate } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false
  });

  return {
    mutate,
    data: formatGenerationOptions(data?.data ?? []),
    isLoading,
    isError: error
  };
};

import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { DropdownOption, MobileHeader } from 'components';
import { GlobalStyle } from 'packs/autocatalog/styles';
import { useAxios } from 'hooks';
import { CatalogItemsResponse } from 'packs/autocatalog/types';
import { apiPaths, RoutePath } from 'packs/autocatalog/constants';
import { useLangUpdate, useQueryState } from 'packs/autocatalog/hooks';
import {
  useCatalogGenerations,
  useCatalogItems,
  useCatalogModels,
  useGenerationOptions
} from 'packs/autocatalog/api';
import { useAutocatalogContext } from 'packs/autocatalog/context';
import { ItemCard, Loader } from 'packs/autocatalog/components/desktop';
import { DropdownSelect, GenerationList } from 'packs/autocatalog/components/mobile';
import { PopularMakes } from 'packs/autocatalog/components/mobile';
import {
  countFiltersInURL,
  formatCatalogItems,
  formatModelOptions,
  groupMakeOptions,
  selectInitialModel
} from 'packs/autocatalog/utils';
import { CatalogItem } from '../CatalogDesktop/CatalogDesktop.types';
import { POPULAR_ITEMS_LIMIT } from './CatalogMobile.constants';
import * as S from './CatalogMobile.styles';

export const CatalogMobile = () => {
  useLangUpdate();
  const history = useHistory();
  const { t } = useTranslation();
  const [query, setQuery] = useQueryState();
  const context = useAutocatalogContext();
  const filtersCount = countFiltersInURL(query);

  const [page, setPage] = useState<number | undefined>();
  const [moreItems, setMoreItems] = useState<CatalogItem[]>([]);

  const makeId = query.make ?? '';
  const modelId = (query.model || query.model_group) ?? '';
  const hasFilters = filtersCount > 0;
  const isModelEmpty = !(query.model || query.model_group);
  const itemsUrl = apiPaths.items(`${history.location.search}`);
  const generationUrl = `?make=${makeId}&${query.model_group ? 'model_group' : 'model'}=${modelId}`;

  const items = useCatalogItems(isModelEmpty ? itemsUrl : null);
  const models = useCatalogModels(makeId ? apiPaths.models(makeId) : null, formatModelOptions);
  const generations = useCatalogGenerations(
    modelId ? apiPaths.generations(history.location.search) : null
  );

  const makeOptions = groupMakeOptions(context.makes, context.popularMakes, t);
  const modelOptions = models.data.length ? models.data : formatModelOptions(context.models);
  const generationOptions = useGenerationOptions(
    modelId ? apiPaths.generations(generationUrl) : null
  );
  const filteredGenerations = generations.data.filter(
    item => item.id === +query.generation || !query.generation
  );

  const initModel = selectInitialModel(query, modelOptions);
  const initMake = makeOptions.find(item => item.value === +query.make) ?? null;
  const initGeneration =
    generationOptions.data.find(item => item.value === +query.generation) ?? null;

  const isLoading = items.isLoading || generations.isLoading;

  useEffect(() => {
    setMoreItems([]);
  }, [history.location.search]);

  useEffect(() => {
    if (!items?.next_page) setPage(undefined);
    if (items.next_page !== page) setPage(items.next_page);
  }, [items.next_page, history.location.search]);

  const moreReq = useAxios<CatalogItemsResponse>({
    method: 'GET',
    url: '',
    onSuccess: data => {
      const formattedData = formatCatalogItems(data);

      if (formattedData.length) {
        setPage(data.next_page);
        setMoreItems(prev => [...prev, ...formattedData]);
      }
    }
  });

  useEffect(() => {
    document.querySelector('.footer')?.classList.remove('is-hidden');
    document.querySelector('.tab-bar-container')?.classList.remove('is-hidden');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.search]);

  useEffect(() => {
    if (moreReq.loading || isLoading) return;

    const handleScroll = debounce(() => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 768 && page) {
        moreReq.callRequest({
          withUrl: apiPaths.items(`${history.location.search}&page=${page}`)
        });
      }
    }, 100);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, moreReq.loading, page]);

  useEffect(() => {
    // When route changes (inside react) tabar can't find header selector, rebind is needed
    window?.TapBarOnScroll?.rebindScrollHandler?.();
  }, [window?.TapBarOnScroll]);

  const onMakeChange = (item: DropdownOption) => {
    setQuery({
      bulk: '',
      p: 'mobile',
      model: '',
      model_group: '',
      make: item?.value ?? ''
    });
  };

  const onModelChange = (item: DropdownOption) => {
    const param = String(item?.value ?? '');

    setQuery({
      bulk: '',
      generation: '',
      model: item?.isCategory ? '' : param,
      model_group: item?.isCategory ? param : ''
    });
  };

  const onGenerationChange = (item: DropdownOption) => {
    setQuery({ generation: item?.value ?? '' });
  };

  const goToSearch = () => {
    history.replace(RoutePath.search + history.location.search);
  };

  const goBack = () => {
    const searchParams = new URLSearchParams(history.location.search);

    if (searchParams.has('generation')) {
      searchParams.delete('generation');
    } else if (searchParams.has('bulk')) {
      searchParams.delete('bulk');
      searchParams.delete('make');
      searchParams.delete('model');
      searchParams.delete('model_group');
    } else if (searchParams.has('model_group') || searchParams.has('model')) {
      searchParams.delete('model');
      searchParams.delete('model_group');
    } else if (searchParams.has('make')) {
      searchParams.delete('make');
    } else {
      window.location.href = '/';
      return;
    }

    history.push(`${RoutePath.autocatalog}?` + searchParams.toString());
  };

  const getCurrentTitle = () => {
    if (makeId || hasFilters) return t('allModels');
    return t('newItems');
  };

  const renderMakePlaceholder = () => {
    return !!makeId || hasFilters ? null : (
      <S.DefaultPlaceholder $isFullHeight>
        <S.CarIcon />
        {t('allMakes')}
      </S.DefaultPlaceholder>
    );
  };

  const renderCatalogItems = () => {
    if ((!generations.data.length && !isModelEmpty) || (!items.data.length && isModelEmpty)) {
      return (
        <S.EmptySection>
          <S.EmptyPlaceholder />
          <S.EmptyTitle>{t('notFound.title')}</S.EmptyTitle>
          <S.EmptyText>{t('notFound.text')}</S.EmptyText>
        </S.EmptySection>
      );
    }

    if (!isModelEmpty) {
      return (
        <>
          <S.SectionTitle>{t('allGenerations')}</S.SectionTitle>
          <GenerationList list={filteredGenerations} />
        </>
      );
    }

    return (
      <>
        <S.SectionTitle>{getCurrentTitle()}</S.SectionTitle>
        <S.CardsGrid>
          {[...items.data, ...moreItems].map(({ make, model, thumbnails }) => (
            <ItemCard
              key={`${model.value}-${model.label}`}
              image={thumbnails[0]}
              label={`${make.label} ${model.label}`}
              onClick={() => {
                setQuery({
                  bulk: query.make ? '' : 1,
                  make: make.value,
                  model: model.isCategory ? '' : model.value,
                  model_group: model.isCategory ? model.value : ''
                });
              }}
            />
          ))}
        </S.CardsGrid>
      </>
    );
  };

  return (
    <S.Container>
      <GlobalStyle />
      <MobileHeader title={t('catalog')} onBackBtnClick={goBack} />
      <S.SearchSection>
        <S.SearchFields>
          <DropdownSelect
            isFullscreen
            isSearchable
            value={initMake}
            options={makeOptions}
            onChange={onMakeChange}
            label={t('mainSearch.make')}
            placeholder={t('mainSearch.make')}
            renderPlaceholder={renderMakePlaceholder}
          />

          {initMake && (
            <DropdownSelect
              isFullscreen
              isSearchable
              value={initModel}
              options={modelOptions}
              onChange={onModelChange}
              label={t('mainSearch.model')}
              placeholder={t('mainSearch.model')}
              searchTitle={t('mainSearch.searchModel')}
            />
          )}

          {!initMake && !hasFilters && (
            <S.FiltersButton onClick={goToSearch}>
              <S.DefaultPlaceholder>
                <S.FiltersIcon />
                {t('mainSearch.filters')}
              </S.DefaultPlaceholder>
            </S.FiltersButton>
          )}

          {initMake && initModel && (
            <DropdownSelect
              value={initGeneration}
              options={generationOptions.data}
              onChange={onGenerationChange}
              label={t('mainSearch.generation')}
              isShort={generationOptions.data.length < 7}
              placeholder={t('mainSearch.generation')}
            />
          )}
        </S.SearchFields>

        {(initMake || hasFilters) && (
          <S.MinimizedFiltersButton onClick={goToSearch}>
            <S.DefaultPlaceholder>
              <S.FiltersIcon $isActive />
              {t('mainSearch.filters')}
              {hasFilters && <S.FiltersCount>{filtersCount}</S.FiltersCount>}
            </S.DefaultPlaceholder>
          </S.MinimizedFiltersButton>
        )}
      </S.SearchSection>

      {initMake || hasFilters ? null : (
        <PopularMakes list={context.popularMakes.slice(0, POPULAR_ITEMS_LIMIT)} />
      )}

      {isLoading ? <Loader /> : renderCatalogItems()}
    </S.Container>
  );
};

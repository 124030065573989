import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { RoutePath } from '../constants';
import { useFilterOptions } from '../api';
import { CatalogDesktop, ModelDesktop } from '../pages';

export const AutocatalogDesktop = () => {
  useFilterOptions();

  return (
    <BrowserRouter>
      <Route path={RoutePath.autocatalog} exact>
        <CatalogDesktop />
      </Route>
      <Route path={`${RoutePath.model}/:generation/:id`} exact>
        <ModelDesktop />
      </Route>
    </BrowserRouter>
  );
};

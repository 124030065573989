import React, { useEffect, useState } from 'react';
import { useNumericInput } from 'hooks';
import * as S from './FilterInput.styles';

interface FilterInputProps {
  label?: string;
  inputValue: string;
  isGrouped?: boolean;
  isHighlighted?: boolean;
  onChange: (value: string) => void;
}

export const FilterInput: React.FC<FilterInputProps> = ({
  onChange,
  inputValue,
  label = '',
  isGrouped = false,
  isHighlighted = false
}) => {
  const { value, handleChange } = useNumericInput('');
  const [isFocused, setFocused] = useState<boolean>(false);

  const hasValue = value.length > 0;
  const isOpen = hasValue || isFocused || isHighlighted;

  useEffect(() => {
    handleChange(inputValue);
  }, [inputValue]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    onChange(inputValue);
    handleChange(inputValue);
  };

  const onReset = () => {
    onChange('');
    handleChange('');
  };

  return (
    <S.Container $isGrouped={isGrouped} $isOpen={isOpen}>
      <S.StyledInput
        value={value}
        onChange={onInputChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />

      <S.InputLabel $isMinimized={hasValue || isFocused}>{label}</S.InputLabel>

      {hasValue && <S.ResetButton onClick={onReset} />}
    </S.Container>
  );
};

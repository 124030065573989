import React from 'react';
import { useHistory } from 'react-router-dom';
import { Category } from 'packs/autocatalog/types';
import { RoutePath } from 'packs/autocatalog/constants';
import { ItemCard } from 'packs/autocatalog/components/desktop';
import * as S from './ModelGeneration.styles';

interface ModelGenItemProps {
  items?: Category[];
  date: string;
  generation: string;
  id: number;
}

export const ModelGeneration: React.FC<ModelGenItemProps> = ({
  id,
  date,
  generation,
  items = []
}) => {
  const history = useHistory();

  const onClick = (ctg: Category) => {
    const query = new URLSearchParams(history.location.search);

    query.delete('generation');
    history.push(`${RoutePath.model}/${id}/${ctg.modification.id}/?` + query.toString());
  };

  return (
    <S.Container>
      <S.Grid>
        <S.Details>
          <S.Title>{generation}</S.Title>
          <S.SubTitle>{date}</S.SubTitle>
        </S.Details>
        {items.map(ctg => {
          return (
            <ItemCard
              key={ctg.modification.id}
              label={ctg?.name ?? ''}
              image={ctg.modification?.photos?.[0] ?? ctg.icon}
              onClick={() => onClick(ctg)}
            />
          );
        })}
      </S.Grid>
    </S.Container>
  );
};

import Coupe from '@assets/images/shared/category/coupe.png';
import SuvOpen from '@assets/images/shared/category/suv_open.png';
import Roadster from '@assets/images/shared/category/roadster.png';
import Minivan from '@assets/images/shared/category/minivan.png';
import Cabriolet from '@assets/images/shared/category/cabriolet.png';
import Phaeton from '@assets/images/shared/category/phaeton.png';
import Sedan from '@assets/images/shared/category/sedan.png';
import CompactVan from '@assets/images/shared/category/compact_van.png';
import Liftback from '@assets/images/shared/category/liftback.png';
import Hatchback5Doors from '@assets/images/shared/category/hatchback_5_doors.png';
import Hatchback4Doors from '@assets/images/shared/category/hatchback_4_doors.png';
import Hatchback3Doors from '@assets/images/shared/category/hatchback_3_doors.png';
import Pickup from '@assets/images/shared/category/pickup.png';
import PickupSingleCab from '@assets/images/shared/category/pickup_single_cab.png';
import PickupDoubleCab from '@assets/images/shared/category/pickup_double_cab.png';
import Suv3Doors from '@assets/images/shared/category/suv_3_doors.png';
import Suv5Doors from '@assets/images/shared/category/suv_5_doors.png';
import Targa from '@assets/images/shared/category/targa.png';
import Microvan from '@assets/images/shared/category/microvan.png';
import Estate3Doors from '@assets/images/shared/category/estate_3_doors.png';
import Estate5Doors from '@assets/images/shared/category/estate_5_doors.png';
import Speedster from '@assets/images/shared/category/speedster.png';
import Limousine from '@assets/images/shared/category/limousine.png';
import Van from '@assets/images/shared/category/van.png';
import Fastback from '@assets/images/shared/category/fastback.png';

export const icons = {
  coupe: Coupe,
  suv_open: SuvOpen,
  roadster: Roadster,
  minivan: Minivan,
  cabriolet: Cabriolet,
  phaeton: Phaeton,
  sedan: Sedan,
  compact_van: CompactVan,
  liftback: Liftback,
  hatchback_5_doors: Hatchback5Doors,
  hatchback_4_doors: Hatchback4Doors,
  hatchback_3_doors: Hatchback3Doors,
  pickup: Pickup,
  pickup_single_cab: PickupSingleCab,
  pickup_double_cab: PickupDoubleCab,
  suv_3_doors: Suv3Doors,
  suv_5_doors: Suv5Doors,
  targa: Targa,
  microvan: Microvan,
  estate_3_doors: Estate3Doors,
  estate_5_doors: Estate5Doors,
  speedster: Speedster,
  limousine: Limousine,
  van: Van,
  fastback: Fastback
};

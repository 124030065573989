import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as classNames from 'classnames';
import { object } from 'prop-types';
import { PersonalCabinetContext } from '../../context';

export const Tab = ({ tab }) => {
  const { t } = useTranslation();
  const { setCurrentTab, currentTab } = useContext(PersonalCabinetContext);
  const getClasses = useCallback(tabName => classNames(
    'profile-items_filters-i',
    { active: currentTab.name === tabName }
  ), [currentTab]);

  const handleClickOnTab = useCallback(() => setCurrentTab(tab), [setCurrentTab, tab]);

  return (
    <Link to={tab.path} onClick={handleClickOnTab} className={getClasses(tab.name)}>
      {t(tab.name)}
    </Link>
  );
};

Tab.propTypes = { tab: object.isRequired };

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.grey[75]};
`;

export const Section = styled.div<{ isTransparent?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isTransparent }) => (isTransparent ? '0 15px' : '15px')};
  background-color: ${({ theme, isTransparent }) =>
    isTransparent ? 'transparent' : theme.color.white};

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:last-child {
    padding-bottom: 45px;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 400;
  padding: 0;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const DimensionsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SpecTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const SpecTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  row-gap: 10px;
`;

export const SpecName = styled.p`
  font-size: 15px;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

export const SpecValue = styled.p`
  font-size: 15px;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

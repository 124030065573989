import { array } from 'prop-types';
import { format as formatFunction, parseISO } from 'date-fns';
import { TimeFormat } from '../types';

export const convertArrayToObject = arr => arr.reduce((acc, curr) => ({
  ...acc, [curr]: curr
}), {});

convertArrayToObject.propTypes = { arr: array.isRequired };

export const doFormatDate = ({
  date,
  format = TimeFormat.date,
  options = {}
}) => (date ? formatFunction(parseISO(date), format, options) : null);

export const getObjCopy = obj => (obj ? JSON.parse(JSON.stringify(obj)) : {});

export const numberWithFractionDigits = (
  num,
  options,
  locale = 'ru-RU',
  defaultValue = '0,00'
) => (
  num
    ? new Intl.NumberFormat(locale, options || { minimumFractionDigits: 2 }).format(num)
    : defaultValue
);

export const numberWithCurrency = (
  num,
  currency = 'AZN'
) => `${numberWithFractionDigits(num)} ${currency}`;

export const getToken = () => document.head.querySelector(
  'meta[name="csrf-token"]'
)?.content;

export const convertToLitres = engineVolume => {
  const volume = parseInt(engineVolume, 10) / 1000;
  return volume >= 10 ? Math.round(volume) : Math.round(volume * 10) / 10;
};

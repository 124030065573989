import React, { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FAQContext } from '../../context';
import { HeaderDesktop, HeaderMobile, BackButton } from './styled';

export const Header = () => {
  const { t } = useTranslation();
  const { isMobile, countOfSteps } = useContext(FAQContext);

  const goToBack = useCallback(() => window.history.go(countOfSteps), [countOfSteps]);

  return isMobile ? (
    <HeaderMobile>
      <BackButton onClick={goToBack} className="header__nav-btn--back" />
      <h1>{t('help')}</h1>
    </HeaderMobile>
  ) : (
    <HeaderDesktop>
      <h1>{t('help')}</h1>
    </HeaderDesktop>
  );
};

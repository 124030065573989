import styled from 'styled-components';

export const mainTurboColor = '#ca1016';
export const defaultBorder = '1px solid #f1f3f7';
export const darkgrey = '#8d94ad';
export const blue = '#4c88f9';
export const blueMidnight = '#212c3a';
export const white = '#fff';
export const red = '#ca1016';
export const darkRed = '#b80d13';
export const black = '#000';
export const blackBg = '#121212';

export const palette = {
  grey: {
    0: '#f9f9f9',
    50: '#f6f7fa',
    75: '#eaebf2',
    100: '#dfe3e9',
    200: '#8d94ad'
  },
  red: {
    50: '#ffe6e5',
    100: '#ca1016',
    200: '#b80d13'
  },
  blue: {
    100: '#4c88f9',
    200: '#212c3a',
    300: '#151d26'
  }
};

/**
 * Global set of variables for styled-components
 * @param {boolean} isMobileSite
 */
export const globalTheme = isMobileSite => ({
  isMobileSite,
  color: {
    black,
    blueMidnight,
    white,
    darkgrey,
    blue,
    red,
    blackBg,
    darkRed,
    mainTurboColor
  },
  palette,
  fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
  border: defaultBorder,
  maskColor: 'rgba(0, 0, 0, 0.4)'
});

export const FlexCenterAligned = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

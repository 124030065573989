import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorHandler } from '../ErrorHandler';
import { useAxios } from '../../../../hooks/useAxios';
import { getFetchInvoicesPath } from '../../routes';
import { InvoicesAppContext } from '../../context';
import { filterInvoices } from '../../helpers';
import { Table } from './Table';

export const TableWrapper = () => {
  const {
    currentInvoicesTab: { currentFilter },
    setCurrentInvoicesTab,
    invoicesRawData: { currentPage, allInvoices, totalCount },
    setInvoicesRawData,
    hasErrors,
    setHasErrors
  } = useContext(InvoicesAppContext);

  const { t } = useTranslation();

  const onSuccess = response => {
    if (response?.invoices) {
      const allInvoicesNext = [...allInvoices, ...response.invoices];

      setInvoicesRawData({
        allInvoices: allInvoicesNext,
        totalCount: response.count,
        currentPage: currentPage + 1
      });
    }
  };

  useEffect(() => {
    setCurrentInvoicesTab(currentTab => ({
      ...currentTab,
      filteredInvoices: filterInvoices(currentFilter, allInvoices)
    }));
  }, [allInvoices]);

  const {
    loading, callRequest
  } = useAxios({
    method: 'get',
    url: getFetchInvoicesPath(currentPage),
    onSuccess,
    onError: () => setHasErrors(true),
    isInitialCall: true
  });

  if (hasErrors) return <ErrorHandler />;

  if (loading && !allInvoices.length) {
    return (
      <div className="invoices_preloader" style={{ margin: 'auto' }}>Loading ...</div>
    );
  }

  if (allInvoices.length) {
    return (
      <InfiniteScroll
        dataLength={allInvoices.length}
        next={callRequest}
        hasMore={allInvoices.length < totalCount}
      >
        <Table />
      </InfiniteScroll>
    );
  }

  return (
    <div className="invoices_no-invoices">
      <div className="invoices_no-invoices-title">
        {t('noInvoicesTitle')}
      </div>
      <div className="invoices_no-invoices-descr">
        {t('noInvoicesDescr')}
      </div>
    </div>
  );
};

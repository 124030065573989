import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { InvoicesAppContext } from '../../context';
import { getToken, doFormatDate } from '../../../../helpers';
import { PaymentModal } from '../PaymentModal';
import { RoutePath } from '../../constants';
import { PackagePaymentView } from './PackagePaymentView';
import { ErrorHandler } from '../ErrorHandler';
import { useAxios } from '../../../../hooks/useAxios';
import {
  createInvoicePath,
  accountsBalancesPath,
  getTariffsPath,
  getInvoicesByScopesPath
} from '../../routes';
import { TimeFormat } from '../../../../types';

export const PackagePayment = () => {
  const {
    setIsModalVisible,
    isMobileSite,
    hasErrors,
    setHasErrors,
    setSelectedInvoice,
    selectedInvoice,
    setBalanceData,
    balanceValueData
  } = useContext(InvoicesAppContext);

  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const [option, setOption] = useState(30);
  const [isPackagePaymentBlocked, blockPackagePaymentBtn] = useState(false);
  const [packageName, setPackageName] = useState(null);
  const [existingPromotions, setExistingPromotions] = useState(null);
  const [showExistingDiscount, setShowExistingDiscount] = useState(false);
  const [expireAt, setExpireAt] = useState(null);
  const [dateLoader, setDateLoader] = useState(false);

  const name = history.location?.state?.name || packageName?.name;
  const tariffCount = history.location?.state?.paid_ads_count || packageName?.paid_ads_count;
  const pricePeriod = history.location?.state?.pricePeriod || packageName?.price_per_period;
  const promotions = history.location?.state?.promotions || packageName?.promotions;
  const wallet_amount = history.location?.state?.wallet_amount || packageName?.wallet_amount;
  const expired = history.location?.state?.expired || expireAt;
  const showPromotions = history.location?.state?.showPromotions || existingPromotions;
  const discount = history.location?.state?.discount || packageName?.discount;
  const showPaidDiscount = history.location?.state?.showDiscount || showExistingDiscount;
  const additionalServices = history.location?.state?.additionalServices
        || Number(packageName?.additional_services?.banner_ad_impressions_count);
  const rawPricePerPeriod = history.location?.state?.rawPricePerPeriod
        || packageName?.raw_price_per_period;
  const discountsPercents = history.location?.state?.discountsPercents
        || packageName?.promotion?.discounts;
  const displayDiscounts = history.location?.state?.displayDiscounts
        || packageName?.promotion?.display_discounts;

  const promoPricePerPeriod = history.location?.state?.promoPricePerPeriod
        || packageName?.promo_price_per_period;

  const promoPriceDiscounts = history.location?.state?.promoPriceDiscounts
        || packageName?.promo_price?.discounts;

  const promoPriceDisplayDiscounts = history.location?.state?.promoPriceDisplayDiscounts
        || packageName?.promo_price?.display_discounts;

  const expireDateOption = expired && doFormatDate({ date: expired, format: TimeFormat.date });
  const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Baku' }));
  const todayDateOption = format(today, TimeFormat.date);

  const [date, setDate] = useState(todayDateOption);
  const [invoicePrice, setPrice] = useState(pricePeriod);
  const [walletAmount, setWalletAmount] = useState(wallet_amount);
  const [adsCount, setAdsCount] = useState(tariffCount);
  const [impressionsCount, setImpressionsCount] = useState(additionalServices);

  const multiplyPackageData = (counter = 1) => {
    setAdsCount(tariffCount * counter);
    setWalletAmount(wallet_amount * counter);

    setPrice(pricePeriod[option]);
    setImpressionsCount(additionalServices * counter);
  };

  useEffect(() => {
    if (pricePeriod && Object.keys(pricePeriod).length > 0) {
      setPrice(pricePeriod[option]);
    }
  }, [option]);

  const { callRequest: getBalanceValue } = useAxios({
    method: 'get',
    url: accountsBalancesPath,
    isInitialCall: true,
    onSuccess: data => setBalanceData(data?.cash),
    onError: () => setHasErrors(true)
  });

  const { callRequest: tarrifs } = useAxios({
    method: 'get',
    url: getTariffsPath,
    onSuccess: res => {
      const packageUrl = id.charAt(0).toUpperCase() + id.slice(1);
      const resName = res.find(o => o.name === packageUrl);

      if (typeof resName === 'undefined') {
        window.location = RoutePath.packages;
      }

      const fieldExisted = [];

      res?.map(tariff => {
        if (tariff.discount) {
          setShowExistingDiscount(true);
        }
        return ['instagram', 'web'].some(v => tariff.promotions.includes(v) && fieldExisted.indexOf(v) === -1 && fieldExisted.push(v));
      });

      setExistingPromotions(fieldExisted);
      setPackageName(resName);
    },
    onError: error => console.log('Invoice services error', error)
  });

  const { callRequest: activeInvoices } = useAxios({
    method: 'get',
    url: getInvoicesByScopesPath('active'),
    onSuccess: res => {
      const invoicesExpireTime = res?.map(
        element => new Date(element?.deal?.expires_at).getTime()
      );

      const maxInvoicesExpireTime = invoicesExpireTime.length > 0
        && invoicesExpireTime?.filter(value => Number.isFinite(value))
          .reduce((a, b) => Math.max(a, b), []);

      if (maxInvoicesExpireTime) {
        setExpireAt(new Date(maxInvoicesExpireTime)?.toISOString());
      }
      setDateLoader(false);
    },
    onError: () => setHasErrors(true)
  });

  useEffect(() => {
    if (!history.location?.state) {
      tarrifs();
      activeInvoices();
      setDateLoader(true);
    }
  }, [history.location]);

  useEffect(() => {
    if (!history.location?.state) {
      setAdsCount(packageName?.paid_ads_count);
      setWalletAmount(packageName?.wallet_amount);
      setPrice(packageName?.price_per_period[option]);
      setImpressionsCount(packageName?.additional_services?.banner_ad_impressions_count);
    }
  }, [packageName]);

  if (!isMobileSite && location.pathname.indexOf('packages-payment') !== -1) {
    document.querySelector('.profile-navbar')?.classList.add('hidden');
  }

  window.addEventListener('popstate', () => {
    if (!isMobileSite && location.pathname.indexOf('packages-payment') !== -1) {
      document.querySelector('.profile-navbar')?.classList.remove('hidden');
    }
    setIsModalVisible(false);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!balanceValueData) return getBalanceValue();
  }, []);

  const dateOnRequest = date === expireDateOption
    ? 'max_expires_date'
    : 'today';

  const { callRequest: createInvoice } = useAxios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'X-Csrf-Token': getToken()
    },
    body: JSON.stringify({
      starts_at_constant: dateOnRequest,
      period: option,
      tariff_plan_title: name
    }),
    url: createInvoicePath,
    onSuccess: data => {
      setSelectedInvoice(data);
    },
    onError: () => setHasErrors(true)
  });

  const handlePaymentClick = () => {
    blockPackagePaymentBtn(true);
    createInvoice();
  };

  useEffect(() => {
    if (selectedInvoice?.services?.tariff_plan_title === name
      && isPackagePaymentBlocked) {
      setIsModalVisible(true);
      blockPackagePaymentBtn(false);
    }
  }, [selectedInvoice]);

  if (hasErrors) return <ErrorHandler />;

  const rawPricePerPeriodKeys = rawPricePerPeriod && Object.keys(rawPricePerPeriod);

  return (
    <>
      <div className="package-payment">
        <PackagePaymentView {
          ...{
            option,
            setOption,
            name,
            promotions,
            expired,
            expireDateOption,
            todayDateOption,
            date,
            setDate,
            invoicePrice,
            multiplyPackageData,
            walletAmount,
            adsCount,
            isPackagePaymentBlocked,
            handlePaymentClick,
            showPromotions,
            discount,
            showPaidDiscount,
            dateLoader,
            impressionsCount,
            rawPricePerPeriod,
            discountsPercents,
            displayDiscounts,
            rawPricePerPeriodKeys,
            promoPricePerPeriod,
            promoPriceDiscounts,
            promoPriceDisplayDiscounts
          }
        }
        />
      </div>
      <PaymentModal />
    </>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[50]};
`;

export const Header = styled.div`
  height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[75]};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 970px;
  margin: 0 auto;
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
`;

export const MakesList = styled.div`
  display: grid;
  width: 100%;
  gap: 15px;
  padding: 20px 0 15px;
  grid-template-columns: repeat(5, 1fr);
`;

export const MakeLogo = styled.img`
  max-width: 30px;
  width: 100%;
`;

export const MakeLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
`;

export const MakeName = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  transition: color 0.2s ease-in-out;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const MakeItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  cursor: pointer;

  &:hover {
    ${MakeName} {
      color: ${({ theme }) => theme.palette.blue[100]};
    }
  }
`;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as S from './DropdownSelect.styles';
import { ArrowIcon, DropdownList, DropdownOption } from './components';

interface DropdownSelectProps {
  label: string;
  isGrouped?: boolean;
  isCategorized?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isSearchable?: boolean;
  isMultiSelect?: boolean;
  isHighlighted?: boolean;
  options: Array<DropdownOption>;
  value: DropdownOption | DropdownOption[] | null;
  onChange: (value: DropdownOption | DropdownOption[] | null) => void;
}

export const DropdownSelect: React.FC<DropdownSelectProps> = ({
  label,
  value,
  options,
  onChange,
  placeholder,
  isGrouped = false,
  isDisabled = false,
  isSearchable = false,
  isMultiSelect = false,
  isHighlighted = false,
  isCategorized = false
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentVal, setCurrentVal] = useState<string>('');
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<DropdownOption[]>([]);

  const hasSelected = selected.length > 0;
  const isNotEmpty = currentVal.length > 0;
  const selectedValues = selected.map(item => item.label).join(', ');
  const placeholderValue = hasSelected ? selectedValues : placeholder || label;

  useEffect(() => {
    setSelected(value ? (Array.isArray(value) ? [...value] : [value]) : []);
  }, [value]);

  useEffect(() => {
    inputVisible ? inputRef.current?.focus() : setCurrentVal('');
  }, [inputVisible]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;

      if (dropdownRef.current?.contains(target)) return;
      if (!containerRef.current?.contains(target)) setInputVisible(false);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredOptions = useMemo(() => {
    if (!currentVal.length) return options;

    const filterRegex = new RegExp('^' + currentVal, 'i');

    return options.filter(option => filterRegex.test(option.label.trim()));
  }, [currentVal, options]);

  const onContainerClick = () => {
    setInputVisible(isVisible => !isVisible);
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentVal(event.target.value);
  };

  const onReset = () => {
    setSelected([]);
    setInputVisible(false);
    onChange(isMultiSelect ? [] : null);
  };

  const onSelect = useCallback(
    (item: DropdownOption) => {
      if (!isMultiSelect) {
        onChange(item);
      } else {
        const updated = selected.includes(item)
          ? selected.filter(({ value }) => value !== item.value)
          : [...selected, item];

        onChange(updated);
      }

      isMultiSelect || setInputVisible(false);
    },
    [selected, isMultiSelect]
  );

  return (
    <S.Wrapper
      $isGrouped={isGrouped}
      $isDisabled={isDisabled}
      $isOpen={inputVisible || hasSelected || isHighlighted}
    >
      <S.Container ref={containerRef} onClick={onContainerClick}>
        {isSearchable && (
          <S.Input
            ref={inputRef}
            value={currentVal}
            onClick={onInputClick}
            onChange={onInputChange}
            $isHidden={!inputVisible}
          />
        )}

        {inputVisible ? (
          !isNotEmpty && (
            <S.InputPlaceholder $isDark={!isSearchable && hasSelected}>
              {placeholderValue}
            </S.InputPlaceholder>
          )
        ) : (
          <S.InputLabel $isMinimized={hasSelected}>{label}</S.InputLabel>
        )}

        {hasSelected && !inputVisible && <S.InputValue>{selectedValues}</S.InputValue>}

        <ArrowIcon isOpen={inputVisible} />
      </S.Container>

      <div ref={dropdownRef}>
        <DropdownList
          isCategorized={isCategorized}
          isOpen={inputVisible}
          options={filteredOptions}
          selectedOptions={selected}
          isMultiSelect={isMultiSelect}
          onReset={onReset}
          onSelect={onSelect}
        />
      </div>
    </S.Wrapper>
  );
};

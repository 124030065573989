import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px 15px;
`;

export const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const List = styled.div`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  gap: 10px;
`;

export const Header = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Title = styled.p`
  font-size: 16px;
  line-height: normal;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const DateTitle = styled.p`
  font-size: 15px;
  line-height: normal;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

export const CardsGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  scrollbar-width: none;
  padding-right: 15px;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    height: 167px;

    @media screen and (max-width: 480px) {
      min-width: calc((100% - 10px) * 0.5);
      max-width: calc((100% - 10px) * 0.5);
    }

    @media screen and (min-width: 480px) {
      min-width: 167px;
      max-width: 167px;
    }
  }
`;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FlexColumn } from '../../../../../styled';
import CloseImg from '../../../images/close.svg';
import ShareImg from '../../../images/share.svg';

export const CategoryQuestionsContainer = styled(FlexColumn)`
  flex: auto;
  margin-left: 50px;
  min-height: 100px;

  a {
    text-decoration: none;
  }

  ${({ theme: { isMobileSite, color: { white, lightGrey } } }) => isMobileSite && ({
    margin: '20px 0',
    padding: '0 15px',
    border: `solid 1px ${lightGrey}`,
    background: white,
    boxSizing: 'border-box'
  })};
`;

export const Title = styled.div`
  margin: 20px 0;
  font-size: 22px;
  font-weight: 700;
  color: ${({ theme: { color: { blueMidnight } } }) => blueMidnight};
  cursor: default;

  ${({ theme: { isMobileSite } }) => isMobileSite && ({
    fontSize: '20px',
    border: 'none',
    fontWeight: 400
  })};
`;

export const QuestionContainer = styled.div`
  max-width: 595px;
  border-top: ${({ theme: { border } }) => border};
  box-sizing: border-box;
  line-height: 1.33;
  position: relative;
  
  &:last-child {
    border-bottom: ${({ theme: { border, isMobileSite } }) => (isMobileSite ? 0 : border)};
  }

  ${({ theme: { isMobileSite, color: { lightGrey } } }) => isMobileSite && ({
    borderTop: 0,
    borderBottom: `solid 1px ${lightGrey}`,
    maxWidth: 'initial'
  })};
`;

export const Answer = styled.div`
  background-color: #fbfaf3;
  padding: 20px 15px;
  margin-bottom: 10px;
  cursor: default;
  ${({ theme: { color: { blueMidnight, grey, mainTurboColor } } }) => ({
    color: blueMidnight,
    backgroundColor: grey,
    borderLeft: `3px solid ${mainTurboColor}`
  })};

  a {
    color: ${({ theme: { color: { blue } } }) => blue};
  }
  
  li {
    list-style: inherit;
  }

  ${({ theme: { isMobileSite, color: { white } } }) => isMobileSite && ({
    backgroundColor: white,
    padding: '0 0 20px',
    borderLeft: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '15px'
  })};
`;

export const QuestionStyled = styled(Link)`
  display: flex;
  align-items: center;
  padding: 20px 25px 20px 0;
  min-height: 60px;
  color: ${({ theme: { color: { blueMidnight } } }) => blueMidnight};
  font-size: 15px;
  box-sizing: border-box;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${CloseImg});
    transition: all 0.1s linear;
    background-size: cover;
    right: 0;
    top: calc(50% - 8px);

    ${({ $isOpened }) => !$isOpened && ({ transform: 'rotate(-45deg)', right: '2px' })}
  }
  
  ${({ theme: { isMobileSite } }) => isMobileSite && ({ minHeight: 65 })};
`;

export const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  cursor: default;

  .help,
  span {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 3px;
      height: 3px;

      margin: 0 10px;
      background-color: ${({ theme: { color: { darkgrey } } }) => darkgrey};
      border-radius: 50%;
    }
  }

  a {
    color: ${({ theme: { color: { darkgrey } } }) => darkgrey};

    &:hover {
      color: ${({ theme: { color: { mainTurboColor } } }) => mainTurboColor};
    }
  }

  span {
    color: ${({ theme: { color: { blueMidnight } } }) => blueMidnight};
  }
`;

export const ShareButton = styled.span`
  width: 132px;
  height: 40px;
  margin: 15px 0 0 auto;
  font-weight: 400;
  border-radius: 10px;
  background-color: ${({ theme: { color: { grey } } }) => grey};
  color: ${({ theme: { color: { blueMidnight } } }) => blueMidnight};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    background-image: url(${ShareImg});
    background-repeat: no-repeat;
    width: 13px;
    height: 17px;
    margin-right: 10px;
    background-size: cover;
  }
`;

import styled from 'styled-components';
import {
  blueMidnight, defaultBorder, FlexCenterAligned, FlexColumn, white
} from '../../../../styled';

export const PopupContainer = styled(FlexCenterAligned)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const Popup = styled(FlexColumn)`
  width: ${props => props.width};
  border-radius: 7px;
  background-color: ${white};
  cursor: default;
  ${props => props.styles};
`;

export const Header = styled.header`
  height: 72px;
  line-height: 72px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  position: relative;
  border-bottom: ${defaultBorder};
  color: ${blueMidnight};
`;

export const Close = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 15px;
  padding: 30px;
  cursor: pointer;

  &::before, &::after {
    position: absolute;
    content: ' ';
    height: 15px;
    width: 1px;
    background-color: #b7bdd2;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

export const Content = styled.div`
  flex: auto;
`;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { InvoiceInfoMobile } from './InvoiceInfoMobile';
import { InvoiceInfoDesktop } from './InvoiceInfoDesktop';
import { InvoicesAppContext } from '../../context';
import { RoutePath, PaymentOption } from '../../constants';
import { getStatusFormatDate, PaidStatusesMap } from '../../helpers';

export const InvoiceInfo = ({ invoice }) => {
  const { isMobileSite, selectedInvoice } = useContext(InvoicesAppContext);
  const { t } = useTranslation();

  if (!invoice?.id && !selectedInvoice.id) {
    return <Redirect to={{ pathname: RoutePath.invoices }} />;
  }

  const { status_datetime, status, payment_source } = invoice || selectedInvoice;

  const statusDate = getStatusFormatDate(status, status_datetime);

  const payment = payment_source === PaymentOption.wallet
    ? t('wallet')
    : payment_source?.replace(payment_source[0], payment_source[0].toUpperCase());

  const paymentStatus = payment_source
    ? `
    ${t(PaidStatusesMap.paid)}:
     ${statusDate},
     ${payment}
    `
    : null;

  return isMobileSite ? (
    <InvoiceInfoMobile {...{ invoice, paymentStatus }} />
  ) : (
    <InvoiceInfoDesktop {...{ invoice, paymentStatus }} />
  );
};

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InvoicesAppContext } from '../../context';
import { RoutePath } from '../../constants';
import { doFormatDate, numberWithFractionDigits } from '../../../../helpers';
import { TimeFormat } from '../../../../types';

export const PackagesUnpaidBlock = ({ invoice }) => {
  const { t } = useTranslation();

  const {
    services: { tariff_plan_title },
    amount,
    starts_at,
    expires_at
  } = invoice;

  const {
    setCurrentBusinessTab,
    setSelectedInvoice,
    isMobileSite,
    setIsModalVisible
  } = useContext(InvoicesAppContext);

  const handleClick = tab => {
    setCurrentBusinessTab(tab);
    setSelectedInvoice(invoice);
    setIsModalVisible(true);
  };

  return (
    <div className="packages-unpaid">
      <div className="packages-unpaid__text">
        <div className="packages-unpaid__text_notification">
          {t('packages.unpaid')}
        </div>
        <div className="packages-unpaid__text_name">
          {t('packages.unpaid_data', {
            name: tariff_plan_title,
            price: numberWithFractionDigits(amount, { minimumFractionDigits: 0 }),
            starts: doFormatDate({ date: starts_at, format: TimeFormat.date }),
            expires: doFormatDate({ date: expires_at, format: TimeFormat.date })
          })}
        </div>
      </div>
      {!isMobileSite && (
        <Link
          to={{ pathname: RoutePath.invoices }}
          onClick={() => handleClick(RoutePath.invoices)}
          className="packages-unpaid__link"
        >
          {t('pay')}
        </Link>
      )}
      {isMobileSite && (
        <div
          className="packages-unpaid__link"
          onClick={() => handleClick(RoutePath.invoices)}
        >
          {t('pay')}
        </div>
      )}
    </div>
  );
};

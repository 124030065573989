import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useLangUpdate = () => {
  const { search, pathname } = useLocation();

  useLayoutEffect(() => {
    const elements = document.querySelectorAll('.js-lang-switch');
    const { origin, pathname, search } = new URL(window.location.href);
    const baseUrl = `${origin}${pathname}`;
    const isRuLang = baseUrl.includes('//ru.');

    const newUrl = `${baseUrl.replace('//ru.', '//')}${search}`;
    const href = isRuLang ? newUrl : `${baseUrl.replace('//', '//ru.')}${search}`;

    elements.forEach(el => {
      if (el instanceof HTMLAnchorElement) {
        el.href = href;
      }
    });
  }, [search, pathname]);
};

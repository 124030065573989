import React from 'react';
import * as S from './ArrowIcon.styles';

interface ArrowIconProps {
  isOpen: boolean;
}

export const ArrowIcon: React.FC<ArrowIconProps> = ({ isOpen }) => (
  <S.Icon $isOpen={isOpen} xmlns="http://www.w3.org/2000/svg" width="15" height="9">
    <path d="M1.28 0 0 1.312 7.5 9 15 1.312 13.72 0 7.5 6.377z" />
  </S.Icon>
);

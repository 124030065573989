import React from 'react';
import { Operations } from './index';

export const Transactions = () => {
  const getDataSource = data => data.map(t => (
    (t.payment && { ...t.payment, value: t.value }) || ({
      ...t,
      service: t.indication,
      label: 'up',
      hasNoPayment: true
    })
  ));

  return (
    <Operations
      getDataSource={getDataSource}
      dataKey="transactions"
      emptyError={{
        title: 'empty.operations.title',
        message: 'empty.operations.transactions'
      }}
    />
  );
};

import styled from 'styled-components';
import { BodyTypes } from 'packs/autocatalog/types';
import { icons } from './icons';

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  height: 100%;
  min-width: 268px;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  max-width: 244px;
  width: 100%;
  margin-bottom: 28px;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 15px;
  line-height: normal;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const Icon = styled.div<{ $icon?: string }>`
  width: 200px;
  height: 100px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${({ $icon }) => $icon || icons[BodyTypes.estate_5_doors]});
`;

export const LeftSide = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-15px, -50%) rotate(-90deg);
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RotatedSideText = styled.div`
  font-size: 12px;
  height: 100%;
  justify-content: center;
  display: flex;
  user-select: none;

  p {
    white-space: nowrap;
  }
`;

export const RightSide = styled.div`
  position: absolute;
  bottom: 10px;
  left: calc(100% - 15px);
  font-size: 12px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  p {
    white-space: nowrap;
  }
`;

export const BottomSide = styled.div`
  position: absolute;
  bottom: -20px;
  transform: translateX(50%);
  right: 50%;
  text-align: center;
  font-size: 12px;
  user-select: none;
`;

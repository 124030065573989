import styled from 'styled-components';
import {
  FlexCenterAligned, FlexColumn
} from '../../../../styled';
import { MediumText } from '../styled';

export const ModalWindowContainer = styled(FlexCenterAligned)`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  top: env(safe-area-inset-top);
  left: 0;
  right: 0;
  z-index: 999;
  height: 100%;
  overflow: hidden;
  background-color: ${({ theme: { maskColor } }) => maskColor};
`;

export const ModalWindow = styled(FlexColumn)`
  min-width: ${({ width }) => width};
  width: ${props => props.width};
  cursor: default;
  position: relative;
  box-sizing: border-box;
  ${({ theme: { borderRadius, color: { white, blueMidnight }, fontFamily }, styles }) => ({
    borderRadius,
    backgroundColor: white,
    fontFamily,
    color: blueMidnight,
    ...styles
  })};
`;

export const Header = styled(MediumText)`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  ${({ theme: { border }, styles }) => ({ ...styles, borderBottom: border })};
  ${({ theme: { isMobileSite } }) => (isMobileSite
    ? ({ padding: '20px 15px', justifyContent: 'center' })
    : ({ padding: '25px 30px' }))};
`;

export const Close = styled.span`
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  width: 15px;
  height: 15px;
  ${({ theme: { isMobileSite } }) => (isMobileSite
    ? ({ padding: '24px 20px', left: 0 })
    : ({ padding: '28px 22px' }))};

  &::before, &::after {
    position: absolute;
    content: ' ';
    background-color: #b7bdd2;
    width: 2px;
    height: 15px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

export const Content = styled.div`
  flex: auto;
  overflow: hidden auto;
  ${({ styles }) => ({ ...styles })};
`;

export const BlueBtn = styled.div`
  ${({ theme: { border, color: { blue } } }) => ({
    borderRight: border,
    color: blue
  })};
`;

export const Message = styled.div`
  font-size: 16px;
  text-align: center;
  padding: 20px;
  ${({ theme: { color: { darkgrey } } }) => ({ color: darkgrey })};
`;

export const Footer = styled.footer`
  display: flex;
  flex: 1 1;
  font-size: 16px;
  cursor: pointer;
  height: 63px;
  line-height: 63px;
  border-top: ${({ theme: { border } }) => border};
  
  div {
    text-align: center;
    flex: auto;
  }
`;

export const PopupHeader = styled.span`
  width: 100%;
  text-align: center;
  ${({ theme: { isMobileSite } }) => (isMobileSite) && ({ fontWeight: 'bold' })}
`;

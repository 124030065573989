import React from 'react';
import az from './locales/az.json';
import ru from './locales/ru.json';
import { ContextWrapper } from './context/ContextWrapper';
import { DeleteAccount } from './components';

const DeleteAccountApp = ({ dataset }) => (
  <ContextWrapper dataset={dataset}>
    <DeleteAccount />
  </ContextWrapper>
);

export default { App: DeleteAccountApp, az, ru };

import styled from 'styled-components';
import PlaceholderPng from '@assets/images/application/placeholder.png';

export const EmptySection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin: 5px 0 15px;
`;

export const EmptyPlaceholder = styled.div`
  width: 171px;
  height: 200px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${PlaceholderPng});
`;

export const EmptyTitle = styled.p`
  font-size: 18px;
  margin: 20px 0;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const EmptyText = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

import React from 'react';
import { TFunction } from 'react-i18next';
import { Actions, ItemTabName } from '../Item/types';
import { setDataStatAttrForProfileTabsActions } from '../Item/data-stat';

export type Services = 'bump' | 'featured' | 'vip';
const services: Services[] = ['bump', 'vip', 'featured'];

interface ServiceI {
  service: Services;
  t: TFunction;
  itemId: number;
  tabName: ItemTabName;
}

const Service:React.FC<ServiceI> = ({
  service, t, itemId, tabName
}) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    className={`card-services__i card-services__i--${service} js-card-service`}
    data-service={service}
    data-item-id={itemId}
    {...setDataStatAttrForProfileTabsActions(Actions[service], tabName)}
    href="#"
  >
    {t(`services.${service}`)}
  </a>
);

export const CardServices: React.FC<{
  t: TFunction, itemId: number, tabName: ItemTabName
}> = ({ t, itemId, tabName }) => (
  <div className="card-services js-card-services">
    {services.map(service => (
      <Service
        {...{
          service, t, itemId, tabName
        }}
        key={service}
      />
    ))}
  </div>
);

import React, {
  useContext, useRef, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import * as classNames from 'classnames';
import { Package } from './PackageBlock';
import { PackageActive } from './PackageActive';
import { useAxios } from '../../../../hooks/useAxios';
import { Balance } from '../Balance';
import { getTariffsPath, getInvoicesByScopesPath } from '../../routes';
import { PaymentModal } from '../PaymentModal';
import { Invoices } from '../Invoices';
import { InvoicesAppContext } from '../../context';
import { useOnScreen } from '../../../../hooks/useOnScreen';
import { PackagesUnpaid } from './PackagesUnpaid';

export const Packages = () => {
  const { t } = useTranslation();
  const {
    isMobileSite, setHasErrors, setPackageTariffs
  } = useContext(InvoicesAppContext);
  const ref = useRef();
  const [showPromotions, setShowPromotions] = useState([]);
  const [showDiscount, setShowDiscount] = useState(false);
  const isVisible = useOnScreen(ref);

  const { response: tariffs } = useAxios({
    method: 'get',
    url: getTariffsPath,
    isInitialCall: true,
    onError: () => setHasErrors(true)
  });

  const { response: responseInvoices } = useAxios({
    method: 'get',
    url: getInvoicesByScopesPath('active'),
    isInitialCall: true,
    onError: () => setHasErrors(true)
  });

  const showPackagesfields = () => {
    const fieldExisted = [];

    tariffs?.map(tariff => {
      if (tariff.discount) {
        setShowDiscount(true);
      }
      return ['instagram', 'web'].some(v => tariff.promotions.includes(v) && fieldExisted.indexOf(v) === -1 && fieldExisted.push(v));
    });

    return fieldExisted;
  };

  useEffect(() => {
    setPackageTariffs(tariffs);
    setShowPromotions(showPackagesfields());
  }, [isVisible, tariffs]);

  const invoicesExpireTime = responseInvoices?.map(
    element => new Date(element?.deal?.expires_at).getTime()
  );

  return (
    <>
      {isMobileSite && <PackagesUnpaid />}
      <div className="packages">
        <div className="packages-active">
          <p>{t('packages.title')}</p>
          { responseInvoices?.length ? responseInvoices?.map(invoice => (
            <PackageActive
              key={invoice.id}
              invoice={invoice}
              showPromotions={showPromotions}
              showDiscount={showDiscount}
            />
          )) : (
            <div className="packages-empty">
              {t('packages.no_packages')}
            </div>
          )}
        </div>
        {
          tariffs?.length > 0 && (
            <div className="packages-list">
              <p>{t('packages.new')}</p>
              <div className={classNames(
                'packages-list__items',
                { 'packages-list__items_width': tariffs?.length <= 3 }
              )}
              >
                {tariffs.map(tariff => (
                  <Package
                    key={tariff.name}
                    name={tariff.name}
                    price={tariff.price}
                    pricePeriod={tariff.price_per_period}
                    rawPricePerPeriod={tariff.raw_price_per_period}
                    promoPricePerPeriod={tariff.promo_price_per_period}
                    promoPriceDiscounts={tariff.promo_price?.discounts}
                    promoPriceDisplayDiscounts={tariff.promo_price?.display_discounts}
                    discountsPercents={tariff.promotion?.discounts}
                    additionalServices={tariff?.additional_services?.banner_ad_impressions_count}
                    displayDiscounts={tariff.promotion?.display_discounts}
                    count={tariff.paid_ads_count}
                    promotions={tariff.promotions}
                    showPromotions={showPromotions}
                    wallet_amount={tariff.wallet_amount}
                    expired={invoicesExpireTime}
                    discount={tariff.discount}
                    showDiscount={showDiscount}
                  />
                ))}
              </div>
            </div>
          )
        }
      </div>
      { isMobileSite && (
        <>
          <Balance />
          <Invoices />
          <PaymentModal />
        </>
      )}
    </>
  );
};

import React from 'react';
import { StyledButton } from './styled';

export const ToggleButton: React.FC<{
  isActive: boolean;
  onChange: (isActive: boolean) => void;
}> = ({ isActive, onChange, children }) => {
  return (
    <StyledButton isActive={isActive} onClick={() => onChange(!isActive)}>
      {children}
    </StyledButton>
  );
};

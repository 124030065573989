import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '../styled';
import { doFormatDate } from '../../../../../helpers';
import { TimeFormat } from '../../../../../types';
import { ActionButton } from '../ActionButton';
import { Operations } from './index';

export const PaidAdsTransactions = () => {
  const { t } = useTranslation();

  const paidAdsTransactionsColumns = [{
    dataIndex: 'created_at',
    title: t('date_and_time'),
    render: created_at => doFormatDate({
      date: created_at,
      format: TimeFormat.fullTime
    })
  }, {
    dataIndex: 'value',
    title: t('count'),
    render: value => (value < 0 ? value : `+${value}`)
  }, {
    dataIndex: 'title',
    title: t('adTitle'),
    render: (title, row) => (
      row.ad_id ? (<Link href={`/autos/${row.ad_id}`}>{title}</Link>) : title
    )
  }, {
    dataIndex: 'service',
    title: t('service'),
    render: (service, row) => row.payment?.service || service
  }, {
    dataIndex: 'action',
    title: '',
    render: (_, row) => row.payment && <ActionButton row={row} />
  }];

  return (
    <Operations
      dataKey="paid_ads_transactions"
      columns={paidAdsTransactionsColumns}
      emptyError={{
        title: 'empty.paidAdsTransactions.title',
        message: 'empty.paidAdsTransactions.message'
      }}
    />
  );
};

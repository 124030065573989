import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import az from './locales/az.json';
import ru from './locales/ru.json';
import { useAxios } from '../../../hooks/useAxios';
import { Item } from '../../../components/Item';
import { ItemI, ItemTabName } from '../../../components/Item/types';
import { Header } from '../../../hooks/useAxios/types';
import { Loader } from '../../../components/Loader';
import { EmptyTab } from './components/EmptyTab';
import { Error } from './components/Error';

type BooleanInString = 'true' | 'false';

interface AllItemsProps {
  dataset: {
    behavior: string;
    isMobile: BooleanInString;
    tabName: ItemTabName;
  }
}

const Items: React.FC<AllItemsProps> = ({ dataset }) => {
  const { t } = useTranslation();
  const isMobile = dataset.isMobile === 'true';
  const [page, setPage] = useState(1);
  const [lastItemId, setLastItemId] = useState<number | null>(null);
  const [ads, setAds] = useState<ItemI[]>([]);

  const { loading, hasError, callRequest } = useAxios<{ ads: ItemI[] }, null, Header, unknown>({
    method: 'get',
    url: `/api/v2/profile/ads/${dataset.tabName}?page=${page}`,
    onSuccess: res => {
      const { length } = res.ads;
      if (length) {
        setAds(currAds => ([...currAds, ...res.ads]));
        setLastItemId(res.ads[length - 1].id);
      } else {
        setLastItemId(null);
      }
    }
  });

  useEffect(callRequest, [page]);

  if (loading && !ads.length) {
    return (
      <div style={{ height: '151px' }}>
        <Loader size={isMobile ? '20px' : '25px'} />
      </div>
    );
  }
  const refetchData = () => window.location.reload();
  if (hasError) return <Error {...{ refetchData, isMobile }} />;

  if (!loading && !ads.length) {
    return isMobile ? <span /> : <EmptyTab tabName={dataset.tabName} />;
  }

  return (
    <div className="products">
      {ads.map(item => (
        <Item
          key={item.id}
          {...{
            item, t, isMobile, setPage, lastItemId, tabName: dataset.tabName
          }}
        />
      ))}
      {loading && !!ads.length && <Loader />}
    </div>
  );
};

export const ItemsApp = { App: Items, az, ru };

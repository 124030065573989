import styled, { css } from 'styled-components';

const ActiveButton = css`
  user-select: none;
  cursor: default;
  color: ${({ theme }) => theme.palette.red[100]};
  border: 1px solid ${({ theme }) => theme.palette.red[100]};
  background-color: ${({ theme }) => theme.palette.red[50]};
  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.red[100]};
  }
`;

export const StyledButton = styled.button<{ isActive: boolean }>`
  box-shadow: none;
  outline: none;
  border-radius: 7px;
  font-size: 15px;
  cursor: pointer;
  padding: 9px 10px;
  color: ${({ theme }) => theme.palette.blue[200]};
  font-weight: 400;
  line-height: normal;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  transition: border-color 0.15s ease-in-out;
  background-color: ${({ theme }) => theme.color.white};

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }

  ${({ isActive }) => (isActive ? ActiveButton : '')}
`;

import React, { useState } from 'react';
import cn from 'classnames';
import * as S from './TabSwitcher.styles';

interface TabSwitcherProps {
  defaultTab: string;
  isFullWidth?: boolean;
  isDark?: boolean;
  tabs: { [key: string]: string };
  onTabChange: (tab: string) => void;
}

export const TabSwitcher = ({
  tabs,
  defaultTab,
  onTabChange,
  isDark = false,
  isFullWidth = false
}: TabSwitcherProps) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const onClick = (tab: string) => {
    setActiveTab(tab);
    onTabChange(tab);
  };

  return (
    <S.Container $isFullWidth={isFullWidth}>
      {Object.entries(tabs).map(([tabKey, tabTitle]) => (
        <S.SwitcherButton
          key={tabKey}
          type="button"
          $isDark={isDark}
          className={cn({ active: tabKey === activeTab })}
          onClick={() => onClick(tabKey)}
        >
          {tabTitle}
        </S.SwitcherButton>
      ))}
    </S.Container>
  );
};

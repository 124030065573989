import { useMemo } from 'react';
import useSWR from 'swr';
import { fetcher } from './api';
import { apiPaths } from '../constants';
import { formatFilterOptions } from '../utils';

export const useFilterOptions = () => {
  const { data, isLoading, error, mutate } = useSWR(apiPaths.properties, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false
  });

  const formattedData = useMemo(() => formatFilterOptions(data?.data ?? {}), [data]);

  return {
    mutate,
    data: formattedData,
    isLoading,
    isError: error
  };
};

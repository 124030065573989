import React, {
  useContext, useState, useCallback
} from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { InvoicesAppContext } from '../../context';
import { mobStyles, deskStyles } from './PackagesModalStyles';
import { getInvoicesPaymentPath } from '../../routes';
import { PaymentOption } from '../../constants';
import {
  openNewPaymentWindow,
  paymentData
} from '../../helpers';
import { numberWithCurrency } from '../../../../helpers';
import { PaymentButton } from '../PaymentButton';

export const PaymentModal = () => {
  const { t } = useTranslation();
  const {
    bankCardProvider,
    selectedInvoice,
    isModalVisible,
    setIsModalVisible,
    balanceValueData,
    setHasErrors,
    isMobileSite
  } = useContext(InvoicesAppContext);

  const [selectedOption, setSelectedOption] = useState(bankCardProvider);
  const [blockBtnOnRequest, setBlockBtnOnRequest] = useState(false);
  const [dataUrl, setDataUrl] = useState(null);

  const { portmanat, wallet, cash } = PaymentOption;
  const { headers, body } = paymentData(selectedOption);

  Modal.setAppElement(document.querySelector('[data-behavior="react"]'));

  const onClose = () => {
    setIsModalVisible(false);
  };

  const initializePayment = async () => {
    const path = getInvoicesPaymentPath(selectedInvoice.id);

    try {
      const res = await fetch(path, {
        method: 'POST',
        headers,
        body
      });

      const data = await res.json();

      if (data.url) {
        setDataUrl(data);
      } else {
        openNewPaymentWindow(data);
      }

      return data;
    } catch (e) {
      setHasErrors(e);
      onClose();
    }
  };

  const isBalanceEnough = Number(balanceValueData) >= Number(selectedInvoice.amount);

  const blockPaymentBtn = selectedOption === wallet ? !isBalanceEnough : null;

  const handlePaymentClick = useCallback((res, paymentUrl, selectedPayment, openedWin) => {
    if (res?.status === 'unpaid') {
      if (selectedPayment === 'wallet') {
        setBlockBtnOnRequest(true);
        initializePayment(selectedPayment);
      } else if (selectedPayment === 'terminal') {
        openKioskModal();
      } else {
        openNewPaymentWindow(paymentUrl, openedWin);
      }
    }
  },
  [selectedInvoice?.id, selectedOption, dataUrl]);

  const modalStyles = isMobileSite ? mobStyles : deskStyles;

  const description = () => (selectedInvoice?.services?.discount ? 'descriptionDiscount' : 'description');

  const digitalPayType = window.Detector.ios ? 'apple' : 'google';

  return (
    <Modal isOpen={isModalVisible} onRequestClose={onClose} style={modalStyles}>
      <header className="package-payment_modal_header">
        <p className="package-payment_modal_title">
          {t('payment.modal.title', { name: selectedInvoice?.services?.tariff_plan_title })}
        </p>
        <button
          type="button"
          onClick={onClose}
          className="package-payment_modal_close-btn"
        />
      </header>
      <div className="package-payment_modal_description">
        <p>
          {t(`payment.modal.${description()}`, {
            count: selectedInvoice?.services?.paid_ads_count,
            sum: selectedInvoice?.services?.wallet_amount,
            discount: selectedInvoice?.services?.discount
          })}
        </p>
      </div>
      <div className="package-payment_modal_source">
        <p>
          {t('payment.source')}
        </p>
      </div>
      <section className="package-payment_modal_body">
        <ul>
          <li className="package-payment_modal_option">
            <input
              id={bankCardProvider}
              name="payment-option"
              type="radio"
              value={bankCardProvider}
              checked={selectedOption === bankCardProvider}
              onChange={() => setSelectedOption(bankCardProvider)}
            />
            <label htmlFor={bankCardProvider}>
              {t('modal.card')}
              {isMobileSite && bankCardProvider === 'yigim' && (
                <span className={`digital-payment-provider digital-payment-provider--${digitalPayType}-pay`} />
              )}
            </label>
          </li>
          <li className="package-payment_modal_option">
            <input
              id={portmanat}
              name="payment-option"
              type="radio"
              value={portmanat}
              checked={selectedOption === portmanat}
              onChange={() => setSelectedOption(portmanat)}
            />
            <label htmlFor={portmanat}>{t('modal.portmanat')}</label>
          </li>
          <li className="package-payment_modal_option">
            <input
              id={cash}
              name="payment-option"
              type="radio"
              value={cash}
              checked={selectedOption === wallet}
              onChange={() => setSelectedOption(wallet)}
            />
            <label htmlFor={cash}>
              {t('modal.wallet', { sum: numberWithCurrency(balanceValueData) })}
            </label>
          </li>
        </ul>
      </section>
      <footer className="package-payment_modal_footer">
        <PaymentButton
          disabled={blockBtnOnRequest || blockPaymentBtn}
          checkUnpaidStatus={handlePaymentClick}
          buttonClassName="invoices_payment-modal-button"
          invoiceId={selectedInvoice?.id}
          initializePayment={initializePayment}
          selectedPayment={selectedOption}
        />
        <p className="package-payment_modal_confirm-rules">
          <span>{t('modal.confirmRules1')}</span>
          <a href="/pages/terms-and-conditions">{t('modal.confirmRules2')}</a>
          <span>{t('modal.confirmRules4')}</span>
          <a href="/pages/proposal">{t('modal.confirmRules5')}</a>
          <span>{t('modal.confirmRules6')}</span>
        </p>
      </footer>
    </Modal>
  );
};

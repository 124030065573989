import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './SpecSummary.styles';

import FuelPng from './images/fuel.png';
import EnginePng from './images/engine.png';
import CapacitylPng from './images/capacity.png';
import GearboxPng from './images/gearbox.png';

interface SpecSummaryProps {
  fields: Array<{
    label: string;
    value: string | number | string[] | number[];
    field?: string;
  }>;
}

export const SpecSummary: React.FC<SpecSummaryProps> = ({ fields = [] }) => {
  const { t } = useTranslation();
  const icons: { [key: string]: string } = {
    power: EnginePng,
    fuel: FuelPng,
    transmission: GearboxPng,
    engine_capacity: CapacitylPng
  };

  return (
    <S.Container>
      {fields.map(item => {
        return (
          <S.Item key={item.label}>
            <div>
              <S.Title>{t(`specsOverview.${item.field}`)}</S.Title>
              <S.Value>{item.value}</S.Value>
            </div>
            <S.SpecIcon src={icons[item?.field ?? '']} />
          </S.Item>
        );
      })}
    </S.Container>
  );
};

import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './styled';
import { PersonalCabinetContext } from '../../../context';

export const ActionButton = ({ row }) => {
  const { t } = useTranslation();
  const { setSelectedRow } = useContext(PersonalCabinetContext);

  const showDetailsAction = useCallback(
    () => setSelectedRow(row),
    [setSelectedRow, row]
  );

  return <Button onClick={showDetailsAction}>{t('action')}</Button>;
};

import styled from 'styled-components';
import { FlexCenterAligned } from '../../../../styled';
import headerImg1 from '../../images/header-img1.svg';
import headerImg2 from '../../images/header-img2.svg';
import headerImg3 from '../../images/header-img3.svg';

export const HeaderDesktop = styled(FlexCenterAligned)`
  height: 130px;
  font-size: 20px;
  background-image: url(${headerImg1}), url(${headerImg2}), url(${headerImg3});
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: top left 57px, top 40px left 331px, top 55px right -30px;
  cursor: default;
  h1 {
    font-size: 20px;
    font-weight: 500;
  }
  ${({ theme: { color: { grey, blueMidnight }, fontFamily } }) => ({
    backgroundColor: grey,
    color: blueMidnight,
    fontFamily
  })}
`;

export const HeaderMobile = styled(FlexCenterAligned)`
  position: relative;
  height: 60px;
  h1 {
    font-size: 16px;
    font-weight: 500;
  }
  ${({ theme: { color: { blueMidnight, white, lightGrey } } }) => ({
    backgroundColor: white,
    color: blueMidnight,
    borderBottom: `1px solid ${lightGrey}`
  })}
`;

export const BackButton = styled.span`
  padding: 20px 15px;
  position: absolute;
  width: 10px;
  height: 17px;
  top: 0;
  left: 0;
`;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { RoutePath } from 'packs/autocatalog/constants';
import { GenerationsResponse } from 'packs/autocatalog/types';
import { ItemCard } from 'packs/autocatalog/components/desktop';
import * as S from './GenerationList.styles';

interface GenerationListProps {
  list: GenerationsResponse;
}

export const GenerationList = ({ list = [] }: GenerationListProps) => {
  const history = useHistory();

  const onClick = (modificationId: number, generationId: number) => {
    const query = new URLSearchParams(history.location.search);

    query.delete('generation');
    history.push(`${RoutePath.model}/${generationId}/${modificationId}/?` + query.toString());
  };

  return (
    <S.Container>
      {list.map(item => {
        return (
          <S.Item key={item.id}>
            <S.Header>
              <S.Title>{item.name}</S.Title>
              <S.DateTitle>{item.years}</S.DateTitle>
            </S.Header>
            <S.CardsGrid>
              {item.categories.map(ctg => {
                return (
                  <ItemCard
                    onClick={() => onClick(ctg.modification.id, item.id)}
                    key={ctg.name}
                    label={ctg.name}
                    image={ctg.modification?.photos?.[0] ?? ctg.icon}
                  />
                );
              })}
            </S.CardsGrid>
          </S.Item>
        );
      })}
    </S.Container>
  );
};

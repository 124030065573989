import React from 'react';
import {
  element, string, object, func
} from 'prop-types';
import {
  PopupContainer, Popup, Header, Content, Close
} from './styled';

export const PopupComponent = ({
  title,
  children,
  onClose,
  popupWidth,
  styles
}) => (
  <PopupContainer onClick={onClose}>
    <Popup width={popupWidth} styles={styles} onClick={e => { e.stopPropagation(); }}>
      <Header>
        {title}
        <Close onClick={onClose} />
      </Header>
      <Content>{children}</Content>
    </Popup>
  </PopupContainer>
);

PopupComponent.propTypes = {
  children: element.isRequired,
  title: string.isRequired,
  onClose: func.isRequired,
  popupWidth: string,
  styles: object
};

PopupComponent.defaultProps = {
  popupWidth: '455px',
  styles: {}
};

import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.palette.grey[50]};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 80px;
`;

export const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  height: 50px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  font-size: 15px;
  border-radius: 7px;
  box-sizing: border-box;
  appearance: none;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.palette.red[100]};
`;

export const Section = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 0 15px 15px 15px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.white};
  border-top: 1px solid ${({ theme }) => theme.palette.grey[75]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[75]};
`;

export const TransparentSection = styled.div`
  display: flex;
  padding: 20px 15px;
  flex-direction: column;
  background: transparent;
`;

export const SelectionsGroup = styled.div`
  border-radius: 7px;
  padding: 0 15px;
  background-color: ${({ theme }) => theme.color.white};

  & > div:last-child {
    border-bottom: none;
  }
`;

export const SectionTitle = styled.p`
  padding: 15px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

export const MakesList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 15px;
  row-gap: 25px;
  margin-bottom: 25px;
`;

export const MakeItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const MakeLogo = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
`;

export const MakeImage = styled.img`
  max-width: 40px;
`;

export const MakeTitle = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 12px;
  font-size: 11px;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

export const ButtonOutlined = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.palette.red[100]};
  border-color: ${({ theme }) => theme.palette.red[100]};
`;

export const SelectorsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
`;

export const ButtonsGrid = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  button {
    user-select: none;
    touch-action: manipulation;
  }

  button:active,
  button:focus {
    outline: none;
    box-shadow: none;
  }
`;

import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { RoutePath } from '../constants';
import { useFilterOptions } from '../api';
import { CatalogMobile, SearchMobile, ModelMobile } from '../pages';

export const AutocatalogMobile = () => {
  useFilterOptions();

  return (
    <BrowserRouter>
      <Route path={RoutePath.autocatalog} exact>
        <CatalogMobile />
      </Route>
      <Route path={RoutePath.search} exact>
        <SearchMobile />
      </Route>
      <Route path={`${RoutePath.model}/:generation/:id`} exact>
        <ModelMobile />
      </Route>
    </BrowserRouter>
  );
};

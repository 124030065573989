import styled from 'styled-components';
import { FlexCenterAligned, FlexColumn } from '../../../../../styled';
import { MediumText } from '../../styled';

export const Container = styled(FlexColumn)`
  ${({ theme: { isMobileSite } }) => !isMobileSite && ({ padding: '20px 30px 30px' })}
`;

export const Title = styled(MediumText)`
  font-size: 20px;
`;

export const Rules = styled(FlexColumn)`
  background-color: #fff;
  ${({ theme: { border, isMobileSite } }) => isMobileSite && ({
    borderBottom: border,
    padding: '20px 15px 15px'
  })}
`;

export const Rule = styled.span`
  font-size: 16px;
  margin-top: 20px;
`;

export const Line = styled.span`
  width: 100%;
  height: 1px;
  margin-top: 20px;
  ${({ theme: { border } }) => ({ borderTop: border })}
`;

export const Button = styled(FlexCenterAligned)`
  font-size: 15px;
  height: 48px;
  margin-top: 20px;
  cursor: pointer;
  
  ${({ theme: { borderRadius, color: { white, pink }, border } }) => ({
    borderRadius,
    backgroundColor: pink,
    color: white,
    borderTop: border
  })}
  
  ${({ theme: { isMobileSite } }) => isMobileSite && ({ margin: '15px' })}
`;

export const Header = styled.span`
  font-size: ${({ theme: { isMobileSite } }) => isMobileSite && '16px'}
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding-left: 15px;
`;

export const List = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  scrollbar-width: none;
  padding-right: 15px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Item = styled.div`
  display: flex;
  min-width: 75px;
  height: 70px;
  padding: 0 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  gap: 5px;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border: 1px solid ${({ theme }) => theme.palette.grey[75]};

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const Image = styled.div`
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 35px;
  }
`;

export const Name = styled.p`
  font-size: 11px;
  padding: 0;
  overflow: hidden;
  max-width: 70px;
  text-overflow: ellipsis;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, RowTitle } from './styled';

export const RowComponent = ({ title, value }) => {
  const { t } = useTranslation();

  return (
    <Row className={title}>
      <RowTitle>{t(title)}</RowTitle>
      <span>{value}</span>
    </Row>
  );
};

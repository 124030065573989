import React from 'react';
import { Make } from 'packs/autocatalog/types';
import { useQueryState } from 'packs/autocatalog/hooks';
import * as S from './PopularMakes.styles';

interface PopularMakesProps {
  list: Make[];
}

export const PopularMakes: React.FC<PopularMakesProps> = ({ list }) => {
  const [query, setQuery] = useQueryState();

  const onSelect = (item: Make) => {
    setQuery({ ...query, make: item.id, model: '', model_group: '' });
  };

  return (
    <S.Container>
      <S.List>
        {list.map(item => {
          return (
            <S.Item key={item.id} onClick={() => onSelect(item)}>
              <S.Image>
                <img src={item.logo_url} alt={item.name} />
              </S.Image>
              <S.Name>{item.name}</S.Name>
            </S.Item>
          );
        })}
      </S.List>
    </S.Container>
  );
};

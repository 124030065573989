import styled, { css } from 'styled-components';
import ZoomSvg from '@assets/images/application/icons-for-sprite/icon-zoom--white.svg';
import ArrowLeftSvg from '@assets/images/application/icons-for-sprite/icon-arrow-left--white.svg';
import ArrowRightSvg from '@assets/images/application/icons-for-sprite/icon-arrow-right--white.svg';

export const CarouselIcon = css`
  position: absolute;
  top: 50%;
  height: 40px;
  width: 40px;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  transform: translateY(-50%);
  transition: opacity 0.2s linear;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Arrow = styled.button<{ $isLeft?: boolean }>`
  ${CarouselIcon};
  background-image: url(${({ $isLeft }) => ($isLeft ? ArrowLeftSvg : ArrowRightSvg)});
  ${({ $isLeft }) => ($isLeft ? 'left: 15px' : 'right: 15px')};
`;

export const ZoomIcon = styled.button`
  ${CarouselIcon};
  right: 10px;
  top: 30px;
  background-size: 14px;
  background-image: url(${ZoomSvg});
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
`;

export const SlideCounter = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  bottom: 10px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 14px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  transition: opacity 0.2s linear;
  color: ${({ theme }) => theme.color.white};
  transform: translateX(-50%);
`;

export const SlidesContainer = styled.div`
  max-width: 640px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    ${SlideCounter},
    ${Arrow},
    ${ZoomIcon} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const Slide = styled.div`
  flex: 1;
  height: 480px;
`;

export const Image = styled.img<{ $isActive: boolean }>`
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-drag: none;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.8)};
  overflow-clip-margin: unset;
`;

export const Thumbs = styled.div`
  display: flex;
  gap: 2px;
  margin-top: 5px;
`;

export const Thumb = styled.div`
  position: relative;
  z-index: 2;
  border-radius: 4px;
  overflow: hidden;
  width: 70px;
  height: 50px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.white};
`;

export const ThumbLabel = styled.div`
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  position: absolute;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  color: ${({ theme }) => theme.color.white};
  background: rgba(0, 0, 0, 0.51);
`;

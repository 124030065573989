import React from 'react';
import { string, array } from 'prop-types';
import { Loader } from '../../../../components/Loader';
import { Pagination } from './Pagination';
import { FIRST_PAGE_NUMBER } from '../constants';

export const Table = ({
  columns,
  dataSource,
  className,
  loading,
  totalPages,
  currentPage,
  setCurrentPage
}) => (
  <div className={className}>
    {
      loading ? <Loader /> : (
        <table>
          <thead>
            <tr>
              {columns.map(({ dataIndex, title }) => (<th key={dataIndex}>{title}</th>))}
            </tr>
          </thead>
          <tbody>
            {dataSource.map(data => (
              <tr key={data.id}>
                {columns.map(({ dataIndex, render }) => (
                  <td key={dataIndex}>
                    {!render ? data[dataIndex] : render(data[dataIndex], data)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )
    }
    {totalPages !== FIRST_PAGE_NUMBER && (
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    )}
  </div>
);

Table.propTypes = {
  columns: array,
  dataSource: array,
  className: string
};

Table.defaultProps = {
  columns: [],
  dataSource: [],
  className: null
};

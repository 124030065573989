import {
  useState, useEffect
} from 'react';

export const useOnScreen = (
  element, offset = -70, throttleMilliseconds = 20
) => {
  const [isVisible, setIsVisible] = useState(false);

  const throttle = func => {
    let lastTime = 0;
    return () => {
      const now = Date.now();
      if (now - lastTime >= throttleMilliseconds) {
        func();
        lastTime = now;
      }
    };
  };

  const onScroll = throttle(() => {
    if (!element.current) {
      setIsVisible(false);
      return;
    }
    const { top } = element.current.getBoundingClientRect();
    setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
  }, throttleMilliseconds);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  });

  return isVisible;
};

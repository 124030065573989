import { useEffect } from 'react';

interface UsePostMessage {
  postMessageName: string;
  handler: () => void;
}
export const usePostMessage = ({ postMessageName, handler }: UsePostMessage): void | null => {
  useEffect(() => {
    const handleEvent = (event: MessageEvent) => event.data === postMessageName && handler();
    window.addEventListener('message', handleEvent);
    return () => window.removeEventListener('message', handleEvent);
  }, []);

  return null;
};

import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileHeader } from 'components/MobileHeader';
import { DropdownOption } from 'components/DropdownSelect';
import {
  Container,
  Overlay,
  SearchBar,
  SearchInput,
  ListContainer,
  ListItem,
  ListItemImage,
  ListItemLabel,
  ListHeader,
  ListHeaderTitle,
  ListHeaderClose,
  ItemRadio,
  ItemCheckbox
} from './styled';

interface DropdownListProps {
  isShort?: boolean;
  isFullscreen?: boolean;
  isSearchable?: boolean;
  isMultiSelect?: boolean;
  headerTitle?: string;
  options: DropdownOption[];
  selectedOptions: DropdownOption[];
  onClose: () => void;
  onSelect: (item: DropdownOption) => void;
  searchTitle?: string;
}

export const DropdownList = ({
  onClose,
  onSelect,
  options = [],
  headerTitle = '',
  selectedOptions = [],
  isShort = false,
  isFullscreen = false,
  isSearchable = false,
  isMultiSelect = false,
  searchTitle = ''
}: DropdownListProps) => {
  const { t } = useTranslation();
  const [currentVal, setCurrentVal] = useState<string>('');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const selectedListItemRef = useRef<HTMLDivElement | null>(null);

  const hasSearch = isSearchable && isFullscreen;

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    if (selectedListItemRef.current) {
      selectedListItemRef.current.scrollIntoView({ block: 'center' });
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (!containerRef.current?.contains(event.target as Node)) {
        onClose();
      }
    };

    if (containerRef.current) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const filteredOptions = useMemo(() => {
    if (!currentVal.length) return options;

    const filterRegex = new RegExp('^' + currentVal, 'i');

    return options.filter(option => filterRegex.test(option.label.trim()));
  }, [currentVal, options]);

  const onListItemClick = (item: DropdownOption) => {
    onSelect(item);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentVal(event.target.value);
  };

  const renderOption = (item: DropdownOption) => {
    const isGroup = !!item?.isGroup;
    const isSection = !!item?.isSection;
    const isCategory = !!item?.isCategory;
    const isSelectable = !isGroup && !isSection;
    const isSelected = selectedOptions.some(
      option => option.isCategory === item.isCategory && option.value === item.value
    );

    return (
      <ListItem
        isGroup={isGroup}
        isSection={isSection}
        isCategory={isCategory}
        key={`${item.value}-${item.label}`}
        onClick={() => isSelectable && onListItemClick(item)}
        ref={isSelected ? selectedListItemRef : null}
      >
        {item?.image && <ListItemImage src={item.image} loading="lazy" />}
        <ListItemLabel>{item.label}</ListItemLabel>
        {isSelectable && !isMultiSelect && <ItemRadio isSelected={isSelected} />}
        {isSelectable && isMultiSelect && <ItemCheckbox $isSelected={isSelected} />}
      </ListItem>
    );
  };

  return (
    <>
      {!isFullscreen && <Overlay />}
      <Container ref={containerRef} isShort={isShort} isFullscreen={isFullscreen}>
        {isFullscreen ? (
          <MobileHeader title={headerTitle} onBackBtnClick={onClose} />
        ) : (
          <ListHeader isShort={isShort}>
            <ListHeaderTitle>{headerTitle}</ListHeaderTitle>
            <ListHeaderClose onClick={onClose} />
          </ListHeader>
        )}

        {hasSearch && (
          <SearchBar>
            <SearchInput
              placeholder={searchTitle || t('advancedSelect.example')}
              onChange={onInputChange}
            />
          </SearchBar>
        )}

        <ListContainer
          isShort={isShort}
          isIos={window.Detector.ios}
          isFullscreen={isFullscreen}
          isSearchable={isSearchable}
        >
          {options.length > 0 && filteredOptions.map(renderOption)}
        </ListContainer>
      </Container>
    </>
  );
};

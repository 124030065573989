import React, { useState } from 'react';
import { element } from 'prop-types';
import { Provider } from './index';
import { globalState } from './globalState';

export const ContextWrapper = ({ children }) => {
  const [currentTab, setCurrentTab] = useState(globalState.currentTab);
  const [selectedRow, setSelectedRow] = useState(globalState.selectedRow);

  const contextValue = {
    currentTab,
    setCurrentTab,
    selectedRow,
    setSelectedRow
  };

  return <Provider value={contextValue}>{ children }</Provider>;
};

ContextWrapper.propTypes = {
  children: element.isRequired
};

import useSWR from 'swr';
import { fetcher } from './api';
import { groupFormatModelOptions } from '../utils';

export const useCatalogModels = (url: string | null, formatter = groupFormatModelOptions) => {
  const { data, isLoading, error, mutate } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false
  });

  return {
    mutate,
    data: formatter(data?.data),
    isLoading,
    isError: error
  };
};

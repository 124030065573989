import styled, { css } from 'styled-components';
import PlaceholderPng from '@assets/images/application/placeholder.png';
import SettingsRedSvg from '@assets/images/mobile/icons-for-sprite/settings.svg';
import CarSvg from '@assets/images/mobile/icons-for-sprite/sports-car--black.svg';
import SettingsSvg from '@assets/images/mobile/icons-for-sprite/settings--black.svg';

const Placeholder = css`
  font-size: 15px;
  transition: all 0.2s;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SectionTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  padding: 0;
  margin: 20px 15px;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const SearchSection = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px 15px;
  flex-direction: column;
`;

export const SearchFields = styled.div`
  border-radius: 7px;
  padding: 0 15px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 4px 21px 2px rgba(0, 0, 0, 0.04);

  &:focus {
    outline: none;
  }

  & > div:last-child {
    border-bottom: none;
  }
`;

export const DefaultPlaceholder = styled.span<{ $isFullHeight?: boolean }>`
  display: flex;
  width: 100%;
  gap: 10px;
  ${Placeholder}
  color: ${({ theme }) => theme.palette.blue[200]};
  height: ${({ $isFullHeight }) => ($isFullHeight ? 'calc(100% + 9px)' : '')};
  align-items: ${({ $isFullHeight }) => ($isFullHeight ? 'flex-start' : 'center')};
`;

export const CarIcon = styled.span`
  display: block;
  width: 22px;
  height: 16px;
  background-image: url(${CarSvg});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const FiltersIcon = styled.span<{ $isActive?: boolean }>`
  display: block;
  width: 18px;
  height: 17px;
  background-repeat: no-repeat;
  background-image: url(${({ $isActive }) => ($isActive ? SettingsRedSvg : SettingsSvg)});
`;

export const FiltersButton = styled.button`
  display: flex;
  align-items: center;
  height: 54px;
  width: 100%;
  background: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
`;

export const MinimizedFiltersButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 20px;
  width: fit-content;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 4px 21px 2px rgba(0, 0, 0, 0.04);
`;

export const FiltersCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  margin-left: -5px;
  border-radius: 50%;
  font-size: 9px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.palette.red[100]};
`;

export const EmptySection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding-bottom: 70px;
`;

export const EmptyPlaceholder = styled.div`
  width: 131px;
  height: 160px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${PlaceholderPng});
`;

export const EmptyTitle = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const EmptyText = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

export const CardsGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  margin: 0 15px;
  padding-bottom: 20px;

  @media screen and (min-width: 480px) {
    width: 521px;
    margin: 0 auto;
  }

  @media screen and (min-width: 729px) {
    width: 698px;
    margin: 0 auto;
  }

  @media screen and (min-width: 906px) {
    width: 875px;
    margin: 0 auto;
  }

  @media screen and (min-width: 1083px) {
    width: 1052px;
    margin: 0 auto;
  }

  & > div {
    height: 167px;

    @media screen and (max-width: 480px) {
      width: calc((100% - 10px) * 0.5);
    }

    @media screen and (min-width: 480px) {
      width: 167px;
    }
  }
`;

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import InvoicesApp from './invoices';
import DeleteAccountApp from './delete-account';
import PersonalCabinetApp from './personal-cabinet';
import { ItemsApp } from './profile/items';
import FrequentlyAskedQuestionsApp from './faq';
import AutocatalogApp from './autocatalog';

import { customizeI18n } from '../utils/i18n';

interface AppElement extends Element {
  dataset: {
    behavior: string;
  }
}

interface MarkupsDispatcherI {
  [id:string]: { App: React.FC<unknown> | unknown, az: object, ru: object }
}

const markupsDispatcher: MarkupsDispatcherI = {
  'invoices-app': InvoicesApp,
  'delete-account-app': DeleteAccountApp,
  'personal-cabinet-app': PersonalCabinetApp,
  'profile-items-app': ItemsApp,
  'frequently-asked-questions-app': FrequentlyAskedQuestionsApp,
  'autocatalog-app': AutocatalogApp
};

document.addEventListener('DOMContentLoaded', () => {
  const elementsToRender: NodeListOf<AppElement> = document.querySelectorAll('[data-behavior=react]');
  elementsToRender.forEach(el => {
    const { App, az, ru } = markupsDispatcher[el.id];
    const i18n = customizeI18n({ az, ru });
    ReactDOM.render(
      // @ts-ignore
      <I18nextProvider {...{ i18n }}><App dataset={el.dataset} /></I18nextProvider>,
      el
    );
  });
});

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCatalogModels, useFilterOptions } from 'packs/autocatalog/api';
import { DropdownOption, MobileHeader, ToggleButton } from 'components';
import { useLangUpdate, useQueryState } from 'packs/autocatalog/hooks';
import { SEATS } from 'packs/autocatalog/components/desktop';
import { FiltersState, Make } from 'packs/autocatalog/types';
import { formatModelOptions, groupMakeOptions, selectInitialModel } from 'packs/autocatalog/utils';
import { DropdownList, DropdownSelect, FilterInput } from 'packs/autocatalog/components/mobile';
import { apiPaths, INITIAL_FILTERS, RoutePath } from 'packs/autocatalog/constants';
import { useAutocatalogContext } from 'packs/autocatalog/context';
import { POPULAR_ITEMS_LIMIT } from './SearchMobile.constants';
import * as S from './SearchMobile.styles';
import { GlobalStyle } from 'packs/autocatalog/styles';

export const SearchMobile = () => {
  useLangUpdate();
  const history = useHistory();
  const { t } = useTranslation();
  const ctx = useAutocatalogContext();
  const [query, setQuery] = useQueryState();

  const [isMakesVisible, setMakesVisible] = useState<boolean>(false);
  const [filters, setFilters] = useState<FiltersState>(INITIAL_FILTERS);

  const popularMakes = ctx.popularMakes.slice(0, POPULAR_ITEMS_LIMIT);
  const makeOptions = groupMakeOptions(ctx.makes, ctx.popularMakes, t);

  const initMake = makeOptions.find(item => item.value === +query.make) ?? null;

  const [make, setMake] = useState<DropdownOption | null>(initMake);
  const [model, setModel] = useState<DropdownOption | null>(null);

  const filterOptions = useFilterOptions();
  const models = useCatalogModels(make ? apiPaths.models(make.value) : null, formatModelOptions);

  const getInitialFilter = (key: string, options: DropdownOption[]) => {
    return (
      options.filter(i => {
        if (Array.isArray(query[key])) {
          return query[key]?.includes?.(String(i.value)) ?? false;
        }

        return query[key] === String(i.value);
      }) ?? []
    );
  };

  useEffect(() => {
    const bodyInner = document.querySelector('.body-inner');

    bodyInner?.classList.add('no-padding');
    document.querySelector('.footer')?.classList.add('is-hidden');
    document.querySelector('.tab-bar-container')?.classList.add('is-hidden');

    return () => {
      bodyInner?.classList.remove('no-padding');
    };
  }, []);

  useEffect(() => {
    if (query.model || (query.model_group && !model)) {
      setModel(selectInitialModel(query, models.data));
    }
  }, [query.model, query.model_group, models.isLoading]);

  useEffect(() => {
    if (!query) return;

    setFilters(prev => ({
      ...prev,
      gear: getInitialFilter('[gear][]', filterOptions.data.gears),
      seatsCount: getInitialFilter('[seats_count][]', SEATS),
      fuelType: getInitialFilter('[fuel_type][]', filterOptions.data.fuelTypes),
      category: getInitialFilter('[category][]', filterOptions.data.categories),
      transmission: getInitialFilter('[transmission][]', filterOptions.data.transmissions),
      accelFrom: query?.acceleration_from ?? '',
      accelTo: query?.acceleration_to ?? '',
      powerFrom: query?.power_from ?? '',
      powerTo: query?.power_to ?? '',
      yearTo: filterOptions.data.regYears.find(i => i.value === Number(query.year_to)) ?? null,
      capTo:
        filterOptions.data.engineVolumes.find(i => i.value === Number(query.engine_volume_to)) ??
        null,
      yearFrom: filterOptions.data.regYears.find(i => i.value === Number(query.year_from)) ?? null,
      capFrom:
        filterOptions.data.engineVolumes.find(i => i.value === Number(query.engine_volume_from)) ??
        null
    }));
  }, [filterOptions.data]);

  const onBackBtnClick = () => {
    history.replace(RoutePath.autocatalog + history.location.search);
  };

  const onResetFilters = () => {
    setMake(null);
    setModel(null);
    setFilters(INITIAL_FILTERS);
  };

  const onMakeChange = (item: DropdownOption) => {
    setMake(item);
    setModel(null);
  };

  const onModelChange = (item: DropdownOption) => {
    setModel(item);
  };

  const onMakeItemClick = (item: Make) => {
    onMakeChange({ value: item.id, label: item.name });
  };

  const toggleGears = (isActive: boolean, item: DropdownOption) => {
    if (isActive) {
      setFilters(prev => ({ ...prev, gear: [...(prev?.gear ?? []), item] }));
    } else {
      setFilters(prev => ({
        ...prev,
        gear: (prev?.gear ?? []).filter(i => i.value !== item.value)
      }));
    }
  };

  const toggleFuelType = (isActive: boolean, item: DropdownOption) => {
    if (isActive) {
      setFilters(prev => ({ ...prev, fuelType: [...(prev?.fuelType ?? []), item] }));
    } else {
      setFilters(prev => ({
        ...prev,
        fuelType: (prev?.fuelType ?? []).filter(i => i.value !== item.value)
      }));
    }
  };

  const toggleTransmission = (isActive: boolean, item: DropdownOption) => {
    if (isActive) {
      setFilters(prev => ({ ...prev, transmission: [...(prev?.transmission ?? []), item] }));
    } else {
      setFilters(prev => ({
        ...prev,
        transmission: (prev?.transmission ?? []).filter(i => i.value !== item.value)
      }));
    }
  };

  const toggleSeats = (isActive: boolean, item: DropdownOption) => {
    if (isActive) {
      setFilters(prev => ({ ...prev, seatsCount: [...(prev?.seatsCount ?? []), item] }));
    } else {
      setFilters(prev => ({
        ...prev,
        seatsCount: (prev?.seatsCount ?? []).filter(i => i.value !== item.value)
      }));
    }
  };

  const applyFilters = () => {
    const queryParams = {
      make: make?.value ?? '',
      model: model?.isCategory ? '' : model?.value ?? '',
      model_group: model?.isCategory ? model?.value ?? '' : '',
      power_from: filters?.powerFrom ?? '',
      power_to: filters?.powerTo ?? '',
      acceleration_from: filters?.accelFrom ?? '',
      acceleration_to: filters?.accelTo ?? '',
      year_from: filters.yearFrom?.value ?? '',
      year_to: filters.yearTo?.value ?? '',
      engine_volume_from: filters.capFrom?.value ? filters.capFrom?.value ?? '' : '',
      engine_volume_to: filters.capTo?.value ? filters.capTo?.value ?? '' : '',
      '[transmission][]': filters.transmission?.map(i => String(i.value)) ?? '',
      '[category][]': filters.category?.map(i => String(i.value)) ?? '',
      '[fuel_type][]': filters.fuelType?.map(i => String(i.value)) ?? '',
      '[seats_count][]': filters.seatsCount?.map(i => String(i.value)) ?? '',
      '[gear][]': filters.gear?.map(i => String(i.value)) ?? ''
    };

    setQuery(queryParams);
    history.push(RoutePath.autocatalog + history.location.search);
  };

  const renderResetButton = () => {
    const hasFilters = Object.values(filters).filter(item => !!item).length;
    const activeClass = hasFilters ? 'is-active' : '';

    return (
      <button
        className={`popup-header__btn popup-header__btn--reset ${activeClass}`}
        onClick={onResetFilters}
      >
        {t('mainSearch.reset')}
      </button>
    );
  };

  console.log(model);

  return (
    <S.Container>
      <GlobalStyle />
      <MobileHeader
        isFixed
        title={t('mainSearch.filters')}
        onBackBtnClick={onBackBtnClick}
        renderRightSide={renderResetButton}
      />

      <S.Body>
        {make ? null : (
          <S.Section>
            {isMakesVisible && (
              <DropdownList
                isFullscreen
                isSearchable
                options={makeOptions}
                selectedOptions={[]}
                onSelect={item => {
                  onMakeChange(item);
                  setMakesVisible(false);
                }}
                headerTitle={t('mainSearch.make')}
                onClose={() => setMakesVisible(false)}
              />
            )}

            <S.SectionTitle>{t('filters.makeAndModel')}</S.SectionTitle>
            <S.MakesList>
              {popularMakes.map(item => {
                return (
                  <S.MakeItem key={item.id} onClick={() => onMakeItemClick(item)}>
                    <S.MakeLogo>
                      <S.MakeImage src={item.logo_url} loading="lazy" />
                    </S.MakeLogo>
                    <S.MakeTitle>{item.name}</S.MakeTitle>
                  </S.MakeItem>
                );
              })}
            </S.MakesList>

            <S.ButtonOutlined onClick={() => setMakesVisible(true)}>
              {t('filters.showAllMakes')}
            </S.ButtonOutlined>
          </S.Section>
        )}

        {!isMakesVisible && make && (
          <S.TransparentSection>
            <S.SelectionsGroup>
              <DropdownSelect
                isFullscreen
                isSearchable
                value={make}
                options={makeOptions}
                label={t('mainSearch.make')}
                placeholder={t('mainSearch.make')}
                onChange={onMakeChange}
              />
              <DropdownSelect
                isFullscreen
                isSearchable
                value={model}
                options={models.data}
                onChange={onModelChange}
                label={t('mainSearch.model')}
                placeholder={t('mainSearch.model')}
                searchTitle={t('mainSearch.searchModel')}
              />
            </S.SelectionsGroup>
          </S.TransparentSection>
        )}

        <S.Section>
          <DropdownSelect
            isMultiSelect
            value={filters.category}
            options={filterOptions.data.categories}
            label={t('mainSearch.category')}
            placeholder={t('mainSearch.category')}
            onChange={(items: DropdownOption[]) =>
              setFilters(prev => ({ ...prev, category: items }))
            }
          />

          <S.SelectorsGrid>
            <FilterInput
              inputValue={filters.accelFrom}
              onChange={value => setFilters(prev => ({ ...prev, accelFrom: value }))}
              label={t('filters.acceleration')}
              placeholder={t('mainSearch.from')}
            />

            <FilterInput
              inputValue={filters.accelTo}
              onChange={value => setFilters(prev => ({ ...prev, accelTo: value }))}
              placeholder={t('mainSearch.to')}
            />

            <DropdownSelect
              isLabelActive
              value={filters.yearFrom}
              options={filterOptions.data.regYears}
              label={t('mainSearch.yearOfManufacture')}
              isDropdownIconVisible={false}
              placeholder={t('mainSearch.from')}
              onChange={(item: DropdownOption) => setFilters(prev => ({ ...prev, yearFrom: item }))}
            />
            <DropdownSelect
              value={filters.yearTo}
              options={filterOptions.data.regYears}
              isDropdownIconVisible={false}
              placeholder={t('mainSearch.to')}
              dropdownTitle={t('mainSearch.yearOfManufacture')}
              onChange={(item: DropdownOption) => setFilters(prev => ({ ...prev, yearTo: item }))}
            />

            <DropdownSelect
              isLabelActive
              value={filters.capFrom}
              options={filterOptions.data.engineVolumes}
              label={t('filters.engineVolume')}
              isDropdownIconVisible={false}
              placeholder={t('mainSearch.from')}
              onChange={(item: DropdownOption) => setFilters(prev => ({ ...prev, capFrom: item }))}
            />

            <DropdownSelect
              value={filters.capTo}
              options={filterOptions.data.engineVolumes}
              isDropdownIconVisible={false}
              dropdownTitle={t('filters.engineVolume')}
              placeholder={t('mainSearch.to')}
              onChange={(item: DropdownOption) => setFilters(prev => ({ ...prev, capTo: item }))}
            />

            <FilterInput
              inputValue={filters.powerFrom}
              onChange={value => setFilters({ ...filters, powerFrom: value })}
              label={t('filters.power')}
              placeholder={t('mainSearch.from')}
            />

            <FilterInput
              inputValue={filters.powerTo}
              onChange={value => setFilters({ ...filters, powerTo: value })}
              placeholder={t('mainSearch.to')}
            />
          </S.SelectorsGrid>
        </S.Section>

        <S.Section>
          <S.SectionTitle>{t('mainSearch.fuelType')}</S.SectionTitle>

          <S.ButtonsGrid>
            {filterOptions.data.fuelTypes.map(item => {
              const isActive = !!filters.fuelType?.filter(i => i.value === item.value).length;

              return (
                <ToggleButton
                  key={item.value}
                  isActive={isActive}
                  onChange={isActive => toggleFuelType(isActive, item)}
                >
                  {item.label}
                </ToggleButton>
              );
            })}
          </S.ButtonsGrid>
        </S.Section>

        <S.Section>
          <S.SectionTitle>{t('mainSearch.gear')}</S.SectionTitle>

          <S.ButtonsGrid>
            <S.ButtonsGrid>
              {filterOptions.data.gears.map(item => {
                const isActive = !!filters.gear?.filter(i => i.value === item.value).length;

                return (
                  <ToggleButton
                    key={item.value}
                    isActive={isActive}
                    onChange={isActive => toggleGears(isActive, item)}
                  >
                    {item.label}
                  </ToggleButton>
                );
              })}
            </S.ButtonsGrid>
          </S.ButtonsGrid>
        </S.Section>

        <S.Section>
          <S.SectionTitle>{t('mainSearch.transmission')}</S.SectionTitle>

          <S.ButtonsGrid>
            {filterOptions.data.transmissions.map(item => {
              const isActive = !!filters.transmission?.filter(i => i.value === item.value).length;

              return (
                <ToggleButton
                  key={item.value}
                  isActive={isActive}
                  onChange={isActive => toggleTransmission(isActive, item)}
                >
                  {item.label}
                </ToggleButton>
              );
            })}
          </S.ButtonsGrid>
        </S.Section>

        <S.Section>
          <S.SectionTitle>{t('mainSearch.seatsCount')}</S.SectionTitle>

          <S.ButtonsGrid>
            {SEATS.map(item => {
              const isActive = !!filters.seatsCount?.filter(i => i.value === item.value).length;

              return (
                <ToggleButton
                  key={item.value}
                  isActive={isActive}
                  onChange={isActive => toggleSeats(isActive, item)}
                >
                  {item.label}
                </ToggleButton>
              );
            })}
          </S.ButtonsGrid>
        </S.Section>

        <div className="tz-container filter__bottom">
          <button
            name="button"
            type="submit"
            className="tz-btn tz-btn--primary tz-btn--full"
            onClick={applyFilters}
          >
            {t('mainSearch.submit')}
          </button>
        </div>
      </S.Body>
    </S.Container>
  );
};

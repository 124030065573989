import styled, { css } from 'styled-components';
import ResetSvg from '@assets/images/mobile/icon-reset--grey.svg';

const MinimizedLabel = css`
  font-size: 14px;
  pointer-events: none;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  height: 54px;
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};
`;

export const InputSection = styled.div``;

export const Input = styled.input`
  width: 100%;
  height: 27px;

  &::placeholder {
    font-size: 15px;
    color: ${({ theme }) => theme.palette.grey[200]};
  }
`;

export const ResetButton = styled.span`
  display: block;
  right: 0;
  bottom: 7px;
  position: absolute;
  height: 15px;
  width: 15px;
  padding-right: 10px;
  background-size: 15px;
  background-repeat: no-repeat;
  background-image: url(${ResetSvg});
`;

export const Label = styled.p<{ $isMinimized: boolean }>`
  font-size: 15px;
  transition: all 0.2s;
  color: ${({ theme }) => theme.palette.grey[200]};
  ${({ $isMinimized }) => ($isMinimized ? MinimizedLabel : '')}
`;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryState } from 'packs/autocatalog/hooks';
import { useAutocatalogContext } from 'packs/autocatalog/context';
import { TabSwitcher } from 'packs/autocatalog/components/desktop';
import * as S from './Header.styles';

enum Tabs {
  all = 'all',
  popular = 'popular'
}

export const Header = () => {
  const { t } = useTranslation();
  const [params, setQuery] = useQueryState();
  const { makes, popularMakes } = useAutocatalogContext();
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.popular);

  const list = activeTab === Tabs.popular ? popularMakes : makes;
  const tabs = {
    [Tabs.popular]: t('popularMakes'),
    [Tabs.all]: t('allMakes')
  };

  const onClick = (id: number | string) => {
    setQuery({ ...params, make: id, model: '', model_group: '' });
  };

  return (
    <S.Container>
      <S.Header>
        <S.Wrapper>
          <S.Title>{t('catalog')}</S.Title>
          <TabSwitcher
            isDark
            tabs={tabs}
            defaultTab={Tabs.popular}
            onTabChange={(tab: Tabs) => setActiveTab(tab)}
          />
        </S.Wrapper>
      </S.Header>

      <S.Wrapper>
        <S.MakesList>
          {list.map(item => {
            return (
              <S.MakeItem key={item.id} onClick={() => onClick(item.id)}>
                <S.MakeLogoContainer>
                  <S.MakeLogo src={item.logo_url} loading="lazy" />
                </S.MakeLogoContainer>
                <S.MakeName>{item.name}</S.MakeName>
              </S.MakeItem>
            );
          })}
        </S.MakesList>
      </S.Wrapper>
    </S.Container>
  );
};

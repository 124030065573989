import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FIRST_PAGE_NUMBER } from '../constants';

export const Pagination = ({
  totalPages,
  currentPage,
  setCurrentPage
}) => {
  const [pages, setPages] = useState([]);
  const step = 1;

  const generatePageNumbers = () => {
    const arr = [];
    let i = 0;
    while (i < totalPages) {
      const pageNumber = i + step;
      arr.push(currentPage !== pageNumber ? (
        <span className="page" onClick={() => setCurrentPage(pageNumber)} key={pageNumber}>
          <Link to={`?page=${pageNumber}`}>{pageNumber}</Link>
        </span>
      ) : <span className="page current" key={pageNumber}>{pageNumber}</span>);
      i += step;
    }
    return arr;
  };

  useEffect(() => {
    if (totalPages) {
      setPages(generatePageNumbers());
    }
  }, [totalPages, currentPage]);

  const goToBack = useCallback(() => setCurrentPage(currentPage - step), [currentPage]);
  const goToNextPage = useCallback(
    () => setCurrentPage(currentPage + step),
    [currentPage]
  );

  return (
    <div className="pagination-container pagination-container--profile">
      <nav className="pagination">
        {currentPage !== FIRST_PAGE_NUMBER && (
          <span className="prev" onClick={goToBack}>
            <Link to={`?page=${currentPage - step}`}>&nbsp;</Link>
          </span>
        )}
        {pages.map(i => i)}
        {currentPage < totalPages && (
          <span className="next" onClick={goToNextPage}>
            <Link to={`?page=${currentPage + step}`}>&nbsp;</Link>
          </span>
        )}
      </nav>
    </div>
  );
};

import { GenerationsResponse } from 'packs/autocatalog/types';

export interface FeatureTab {
  fields: string[];
  hidden_fields: string[];
  id: string;
  label: string;
}

export enum Tabs {
  generation = 'generation',
  specs = 'specs'
}

export interface GenerationListProps {
  isLoading: boolean;
  generations: GenerationsResponse;
}

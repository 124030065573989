import React from 'react';
import { useTranslation } from 'react-i18next';
import { ItemTabName } from '../../../../components/Item/types';

export const EmptyTab: React.FC<{ tabName: ItemTabName}> = ({ tabName }) => {
  const { t } = useTranslation();

  return tabName === ItemTabName.all ? (
    <>
      <div className="profile-items_results_header">
        <div className="profile-items_results_header_label">{t('status.all')}</div>
      </div>
      <div className="profile-items_results_list products-container">
        <p className="profile-items_results_list_empty">{t('empty')}</p>
      </div>
    </>
  ) : <p className="profile-items_results_list_empty">{t('empty')}</p>;
};

import styled, { css } from 'styled-components';
import ResetSvg from '@assets/images/mobile/icon-reset--grey.svg';
import SelectSvg from '@assets/images/mobile/icon-select--grey.svg';

const MinimizedPlaceholder = css`
  top: 8px;
  font-size: 14px;
  pointer-events: none;
`;

const OutlinedSelector = css`
  padding: 0 15px;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.palette.grey[75]};
  background-color: ${({ theme }) => theme.palette.grey[50]};

  p {
    left: 15px;
  }
`;

export const Wrapper = styled.div<{ $isOutlined: boolean }>`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};
  ${({ $isOutlined }) => ($isOutlined ? OutlinedSelector : '')}
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background: transparent;
  height: 53px;
  flex-direction: column;
`;

export const ResetIcon = styled.span<{ isCentered: boolean }>`
  margin-top: -15px;
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  background-image: url(${ResetSvg});
  top: ${({ isCentered }) => (isCentered ? '28px' : 'unset')};
  bottom: ${({ isCentered }) => (isCentered ? 'unset' : '0')};
`;

export const DropdownIcon = styled.span`
  top: 28px;
  margin-top: -17px;
  position: absolute;
  width: 35px;
  height: 35px;
  right: 0;
  background-size: 17px 10px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  background-image: url(${SelectSvg});
`;

export const InputSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Placeholder = styled.p<{ isMinimized: boolean }>`
  position: absolute;
  left: 0;
  top: 19px;
  font-size: 15px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: top 0.2s ease;
  box-sizing: border-box;
  transform: none;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.grey[200]};

  ${({ isMinimized }) => (isMinimized ? MinimizedPlaceholder : '')}
`;

export const Value = styled.p`
  font-size: 15px;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const Input = styled.input`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  && {
    font-size: 15px;
    background-color: transparent;
    padding: 0 20px 0 0 !important;
    color: ${({ theme }) => theme.palette.blue[200]};

    &::placeholder {
      color: ${({ theme }) => theme.palette.grey[200]};
    }
  }
`;

import { useHistory, useLocation } from 'react-router-dom';
import { QueryParams } from '../types';

type QueryValues = Record<string, string | null | Array<string>>;

type QueryStateHook = [
  QueryParams,
  (values: Record<string, string | number | null | Array<string>>) => void
];

export const useQueryState = (): QueryStateHook => {
  const history = useHistory();
  const location = useLocation();

  const setQuery = (values: QueryValues) => {
    const searchParams = new URLSearchParams(location.search);

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];

        if (!value) {
          searchParams.delete(key);
        } else if (Array.isArray(value)) {
          searchParams.delete(key);
          value.forEach(val => {
            searchParams.append(key, String(val));
          });
        } else {
          searchParams.set(key, String(value));
        }
      }
    }

    history.push(`${location.pathname}?${searchParams.toString()}`);
  };

  const queryParams = new URLSearchParams(location.search);
  const queryValues: QueryValues = {};

  queryParams.forEach((value, key) => {
    if (queryValues[key] === undefined) {
      queryValues[key] = value;
    } else {
      if (!Array.isArray(queryValues[key])) {
        queryValues[key] = [queryValues[key] as string];
      }
      (queryValues[key] as string[]).push(value);
    }
  });

  return [queryValues, setQuery];
};

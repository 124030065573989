import styled from 'styled-components';
import CloseSvg from '@assets/images/application/icons-for-sprite/icon-close--white.svg';

export const Container = styled.div<{ $isClosing: boolean }>`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  transition: opacity 0.2s linear;
  opacity: ${({ $isClosing }) => ($isClosing ? 0 : 1)};
  background-color: ${({ theme }) => theme.color.blackBg};
`;

export const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 125px);
  display: flex;
  align-items: center;

  // Fix for too wide slides on initial load
  .swiper-container {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-height: 0;
    min-width: 0;
  }
`;

export const Title = styled.p`
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[0]};
`;

export const Header = styled.div`
  display: flex;
  height: 60px;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
`;

export const Footer = styled.div`
  padding: 15px;
`;

export const HeaderTitle = styled.div`
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.grey[0]};
`;

export const CarouselContainer = styled.div<{ $offsetY: number }>`
  position: relative;
  width: 100%;
  touch-action: none;
  transform: translate3d(0px, ${({ $offsetY }) => $offsetY}px, 0px) scale(1);
`;

export const CloseButton = styled.button`
  width: 15px;
  height: 35px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  border-radius: 7px;
  transition: background-color 0.2s linear 0s;
  background: url(${CloseSvg}) center center / 30px 30px no-repeat transparent;

  &:focus,
  &:active {
    outline: none;
    border: none;
    box-shadow: none;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

export const SliderMobile = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

export const SlideMobile = styled.div`
  width: 100%;
  height: 100%;
`;

export const ImageMobile = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  user-drag: none;
  transition: opacity 0.2s linear;
`;

import React, { useState } from 'react';
import { element, object } from 'prop-types';
import { InvoicesAppContext } from './index';
import {
  globalState,
  initialCurrentInvoicesTab,
  initialSelectedInvoice,
  initialInvoicesRawData
} from './globalState';

export const ContextWrapper = ({ children, dataset }) => {
  const [invoicesRawData, setInvoicesRawData] = useState(initialInvoicesRawData);
  const [balanceValueData, setBalanceData] = useState(globalState.balanceValue);
  const [hasErrors, setHasErrors] = useState(globalState.hasErrors);
  const [isModalVisible, setIsModalVisible] = useState(globalState.isModalVisible);
  const [balanceModalVisible, setIsBalanceModalVisible] = useState(globalState.isModalVisible);
  const [selectedInvoice, setSelectedInvoice] = useState(initialSelectedInvoice);
  const [currentInvoicesTab, setCurrentInvoicesTab] = useState(initialCurrentInvoicesTab);
  const [packageTariffs, setPackageTariffs] = useState(null);

  const [invoiceRef, setInvoiceRef] = useState(null);
  const [balanceRef, setBalanceRef] = useState(null);
  const [packageRef, setPackageRef] = useState(null);
  const [visibleTabRef, setVisibleTabRef] = useState(globalState.visibleTabRef);
  const [currentBusinessTab, setCurrentBusinessTab] = useState(window.location.pathname);

  const [winOpened, setWindowOpened] = useState(null);

  const contextValue = {
    isMobileSite: dataset.isMobileSite === 'true',
    bankCardProvider: dataset.bankCardProvider,
    selectedInvoice,
    setSelectedInvoice,
    hasErrors,
    isModalVisible,
    setIsModalVisible,
    setIsBalanceModalVisible,
    balanceModalVisible,
    setHasErrors,
    currentInvoicesTab,
    setCurrentInvoicesTab,
    invoicesRawData,
    setInvoicesRawData,
    balanceValueData,
    setBalanceData,
    setInvoiceRef,
    invoiceRef,
    setPackageRef,
    packageRef,
    setBalanceRef,
    balanceRef,
    visibleTabRef,
    setVisibleTabRef,
    currentBusinessTab,
    setCurrentBusinessTab,
    setPackageTariffs,
    packageTariffs,
    winOpened,
    setWindowOpened
  };

  return (
    <InvoicesAppContext.Provider value={contextValue}>
      { children }
    </InvoicesAppContext.Provider>
  );
};

ContextWrapper.propTypes = {
  children: element.isRequired,
  dataset: object.isRequired
};

import styled from 'styled-components';

export const Container = styled.div``;

export const Body = styled.div`
  width: 970px;
  margin: 30px auto;
`;

const BaseGrid = styled.div`
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
`;

export const NewItemsGrid = styled(BaseGrid)`
  margin-bottom: 70px;

  & > div {
    height: 190px;
  }
`;

export const ItemsGrid = styled(BaseGrid)<{ $hasLoadButton: boolean }>`
  margin-bottom: ${({ $hasLoadButton }) => ($hasLoadButton ? '40px' : '70px')};

  & > div {
    height: 165px;
  }
`;

export const SectionTitle = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 0 20px 0;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const LoadButton = styled.button`
  display: block;
  padding: 15px 35px;
  border-radius: 7px;
  cursor: pointer;
  font-size: 15px;
  margin: 40px auto 0 auto;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.palette.blue[100]};
`;

export const OffersContainer = styled.div`
  margin-bottom: 55px;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[75]};
`;

export const Offers = styled.div`
  .offers-header {
    display: flex;
    margin: 0 auto;
    max-width: 970px;
    align-items: center;
    justify-content: space-between;

    & > a {
      color: ${({ theme }) => theme.palette.blue[200]};
    }
  }

  .offers-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 30px 0;
    color: ${({ theme }) => theme.palette.blue[200]};
  }

  .page-content {
    background-color: transparent;
  }

  .products {
    margin: 0 -18px 0 0;
  }
`;

import styled, { css } from 'styled-components';
import CloseSvg from '@assets/images/mobile/icon-cancel--grey.svg';
import SearchSvg from '@assets/images/mobile/advanced-select-search.svg';
import CheckedSvg from '@assets/images/application/icon-checked--white.svg';

const FullscreenList = css`
  top: 0;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
`;

const DefaultList = css`
  top: 107px;
  height: calc(100vh - 107px);
  border-radius: 7px 7px 0 0;
  overflow: hidden;
`;

const ShortList = css`
  top: inherit;
  height: auto;
  overflow: scroll;
  border-radius: 7px 7px 0 0;
`;

const CategoryOption = css`
  border-bottom: none;
  font-size: 15px;

  p {
    color: ${({ theme }) => theme.palette.grey[200]};
  }
`;

const SectionOption = css`
  font-size: 14px;
  border-bottom: none;

  p {
    color: ${({ theme }) => theme.palette.grey[200]};
  }
`;

const GroupOption = css`
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[50]};

  p {
    color: ${({ theme }) => theme.palette.blue[200]};
  }
`;

const DefaultOption = css`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[50]};
`;

const getOptionStyle = (isGroup?: boolean, isCategory?: boolean, isSection?: boolean) => {
  if (isGroup) return GroupOption;
  if (isSection) return SectionOption;
  if (isCategory) return CategoryOption;
  return DefaultOption;
};

const SelectedRadio = css`
  border-color: ${({ theme }) => theme.palette.red[100]};

  &:after {
    content: '';
    position: absolute;
    right: 4px;
    top: 4px;
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.palette.red[100]};
    border-radius: 50%;
  }
`;

const SelectedCheckbox = css`
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${CheckedSvg});
  border-color: ${({ theme }) => theme.palette.red[100]};
  background-color: ${({ theme }) => theme.palette.red[100]};
`;

export const Container = styled.div<{ isFullscreen: boolean; isShort: boolean }>`
  position: fixed;
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  max-height: 100%;
  background-color: ${({ theme }) => theme.color.white};
  ${({ isFullscreen, isShort }) =>
    isFullscreen ? FullscreenList : isShort ? ShortList : DefaultList}

  .header {
    border-bottom: none;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  height: 120%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.24);
  z-index: 99;
  top: 0;
  left: 0;
  overflow: hidden;
`;

export const ListHeader = styled.div<{ isShort: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  height: 60px;
  padding-bottom: 10px;
  box-sizing: border-box;
  position: ${({ isShort }) => (isShort ? 'relative' : 'absolute')};
  background-color: ${({ theme }) => theme.color.white};
`;

export const ListHeaderTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const ListHeaderClose = styled.button`
  background: transparent;
  outline: none;
  box-shadow: none;
  position: absolute;
  right: 7px;
  bottom: 10px;
  width: 30px;
  height: 20px;
  margin-right: -4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  background-image: url(${CloseSvg});
  z-index: 2;
`;

export const SearchBar = styled.div`
  display: flex;
  padding: 0 15px 12px;
  border-bottom: ${({ theme }) => theme.border};
`;

export const SearchInput = styled.input`
  display: block;
  width: 100%;
  height: 40px;
  font-size: 15px;
  border-radius: 7px;
  padding: 0 10px 0 36px !important;
  color: ${({ theme }) => theme.palette.blue[200]};
  background-color: ${({ theme }) => theme.palette.grey[50]};
  background-image: url(${SearchSvg});
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 15px;

  ::placeholder {
    color: ${({ theme }) => theme.palette.grey['200']};
  }
`;

export const ListContainer = styled.div<{
  isIos: boolean;
  isShort: boolean;
  isSearchable: boolean;
  isFullscreen: boolean;
}>`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  z-index: 1;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  padding-bottom: calc(env(safe-area-inset-bottom) + ${({ isIos }) => (isIos ? '130px' : '76px')});
  position: ${({ isShort }) => (isShort ? 'relative' : 'absolute')};
  top: ${({ isFullscreen, isSearchable, isShort }) =>
    !isShort && (isFullscreen && isSearchable ? '115px' : '60px')};
`;

export const ListItem = styled.div<{
  isCategory?: boolean;
  isGroup?: boolean;
  isSection?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 0 15px;
  position: relative;
  justify-content: space-between;
  ${({ isGroup, isCategory, isSection }) => getOptionStyle(isGroup, isCategory, isSection)};
`;

export const ListItemLabel = styled.p`
  font-size: 15px;
  letter-spacing: -0.35px;
  width: 100%;
  padding: 18px 0;
  color: ${({ theme }) => theme.palette.blue[200]};
`;

export const ItemRadio = styled.span<{ isSelected: boolean }>`
  position: absolute;
  content: '';
  right: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.grey[75]};
  box-sizing: border-box;

  ${({ isSelected }) => (isSelected ? SelectedRadio : '')}
`;

export const ItemCheckbox = styled.span<{ $isSelected: boolean }>`
  position: absolute;
  content: '';
  right: 20px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.grey[75]};
  ${({ $isSelected }) => ($isSelected ? SelectedCheckbox : '')}
`;

export const ListItemImage = styled.img`
  max-width: 30px;
  max-height: 80%;
  margin-right: 10px;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

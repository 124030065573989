import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWindowComponent } from '../index';
import { DeleteAccountContext } from '../../../context';
import { OpenedWindow } from '../../../context/initialState';
import {
  Container, Title, Rule, Button, Line, Rules, Header
} from './styled';

export const AlertWindow = () => {
  const { setOpenedWindow, isMobileSite } = useContext(DeleteAccountContext);
  const { t } = useTranslation();

  const onClose = () => {
    setOpenedWindow(OpenedWindow.none);
  };

  const openDeleteWindow = () => {
    setOpenedWindow(OpenedWindow.deleteWindow);
  };

  return (
    <ModalWindowComponent
      onClose={onClose}
      title={<Header>{t('alert.title')}</Header>}
      popupWidth={isMobileSite ? '100%' : '455px'}
      styles={isMobileSite ? {
        borderRadius: 0,
        height: '100%',
        position: 'fixed',
        bottom: 'env(safe-area-inset-bottom)',
        top: 'env(safe-area-inset-top)',
        overflow: 'hidden'
      } : {}}
      contentStyles={isMobileSite ? { backgroundColor: '#f8f9fd' } : {}}
    >
      <Container>
        <Rules>
          <Title>{t('alert.message')}</Title>
          <Rule>{t('alert.rule1')}</Rule>
        </Rules>
        {!isMobileSite && <Line />}
        <Button onClick={openDeleteWindow}>{t('action')}</Button>
      </Container>
    </ModalWindowComponent>
  );
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PopupComponent } from '../../popup';
import { PersonalCabinetContext } from '../../../context';
import {
  AdInfo, AdPhoto, Description, AdTitle
} from './styled';
import { numberWithFractionDigits, doFormatDate, numberWithCurrency } from '../../../../../helpers';
import { RowComponent } from './Row';
import { TimeFormat } from '../../../../../types';

export const DetailsPopup = () => {
  const { t } = useTranslation();
  const { selectedRow, setSelectedRow } = useContext(PersonalCabinetContext);
  const {
    id, created_at, service, source, discount, ad, amount
  } = selectedRow?.payment || selectedRow;

  const value = numberWithCurrency(amount || selectedRow.value);
  const date = doFormatDate({ date: created_at, format: TimeFormat.fullTime });

  const onClose = () => setSelectedRow(null);

  return (
    <PopupComponent
      title={`${t('detailsTitle')} #${id}`}
      onClose={onClose}
    >
      <div className="profile-transaction-details">
        {ad && (
          <AdInfo href={`/autos/${ad.id}`} className="item-link">
            <AdPhoto photo={ad.photo} />
            <AdTitle>
              <span>{`${numberWithFractionDigits(ad.price, { minimumFractionDigits: 0 })} $`}</span>
              <span>{ad.title}</span>
            </AdTitle>
          </AdInfo>
        )}
        <div className="profile-transaction-details_logo" />
        <Description>
          <RowComponent title="date_and_time" value={date} />
          <RowComponent title="paymentId" value={id} />
          {ad?.id && <RowComponent title="adId" value={ad?.id} />}
          <RowComponent title="service" value={service} />
          {source && <RowComponent title="source" value={source} />}
          {discount && <RowComponent title="adDiscount" value={`${discount}%`} />}
          <RowComponent title="sum" value={value} />
        </Description>
      </div>
    </PopupComponent>
  );
};

import { useTranslation } from 'react-i18next';
import React from 'react';

export const ErrorHandler = () => {
  const { t } = useTranslation();

  return (
    <div className="invoices-error">
      <div className="invoices-error-img" />
      <b>{t('serverError.title')}</b>
      <span className="invoices-error-message">{t('serverError.message')}</span>
      <a href={window.location}>{t('serverError.tryAgain')}</a>
    </div>
  );
};

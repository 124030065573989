// @ts-nocheck
import React from 'react';
import { object } from 'prop-types';
import az from './locales/az.json';
import ru from './locales/ru.json';
import { ContextWrapper } from './context/ContextWrapper';
import { AutocatalogMobile, AutocatalogDesktop } from './routes';

const AutocatalogApp = ({ dataset }) => (
  <ContextWrapper {...{ dataset }}>
    {dataset.isMobileSite === 'true' ? <AutocatalogMobile /> : <AutocatalogDesktop />}
  </ContextWrapper>
);

AutocatalogApp.propTypes = {
  dataset: object.isRequired
};

export default { App: AutocatalogApp, az, ru };

import { Dispatch, SetStateAction } from 'react';
import { FilterOptions, GenerationsResponse, Make, ModelsResponse } from '../types';

export interface State {
  isMobile: boolean;
  makes: Make[];
  models: ModelsResponse;
  generations: GenerationsResponse;
  popularMakes: Make[];
  filterOptions: FilterOptions;
  isLoading: boolean;
  setFilterOptions: Dispatch<SetStateAction<FilterOptions>>;
  setMakes: Dispatch<SetStateAction<Make[]>>;
  setModels: Dispatch<SetStateAction<ModelsResponse>>;
  setGenerations: Dispatch<SetStateAction<GenerationsResponse>>;
  setPopularMakes: Dispatch<SetStateAction<Make[]>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const initialState: State = {
  isMobile: false,
  makes: [],
  models: [],
  isLoading: true,
  generations: [],
  popularMakes: [],
  filterOptions: {
    regYears: [],
    gears: [],
    categories: [],
    engineVolumes: [],
    fuelTypes: [],
    transmissions: []
  },
  setMakes: () => {},
  setModels: () => {},
  setGenerations: () => {},
  setLoading: () => {},
  setPopularMakes: () => {},
  setFilterOptions: () => {}
};

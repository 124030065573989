import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAxios } from 'hooks';
import { useQueryState } from 'packs/autocatalog/hooks';
import { CatalogItemsResponse } from 'packs/autocatalog/types';
import { countFiltersInURL, formatCatalogItems } from 'packs/autocatalog/utils';
import { useCatalogGenerations, useCatalogItems, useOffers } from 'packs/autocatalog/api';
import { apiPaths } from 'packs/autocatalog/constants';
import {
  Breadcrumbs,
  Header,
  SearchFilters,
  Loader,
  Empty,
  GenerationList
} from 'packs/autocatalog/components/desktop';
import { CatalogItem } from './CatalogDesktop.types';
import { NewItemCards } from './NewItemCards';
import { ItemCards } from './ItemCards';
import * as S from './CatalogDesktop.styles';

export const CatalogDesktop = () => {
  const history = useHistory();
  const [query] = useQueryState();

  const [page, setPage] = useState<number | undefined>();
  const [moreItems, setMoreItems] = useState<CatalogItem[]>([]);

  const makeId = query.make ?? '';
  const isModelEmpty = !(query.model || query.model_group);
  const filtersCount = countFiltersInURL(query);
  const hasFilters = filtersCount > 0;
  const itemsUrl = apiPaths.items(`${history.location.search}`);

  const offers = useOffers(history.location.search);
  const catalogItems = useCatalogItems(isModelEmpty ? itemsUrl : null);
  const generations = useCatalogGenerations(
    !isModelEmpty ? apiPaths.generations(history.location.search) : null
  );
  const items = [...catalogItems.data, ...moreItems];

  const moreReq = useAxios<CatalogItemsResponse>({
    method: 'GET',
    url: apiPaths.items(`${history.location.search}&page=${page}`),
    onSuccess: data => {
      const formattedData = formatCatalogItems(data);

      if (formattedData.length) {
        setPage(data.next_page);
        setMoreItems(prev => [...prev, ...formattedData]);
      }
    }
  });

  useEffect(() => {
    setMoreItems([]);
  }, [history.location.search]);

  useEffect(() => {
    if (!catalogItems?.next_page) setPage(undefined);
    if (catalogItems.next_page !== page) setPage(catalogItems.next_page);
  }, [catalogItems.next_page, history.location.search]);

  const renderOfferItems = () => {
    if (!offers?.data.includes('products-i')) return null;

    return (
      <S.OffersContainer>
        <S.Offers dangerouslySetInnerHTML={{ __html: offers?.data }} />
      </S.OffersContainer>
    );
  };

  const renderCatalogItems = () => {
    if (!isModelEmpty) {
      const filteredGenerations = generations.data.filter(item => item.id === +query.generation);
      const generationList = filteredGenerations.length ? filteredGenerations : generations.data;

      return <GenerationList isLoading={generations.isLoading} generations={generationList} />;
    }

    if (!catalogItems.data.length && !catalogItems.isLoading) {
      return <Empty />;
    }

    if (makeId || hasFilters) {
      return <ItemCards page={page} onLoadMore={moreReq.callRequest} items={items} />;
    }

    return <NewItemCards items={catalogItems.data} />;
  };

  return (
    <S.Container>
      {query.make ? <Breadcrumbs /> : <Header />}
      <SearchFilters />
      {catalogItems.isLoading ? <Loader /> : <S.Body>{renderCatalogItems()}</S.Body>}
      {makeId && !catalogItems.isLoading && renderOfferItems()}
    </S.Container>
  );
};

export const OpenedWindow = {
  none: 'none',
  alertWindow: 'alertWindow',
  deleteWindow: 'deleteWindow',
  errorWindow: 'errorWindow'
};

export const initialState = {
  openedWindow: OpenedWindow.none,
  setOpenedWindow: () => {},
  isMobileSite: false
};

import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { PaymentModal } from './PaymentModal';
import { InvoicesAppContext } from '../context';
import { Tabs } from './Tabs';
import { TableWrapper } from './Table';
import { useOnScreen } from '../../../hooks/useOnScreen';
import { accountsBalancesPath } from '../routes';
import { useAxios } from '../../../hooks/useAxios';
import { RoutePath } from '../constants';

export const Invoices = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const ref = useRef();
  const isInfoVisible = useOnScreen(ref);

  const {
    isMobileSite,
    setInvoiceRef,
    setVisibleTabRef,
    setHasErrors,
    setBalanceData,
    balanceValueData
  } = useContext(InvoicesAppContext);

  if (isMobileSite && location.pathname === RoutePath.invoices) {
    return <Redirect to={{ pathname: RoutePath.packages }} />;
  }

  const { callRequest: getBalanceValue } = useAxios({
    method: 'get',
    url: accountsBalancesPath,
    isInitialCall: true,
    onSuccess: data => {
      if (data?.cash) {
        setBalanceData(data.cash);
      }
    },
    onError: () => setHasErrors(true)
  });

  useEffect(() => {
    setInvoiceRef(ref);
    if (isInfoVisible && isMobileSite) {
      setVisibleTabRef(ref);
    }
    if (!balanceValueData) return getBalanceValue();
  }, [isInfoVisible]);

  return (
    <div
      className="invoices"
      style={{ minHeight: '100vh' }}
    >
      {!isMobileSite
        && (
        <>
          <Tabs />
        </>
        )}
      {isMobileSite && (
        <div
          className="invoices_title"
          ref={ref}
        >
          {t('title')}
        </div>
      )}
      <TableWrapper />
      { !isMobileSite && <PaymentModal /> }
    </div>
  );
};

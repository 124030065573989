import styled, { css } from 'styled-components';

export const SwitcherButton = styled.div<{ $isDark: boolean }>`
  display: inline-block;
  padding: 0 10px;
  background-color: transparent;
  border: 1px solid transparent;
  border-left: 0;
  border-right: 0;
  text-decoration: none;
  line-height: 46px;
  font-size: 15px;
  font-weight: 400;
  outline: none;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.palette.grey[200]};
  border-bottom-color: ${({ theme }) => theme.color.lightGrey};
  transition: color 0.15s ease-in-out;

  &.active {
    border-width: 2px;
    line-height: 44px;
    color: ${({ theme, $isDark }) => ($isDark ? theme.palette.blue[200] : theme.palette.red[100])};
    border-bottom-color: ${({ theme, $isDark }) =>
      $isDark ? theme.palette.blue[200] : theme.palette.red[100]};
  }

  &:hover {
    color: ${({ theme, $isDark }) => ($isDark ? theme.palette.blue[200] : theme.palette.red[100])};
  }
`;

const FullWidthContainer = css`
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};

  ${SwitcherButton} {
    margin-bottom: -1px;
  }
`;

export const Container = styled.div<{ $isFullWidth: boolean }>`
  display: flex;
  ${({ $isFullWidth }) => ($isFullWidth ? FullWidthContainer : '')}
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyTable } from './styled';

export const EmptyTableComponent = ({ title, message }) => {
  const { t } = useTranslation();

  return (
    <EmptyTable>
      <span>{t(title)}</span>
      <span>{t(message)}</span>
    </EmptyTable>
  );
};

import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PersonalCabinetContext } from '../../context';
import { TabsNames } from '../../context/globalState';
import { AdsPayments } from './Operations/AdsPayments';
import { PaidAdsTransactions } from './Operations/PaidAdsTransactions';
import { Transactions } from './Operations/Transactions';
import { DetailsPopup } from './DetailsPopup';

export const Content = () => {
  const { selectedRow } = useContext(PersonalCabinetContext);
  const { adsPayments, paidAdsTransactions, transactions } = TabsNames;

  return (
    <>
      <Switch>
        <Route path={adsPayments.path} exact><AdsPayments /></Route>
        <Route path={transactions.path}><Transactions /></Route>
        <Route path={paidAdsTransactions.path}><PaidAdsTransactions /></Route>
      </Switch>
      {selectedRow && <DetailsPopup />}
    </>
  );
};

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { az, ru } from 'date-fns/esm/locale';
import * as classNames from 'classnames';
import { Table } from '../../table';
import { Link } from '../styled';
import { ActionButton } from '../ActionButton';
import { doFormatDate, numberWithCurrency } from '../../../../../helpers';
import { TimeFormat } from '../../../../../types';
import { usePostMessage } from '../../../../../hooks/usePostMessage';
import { FIRST_PAGE_NUMBER } from '../../constants';
import { useAxios } from '../../../../../hooks/useAxios';
import { EmptyTableComponent } from '../EmptyTable';
import { operationPath } from '../../../routes';
import { getPageNumberFromUrl } from '../../../helpers';

export const Operations = ({
  getDataSource,
  dataKey,
  emptyError,
  columns
}) => {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(getPageNumberFromUrl() || FIRST_PAGE_NUMBER);
  const [dataSource, setDataSource] = useState([]);

  const { response, loading, callRequest } = useAxios({
    url: operationPath(currentPage, dataKey),
    method: 'get'
  });

  useEffect(() => {
    const handleHashChange = () => {
      const pageNumber = getPageNumberFromUrl();
      if (pageNumber && pageNumber !== currentPage) setCurrentPage(pageNumber);
    };
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  useEffect(() => callRequest(), [currentPage]);

  useEffect(() => {
    if (response && !loading) {
      setDataSource(getDataSource ? getDataSource(response[dataKey]) : response[dataKey]);
    }
  }, [response, loading]);

  usePostMessage({ postMessageName: 'paymentFinished', handler: callRequest });

  if (response && !response[dataKey].length && !loading) {
    return (
      <EmptyTableComponent
        title={emptyError.title}
        message={emptyError.message}
      />
    );
  }

  const operationColumns = columns || [{
    dataIndex: 'created_at',
    title: t('date_and_time'),
    render: created_at => (
      <span className="date_and_time">
        {doFormatDate({
          date: created_at,
          format: TimeFormat.fullTime,
          options: { locale: i18n.language === 'az' ? az : ru }
        })}
      </span>
    )
  }, {
    dataIndex: 'value',
    title: t('sum'),
    render: (value, row) => {
      const amount = value || row?.amount;

      const getAmountClassName = () => {
        if (row?.label) return `amount-${row.label}`;
        return amount < 0 ? 'amount-up' : 'amount-down';
      };
      return (
        <div className={getAmountClassName()}>
          {dataKey === 'transactions' && (amount < 0 ? '' : '+')}
          {numberWithCurrency(amount)}
        </div>
      );
    }
  }, {
    dataIndex: 'id',
    title: t('adTitle'),
    render: (_, row) => {
      const adId = row.ad?.id;
      if (adId) {
        return (
          <Link href={`/autos/${adId}`}>
            #
            {adId}
          </Link>
        );
      }
      return '';
    }
  }, {
    dataIndex: 'service',
    title: t('service')
  }, {
    dataIndex: 'action',
    title: '',
    render: (_, row) => !row?.hasNoPayment && <ActionButton row={row} />
  }];

  const tableClasses = classNames(
    'profile-transactions',
    'profile-transactions--operations',
    { 'empty-table': loading }
  );

  return (
    <Table
      dataSource={dataSource}
      className={tableClasses}
      columns={operationColumns}
      loading={loading}
      totalPages={response?.total_pages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  );
};

import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import * as S from './Gallery.styles';
import { Image, Thumb } from '../../Carousel.styles';

const THUMB_WIDTH = 80;
const THUMB_OFFSET = 10;
const CENTER_SLIDES_COUNT = 15;

interface ThumbnailsProps {
  active: number;
  items: { url: string; label: string }[];
  onThumbnailClick: (id: number) => void;
}

export const Thumbnails = ({ items, active, onThumbnailClick }: ThumbnailsProps) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const currentStep = active - 1;
  const maxTranslate = items.length * THUMB_WIDTH - windowWidth + THUMB_OFFSET;
  const maxStep = Math.ceil(maxTranslate / items.length) + THUMB_OFFSET;
  const translate = Math.min(currentStep * maxStep, maxTranslate);
  const isCentered = items.length < CENTER_SLIDES_COUNT && windowWidth / THUMB_WIDTH > items.length;

  useEffect(() => {
    const onResize = debounce(() => {
      // Reset calculation and start over if resized
      if (scrollerRef?.current) {
        scrollerRef.current.style.transform = 'translate3d(0, 0, 0)';
      }

      setWindowWidth(window.innerWidth);
    }, 100);

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <S.ThumbnailsContainer $isCentered={isCentered}>
      <S.ThumbnailsScroller
        ref={scrollerRef}
        style={{ transform: `translate3d(-${translate}px, 0px, 0px)` }}
      >
        {items.map((thumb, idx) => {
          return (
            <Thumb key={idx} onMouseOver={() => onThumbnailClick(idx + 1)}>
              <Image src={thumb.url} $isActive={active === idx + 1} />
            </Thumb>
          );
        })}
      </S.ThumbnailsScroller>
    </S.ThumbnailsContainer>
  );
};

import { useEffect } from 'react';

type Callback = (params: { [key: string]: string }) => void;

export const useQueryParamListener = (callback: Callback) => {
  useEffect(() => {
    const parseQueryParams = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const params: { [key: string]: string } = {};

      for (const [key, value] of searchParams.entries()) {
        params[key] = decodeURIComponent(value);
      }

      return params;
    };

    const handleUrlChange = () => {
      const queryParams = parseQueryParams();

      callback(queryParams);
    };

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [callback]);
};

import useSWR from 'swr';
import { fetcher } from './api';
import { GenerationsResponse } from '../types';

type TypedResponse = { data: GenerationsResponse };

export const useCatalogGenerations = (url: string | null) => {
  const { data, isLoading, error, mutate } = useSWR<TypedResponse>(url, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false
  });

  return {
    mutate,
    data: data?.data ?? [],
    isLoading,
    isError: error
  };
};

import React from 'react';
import { object } from 'prop-types';
import az from './locales/az.json';
import ru from './locales/ru.json';
import { ContextWrapper } from './context/ContextWrapper';
import { Header } from './components/header';
import { Content } from './components/content';

const FrequentlyAskedQuestionsApp = ({ dataset }) => (
  <ContextWrapper {...{ dataset }}>
    <Header />
    <Content />
  </ContextWrapper>
);

FrequentlyAskedQuestionsApp.propTypes = {
  dataset: object.isRequired
};

export default { App: FrequentlyAskedQuestionsApp, az, ru };

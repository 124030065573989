import React, { useState, useEffect } from 'react';
import * as S from './FilterTabs.styles';

export type FilterTabOption = { label: string; value: string | number };

interface FilterTabsProps {
  options: FilterTabOption[];
  selected: FilterTabOption | null;
  onChange: (selected: FilterTabOption) => void;
}

export const FilterTabs: React.FC<FilterTabsProps> = ({ options, selected, onChange }) => {
  const [selectedOption, setSelectedOption] = useState<FilterTabOption | null>(null);

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  const handleOptionClick = (option: FilterTabOption) => {
    onChange(option);
    setSelectedOption(option);
  };

  return (
    <S.Container>
      {options.map(option => {
        return (
          <S.Option
            key={option.value}
            onClick={() => handleOptionClick(option)}
            $isSelected={selectedOption?.value === option.value}
          >
            {option.label}
          </S.Option>
        );
      })}
    </S.Container>
  );
};
